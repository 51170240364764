// 通用请求
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import { saveAs } from 'file-saver'
import i18n from '@/i18n'
function download(headers, content) {
  const blob = new Blob([content], { type: headers['content-type'] })
  // const blob = new Blob([content]) // 构造一个blob对象来处理数据
  let fileName = 'attechment'
  if (headers['content-disposition']) {
    fileName = decodeURIComponent(
      headers['content-disposition'].substr('attachment;filename='.length)
    )
  }
  saveAs(blob, fileName)
}

function jsonProcess(res) {
  if (res.errorCode !== 9000 && res.errorCode !== 0) {
    if ([2000, 990105].indexOf(res.errorCode) !== -1) {
      // to re-login
      MessageBox.confirm(
        i18n.t('logout.confirmTip'),
        i18n.t('logout.confirmTitle'),
        {
          confirmButtonText: i18n.t('logout.submitBtn'),
          cancelButtonText: i18n.t('logout.cancelBtn'),
          type: 'warning'
        }
      ).then(() => {
        store.dispatch('user/LogOut').then(() => {
          location.reload()
        })
        this.$store.dispatch('im/logoutIm')
      })
    } else {
      Message({
        message: i18n.t(`code.${res.errorCode}`) || 'Request Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(new Error(res.errorMessage || 'Network Err'))
  } else {
    return res
  }
}

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
  responseType: 'blob'
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // config.headers['X-Auth-Type'] = '2'
    // if (!config.headers['x-rule'] && process.env.VUE_APP_RULE) {
    //   config.headers['x-rule'] = process.env.VUE_APP_RULE
    // }
    let lang = Cookies.get('lang') || 'en'
    config.headers['lang'] = lang
    config.headers['Accept-Language'] = lang === 'zh' ? 'zh_CN' : 'en_US'
    // config.headers['X-Auth-Type'] = '2'
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = `${token}`
      // config.headers['checkToken'] = '0'
      config.data = Object.assign({}, config.data || {})
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const headers = response.headers
    const content = response.data
    const contentType = headers['content-type']
    if (contentType.indexOf('application/json') !== -1) {
      var reader = new FileReader()
      reader.onload = function () {
        jsonProcess(JSON.parse(reader.result))
      }
      reader.readAsText(content)
    } else {
      download(headers, content)
    }
  },
  (error) => {
    console.log('报错: ' + error) // for debug
    Message({
      message: error.errorMessage || '网络错误',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
