import enIndex from './en/index'
export default {
  lang: 'en',
  ...enIndex,
  message: 'hello i18n !!',
  cookies: {
    text: 'We and selected third parties use cookies or similar technologies for technical purposes, to enhance site navigation, analyze site usage, assist in our marketing efforts, and for other purposes as specified in the Cookie Policy',
    learnMore: 'Learn more and customize',
    accept: 'Accept All',
    title: 'Privacy Preference Center',
    des: 'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.',
    moreInfo: 'More information',
    manageConsentPreferences: 'Manage Consent Preferences',
    strictlyNecessaryCookies: 'Strictly Necessary Cookies',
    strictlyNecessaryCookiesText:
      'These cookies are essential for users to browse or use our website and its features, such as accessing secure areas of the site. These cookies are necessary for the website to function and cannot be switched off in our systems.',
    performanceCookies: 'Performance Cookies',
    performanceCookiesText:
      'These cookies allow us to count visits in traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how many visitors move around the site. All information these cookies collect is anonymous. If you do not allow these cookies we will not know when you have visited our site, and we will not be able to monitor its performance.',
    functionalCookies: 'Functional Cookies',
    functionalCookiesText:
      'These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
    targetingCookies: 'Targeting Cookies',
    targetingCookiesText:
      "These cookies track users' online activity to help advertisers deliver more relevant advertising or to limit how many times users see an ad. These cookies may be set through our site by our advertising partners. They may be used by those companies to show you relevant advertisements on other sites. If you do not allow these cookies, you will experience less targeted advertising.",
    alwaysActive: 'Always Active',
    confirmMyChoice: 'Confirm my choice'
  },
  login: {
    middlePage: {
      text: 'Welcome to join us',
      hasAccount: 'Already had an account and go to verify it',
      noAccount: 'Have no account and go ahead to sign up'
    },
    addTeam: 'Add new team/enterprise',
    pleaseEnterName: 'Please enter the name of your team/company',
    changeRole: 'Switch role',
    changeToDP: 'Switch to DP',
    changeToHP: 'Switch to HP',
    become: 'Apply for',
    zhLang: 'Chinese',
    enLang: 'English',
    loginView: {
      tabs: {
        chooseAirRnd: 'Why',
        findTalent: 'Find Talent',
        findWork: 'Find Work',
        findItem: 'Find work',
        language: 'Simplified Chinese',
        login: 'Log in',
        register: 'Sign up',
        logout: 'Log out',
        findFriends: 'Invite friends',
        workbench: 'Workbench',
        changeTeam: 'Switch studio/company',
        publishProject: 'Publish a project'
      },
      lanList: {
        zh: '简体中文',
        en: 'English'
      },
      airRnd: {
        about: 'About us',
        ustomers: 'Our users'
      },
      // title: 'Join us as a Hiring Partner or Development Partner',
      title: 'AirRnd invites you to register',
      title2: '',
      emillText: 'Please enter your email',
      emailOrMobile: 'Please enter your email or mobile phone number',
      pswText: 'Please enter your invitation code',
      countryTip: 'Country or Region',
      mobileText: 'Mobile',
      verifyBtnText: 'Send auth code',
      verifyCodeText: 'Auth code',
      resend: 'Resend',
      docTypeText: 'Please select the ID type',
      docTypeList: [
        { key: '1', label: 'Passport' },
        { key: '2', label: 'Other certificates of nationality' }
      ],
      idNumberText: 'Please enter your ID number',
      uploadDocText: 'Upload',
      uploadTip: 'Please upload your passport or other identification',
      sampleText: 'View example',
      exampleText: 'Passport sample',
      nextText: 'Next step',
      accountTip: 'Already had an account?',
      loginText: 'Log in',
      // 第二步
      firstTip: 'Surname',
      lastTip: 'First name',
      passTip: 'Password',
      need: 'You would like to',
      teamTypeOne: 'Become Hiring Partner & Publish a project/task',
      teamTypeTwo: 'Become Development Partner & Bid for a project/task',
      teamTip: 'Please select team type',
      teamNameTip: 'Please enter the name of your team',
      contractText: 'Yes, I understand and agree to AirRnd',
      contractTextAnd: 'and',
      agreement: 'User Agreement',
      policy: 'Privacy Policy',
      buttonText: 'Create now',
      tipText: "Can't change after confirmation", // 新增加
      registTitle: 'Prompt',
      registConfirm1:
        "Please double confirm that you will be signing up as a Hiring Partner, and note that you won't be able to change the identity once registered.",
      registConfirm2:
        "Please double confirm that you will be signing up as a Development Partner, and note that you won't be able to change the identity once registered.",
      registConfirmSubmitBtn: 'Confirm',
      registConfirmCancelBtn: 'Let me think'
    },
    register: {
      title: 'Last step to complete registration',
      sendEmail:
        'The account activation email has been sent to your email address:',
      address: 'Log into your email and activate your account.',
      changeAddr: 'Change email address',
      emailInput: 'Please enter a new email address',
      sendBtn: 'Update and resend',
      resend: 'Resend verification email',
      successTitle: 'Congratulations! Your account has been activated.',
      successText: 'Redirecting to work bench…',
      errorTitle: 'Invalid email, please re-activate',
      errorBtn: 'Send activation email again',
      teamUnit: "'s team"
    },
    registerAudit: {
      auditStatus: 'Identity review in progress',
      auditTip: 'We will inform you within 1-3 working days, please be patient',
      auditFailText: 'Audit failure, please upload again',
      uploadBtnText: 'Re-upload your credentials'
    },
    registerFail: {
      title: 'Warm Tips',
      tip: 'According to Chinese law, non-Chinese mobile phone users are required to upload passports or other identification documents for real-name verification. Our team will not use your ID for other purposes.',
      idType: 'Type of Certificate',
      idNumber: 'ID Number',
      idFile: 'Credentials',
      idFilePText: 'Please upload your credentials.'
    },
    loginfo: {
      title: 'Welcome! Log in to AirRnd',
      rememberPass: 'Save password',
      forgetPass: 'Forget password',
      loginBtn: 'Log in',
      noAccount: "Don't have an AirRnd account？",
      goRegister: 'Sign up now'
    },
    teamErrTip:
      'Failed to load your team information, refresh this page please',
    setpOne: {
      type: 'What types of projects/tasks your team would like to take',
      project:
        'Your inputs around expertise, experiences and background would significantly increase the chance for you to win work.',
      selectTip: 'Please select'
    },
    setpTwo: {
      title: "Highlight your team's or your expertise",
      text: 'the relevant skill sets help you to stand out',
      selectTip: 'Please select skill set type'
    },
    setpThree: {
      title: "Describe your team's or your previous project experiences",
      text: 'The more project experiences you provide, the better chance you will be precisely matched to the opportunities of your interest',
      addTeamTitle: 'Add project experience',
      editTeamTitle: 'Edit project experience',
      teamName: 'Project name',
      teamNameTip: 'Please enter the name of your previous project',
      teamDesc: 'Project description',
      teamDescTip: 'Please enter project description',
      uploadImg: 'Upload picture',
      upTips: 'Maximum 8 (png/jpg/jpeg/gif, <=2M each)',
      isDel: 'Are you sure to delete this item?'
    },
    setpFour: {
      title: 'Introduce your team or yourself',
      text: "Description of your team's or your strength and experience help your resume shine out",
      entryTip: 'Please briefly introduce your team or yourself'
    },
    setpFive: {
      title: "What's your working language?",
      text: "We'll help to match HPs in that language",
      selectTip: 'Please select working languages'
    },
    setpSix: {
      title: 'Upload team logo and start your journey at AirRnd!',
      tips: 'Upload one picture (png/jpg/jpeg/gif, <=2M)'
    },
    // 忘记密码
    forget: {
      title: 'Change your password',
      text: 'Enter your email address and select to send email',
      emailTip: 'Please enter your Email',
      sendEmail: 'Send out',
      overTitle: 'Sent out',
      text1: "We've sent you an email",
      text2: 'Follow the steps to change your password',
      text3: "If you don't want to change password, go to Log in",
      rSend: 'Resend',
      lSend: 'Log in',
      changeMsgTitle: 'Change your password',
      newMsg: 'New password',
      restNewMsg: 'Enter new password again',
      passErrTip: 'Inconsistent new passwords',
      oldPassword: 'Old password',
      oldPasswordTip:
        'Please enter a 6 to 18 digit old password, with both alphabet letters or numbers',
      newPasswordTip:
        'Please enter a 6 to 18 digit new password, with both alphabet letters or numbers',
      restPasswordTip:
        'Please enter the 6 to 18 digit new password again, with both alphabet letters or numbers'
    },
    pass: 'Please enter a 6 to 18 digit password, with both alphabet letters or numbers'
  },
  logout: {
    successTip: "You've logged out",
    confirmTip:
      'Token expired. Click Cancel to stay on the current page or click Re-login',
    confirmTitle: 'Confirm logout',
    cancelBtn: 'Cancel',
    submitBtn: 'Re-login'
  },
  logoutSuccess: "You've logged out",
  // 权限提示
  permis: {
    toActivate: 'You need to activate your account first',
    toCert: 'You need to verify your account and get approved first',
    inCert:
      "Your account verification is pending in process, we appreciate your patience. Once it is done, you'll be able to proceed",
    toRegister:
      "Please log in to your email to activate your account for details. If you haven't received any emails or want to change your Email address, please click 'confirm' button for the next step."
  },
  // 发布项目需求
  publishProject: {
    indexView: {
      cooperationCycleList: {
        0: '0-4 Weeks',
        1: '1-2 Months',
        2: '3-5 Months',
        3: '6 Months - 1 Year',
        4: 'More than 1 year'
      },
      averageWorkweekList: {
        0: 'Less than 8 hours',
        1: '9-24 Hours',
        2: '24-32 Hours',
        3: '33-40 Hours',
        4: 'More than 40 hours'
      },
      rateGearList: {
        0: 'Less than 30',
        1: '31-50',
        2: '51-80',
        3: '81-110',
        4: '111-140',
        5: '141-170',
        6: 'More than 170'
      },
      publishItemType: 'What type of project do you want to post ？',
      fixedPriceContract: 'Fixed Price Service Contract',
      laborRateContract: 'Hourly Contract',
      hopeCooperationTime: 'How long would you like to work with her/him',
      averageWorkingHoursPerWeek:
        'How many working hours a week do you expect her/him to work',
      expectedHourlyRate: "What's your expected hourly rate",
      title: 'Publish a project',
      desc: 'Detailed project description will help to attract more talented DPs to bid',
      typeLabel: 'Your project type',
      typePlaceholder: 'Please select your project type',
      titleLabel: 'Project name',
      titlePlaceholder: 'Please name your project',
      descLabel: 'Describe about your project',
      uploadBtnText: 'Upload',
      uploadType: 'Supporting: jpg/jpge/png/pdf/doc/docx',
      uploadLimitText:
        'Supporting: jpg/jpge/png/pdf/doc/docx,upload picture size <=2M , upload file size <=10M',
      uploadSize: 'Upload picture size <=2M, upload file size <=10M!',
      skillsText: 'What are the most required skill sets?',
      skillPlaceholder: 'Enter key words',
      recommendSkillText: 'The following skill sets may fit your project',
      deliveryTimeText: 'Project duration',
      deliveryTimePlaceholder: 'Please enter the expected end date',
      deliveryUnitPlaceholder: 'Please select',
      skillTips: 'Please select skill sets',
      timeTips: 'Please enter end date',
      deliveryUnit: {
        label1: 'Day',
        label2: 'Week',
        label3: 'Month',
        label4: 'Year'
      },
      tipText: 'Notional only, see contract T&Cs for real requirements',
      budgetText: "What's your budget for this project?",
      budgetTypeObj: {
        label0: 'RMB',
        label1: 'US Dollar',
        label2: 'Euro'
      },
      budgetRangeObj: {
        label1: 'Less than 10,000',
        label2: '10,000 to 50,000',
        label3: '50,000 to 500,000',
        label4: '500,000 to 1,000,000',
        label5: 'Above 1,000,000'
      },
      lowBudget: 'Lowest budget',
      highBudget: 'Highest budget',
      customPlaceholderText: 'Enter value',
      templateText: 'Insert template',
      nextBtnText: 'Next step',
      publishPrjBtnText: 'Publish a project',
      // 插入模板弹窗
      templateDialog: {
        title: 'Insert template',
        sureBtnText: 'Confirm',
        cancelBtnText: 'Cancel'
      }
    },
    noModel: 'No templates available now'
  },
  // 富文本组件
  richEditer: {
    headerList: {
      label1: 'Mainbody text',
      label2: 'Headline1',
      label3: 'Headline2',
      label4: 'Headline3'
    },
    fontStyleList: {
      bold: 'Bold',
      italic: 'Italic',
      underline: 'Underline',
      strike: 'Strikethrough'
    },
    colorsList: {
      color: 'Text color',
      background: 'Background color'
    },
    list: {
      bullet: 'Bullet list',
      ordered: 'Ordered list'
    },
    alignList: {
      justify: 'Justify',
      left: 'Left align',
      center: 'Center align',
      right: 'Righ align'
    }
  },
  // 菜单栏
  menuBar: {
    workbench: 'Work Bench',
    project: 'Project',
    publishProject: 'Publish a project',
    myProject: 'Project status',
    deal: 'Transaction',
    myTender: 'Bid review',
    projectTender: 'My proposals',
    myOrder: 'My contracts',
    myEvaluate: 'My reviews',
    myBill: 'My bills',
    myReward: 'My payments',
    team: 'Studio',
    team2: 'Company',
    teamMember: 'Studio member',
    teamMember2: 'Company member',
    teamInfo: 'Studio information',
    teamInfo2: 'Company information',
    identity: 'Account verification',
    personal: 'Personal setting',
    myHomePage: 'Studio homepage',
    security: 'Change password',
    invite: 'Invite friends',
    letter: 'Inbox',
    myProjects: 'My project', // 新增加
    upgrade: 'Upgrade now', //新修改
    cloud: 'My cloud drive', // 新修改
    setting: 'Others', // 新修改
    questions: 'AirRnd FAQ' // 新增加
  },
  btns: {
    notSubmitted: 'Not submitted',
    previous: 'Previous step',
    next: 'Next step',
    sure: 'Confirm',
    cancel: 'Cancel',
    uploadText: 'Upload',
    submit: 'Submit',
    resubmit: 'Re-submitted',
    sureBtnText: 'Confirm',
    revise: 'Revise',
    know: 'Agree',
    save: 'Save',
    complete: 'Complete',
    toTrial: 'Send for review',
    copy: 'Copy',
    addSure: 'Join',
    sendMail: 'Send email',
    addFriend: 'Invite members',
    download: 'Download',
    uploadFile: 'Upload files',
    newLink: 'New link',
    newFolder: 'New folder',
    deleteText: 'Delete',
    contactMe: 'Contact us',
    please: 'Enter',
    useMode: 'Template',
    editText: 'Edit',
    cancelRelease: 'Cancel',
    shelve: 'On shelf',
    unshelve: 'Off shelf',
    publish: 'Publish',
    toAudit: 'Submit for review',
    fold: 'Fold',
    unfold: 'Unfold',
    hasIntent: 'Intent',
    releaseProj: 'Publish',
    viewDetail: 'Details',
    turnDown: 'Reject',
    pass: 'Pass',
    dollar: 'USD',
    rmb: 'RMB',
    euro: 'Euro',
    newContract: 'New contract',
    cancelled: 'Cancelled',
    toSigne: 'Pending signature',
    inSigne: "Pending both parties' signature",
    toConfirm: 'To be confirmed',
    void: 'Void',
    hasVoid: 'Voidance',
    effective: 'Effective',
    acceptance: 'Accept',
    inConfirm: 'Confirmed',
    toCert: 'To verify',
    toComp: 'To complete',
    acceptSure: 'Accptance confirmed',
    confirmDelivery: 'Confirm the delivery?',
    terminate: 'Termination',
    terminateConfirm: 'Termination confirmed',
    terminateNo: 'Termination rejected',
    signe: 'Initiate signing',
    deliver: 'Deliver',
    btnBack: 'Back',
    addProject: 'New project experience',
    showDetail: 'Details',
    send: 'Send',
    linkTeam: 'Join Team',
    addEmail: 'Add Email address',
    copyLink: 'Copy link',
    addTemplate: 'Add template',
    preview: 'Preview',
    viewComments: 'See reviews', // 新增加
    toActivate: 'Activate', // 新增加
    checkDp: 'Check DP candidates',
    saveDraf: 'Save as draft', // 新增加
    cancellation: 'unsubscribe',
    revocation: 'revocation of cancellation application'
  },
  headTips: {
    certTip: 'Please verify your account and get approved first',
    noData: 'No data',
    noCompleteTip:
      'Your personal information completeness is only {0}%, recommend improving your profile'
  },
  // 身份认证
  identity: {
    certTip: "The name can't change after verified.", // 新增加
    chooseTypeText: 'Choose verification method',
    personalText: 'Studio Verification - freelancer(s)',
    firmText: 'Company verification',
    authTips:
      "You're not Admin of this account, please log in as Admin for account",
    domestic: {
      stepOneText: 'Focal verification',
      stepTwoText: 'Organization information check and verfication',
      // stepThreeText: 'Authorization letter verification',
      stepFourText: 'Completed',
      tip: 'Please fill in all essential items', // 新增加
      stepOne: {
        tip: 'Entered information here is for this verification ONLY and will not be stored or disclosed by AirRnd',
        typeText: 'Verification method',
        type: 'Facial recognition',
        desc_1: '1 China mainland residence only',
        desc_2:
          '2 Enter your full name as in your China mainland ID card and card number',
        name: 'Full name',
        agentName: "Focal's full name",
        namePlaceholder: 'Please enter your full name',
        sfz: 'ID card number',
        agentSfz: 'Focal ID card number',
        sfzPlaceholder: 'Please enter your ID card number',
        mobile: 'Mobile number',
        agentMobile: "Focal's mobile number",
        mobilePlacehoder: 'Please enter your mobile number',
        workName: 'Name of your team',
        workPlaceholder: 'Please enter the name of your team',
        headImg: 'Upload your photo',
        headImgTip: 'Please upload your authentic photo',
        imgTip: '1 profile photo <=2M, png/jpg/jpeg',
        qrCodeBtn: 'QR code'
      },
      stepTwo: {
        organTypeText: 'Organization type',
        organList: ['Company of other types', 'Individual business'],
        sample: 'Example',
        firmNameText: 'Company name',
        firmPlaceholder: 'Please enter company name',
        codeText: ['Uniform Social Credit Code', 'or Business License Number'],
        codePlaceholder:
          'Please enter Uniform Social Credit Code or Business License Number',
        operateText: 'Name of legal representative',
        operatePlaceholder: 'Please enter name of legal representative',
        idType: 'ID document type of legal representative',
        idNumber: 'Legal representative ID document number',
        idNumberPlaceholder:
          'Please enter legal representative ID document number',
        tipText: 'Verification failed?',
        ID: 'ID card'
      },
      dialog: {
        title: 'Scan to verify',
        type: 'Method',
        zfb: 'Alipay',
        txy: 'Tencent cloud',
        qrCodeText:
          'Please scan the QR code with Alipay or Wechat for facial recognition verification',
        exampleTitle: 'Example picture',
        tipText: 'Tips',
        tipDesc: [
          "Unfortunately, we can't support the following",
          'Companies registered outside mainland China',
          'Few social organizations, law firms and individual business that not included in current database'
        ]
      },
      result: {
        name: 'Full name',
        ID: 'ID card number',
        phone: 'Mobile number',
        orgType: 'Organization type',
        company: 'Company name',
        businessNumber: 'Uniform Social Credit Code or Business License Number',
        legal: 'Legal representative',
        legalID: 'Legal representative ID card number',
        btnText: 'To verify again',
        studio: 'Team name',
        tName: 'Focal name',
        tTD: 'Focal ID card number',
        tPhone: 'Focal mobile number',
        failure: 'Check for verification failure reasons',
        per: 'Studio verification',
        firm: 'Company verification',
        status1: 'Studio verification in process',
        status2: 'Studio verified',
        status3: 'Studio verification failed',
        firmStatus1: 'Pending',
        firmStatus2: 'Company verified',
        firmStatus3: 'Company verification failed',
        status: 'Verification status'
      },
      faceCode: {
        title:
          "Scan this QR code and you'll have 4 different methods to verify your account, feel free to choose one most conveniet to you:",
        legal: 'Legal representative verification:',
        legalText: 'Most suitable if you are the legal representative',
        payment: 'Official bank account payment verification:',
        paymentTitle:
          "Most suitable if it's easier for you to make an official bank account payment out",
        alipay:
          'Enterprise Alipay verification (only applicable if you scanned the QR code with Alipay):',
        alipayText:
          "Most suitable if you've got an Enterprise Alipay account established already",
        auth: 'Authorization letter verification:',
        authText:
          "Most suitable if you know the legal representative's mobile number and can reach out to him/her for an easy mobile phone SMS verification"
      }
    },
    foreign: {
      name: 'Please enter your name',
      docType: 'Please select your ID document type',
      cerNo: 'Please enter your ID document number',
      cerNomLong: 'Length between 3 to 18 characters',
      regNum: 'Please enter your business license number',
      regDocNo: 'Please enter business license number',
      teamName: 'Please enter the studio name',
      uploadAvatar: 'Please upload your profile picture',
      read: "I've read and agreed to AirRnd",
      book: 'User Entry Agreement',
      // 企业认证
      role: 'Please select your role',
      entName: 'Please enter company name',
      onePeople: 'Company size',
      people: 'people',
      companyName: 'Company name',
      entRegTime: 'Please enter company registration date',
      taxRegNum: 'Please enter business license number',
      entPerSize: 'Please select your company size',
      conNum: 'Mobile number is required',
      email: 'Please enter your email',
      letOfCom: 'Please upload commitment letter',
      entPowOfAtt: 'Please upload company authorization letter',
      companyLogo: 'Please upload company logo',
      Letter: 'Commitment letter',
      upgrade: 'Upgrade to company verification'
    }
  },
  // 团队信息
  teamInfo: {
    title: 'General information',
    teamName: 'Studio name',
    firmName: 'Company name',
    website: 'Official website',
    brandName: 'Brand name',
    englishName: 'English name',
    peopleNum: 'Size',
    phone: 'Telephone',
    email: 'Email',
    please: 'Please enter',
    address: 'Address'
  },
  teamMember: {
    bar: 'Studio members',
    firmBar: 'Company member',
    textOne: 'Now you have',
    textTwo: 'members and you can invite another ',
    textThree: 'members',
    roles: 'Role',
    superAdmin: 'Super Admin',
    admin: 'Admin',
    tabOne: 'Inviation link',
    tabTwo: 'Invitation email',
    members: 'Members',
    lastOperation: '最后操作',
    inviter: 'Inviter',
    selectProject: 'Please select project',
    checked: 'Selected',
    allProject: 'All projects',
    joinProject: 'Join project',
    operate: '操作',
    InviteFriends: {},
    copySuccess: 'Link copied successfully',
    copyError: 'Copy failed',
    emailPlaceholder: 'Please enter your Email address',
    tip: 'Link valid for 30 days',
    contactHim: 'Contact her/him'
  },
  // 网盘
  inlineDisk: {
    volume: {
      text_1: 'Cloud drive usage',
      text_2: 'Free'
    },
    steps: ['All', 'Contract number', 'Stage', 'Stage description'],
    cols: ['File name', 'Size', 'Revise date', 'Publisher'],
    emptyText: 'No file',
    more: 'More',
    forbid: 'Deletion denied',
    dialog: {
      title: 'Add link',
      name: 'File name',
      link: 'Link',
      nameMsg: 'Please enter file name',
      linkMsg: 'Please enter the link'
    },
    folderNameMap: {
      prePay: 'Pre-paid',
      finalPay: 'Balance payment',
      payStep: 'Stage'
    }
  },
  // 个人设置
  personal: {
    title: 'Personal information',
    name: 'Full name',
    country: 'Country or region',
    mobile: 'Mobile number',
    mobilePlaceholder: 'Please enter your mobile number',
    codePlaceholder: 'Please enter your verification code',
    verifyBtn: 'Send verification code',
    verifyBtn_1: 'Resend after',
    tips: 'Profile photo: one picture only, png/jpg/jpeg, <=2M',
    noPerfect: 'To be completed',
    bindPhone: 'Bind your mobile number',
    changeMobile: 'Change your mobile number',
    sample: 'e.g.: +86 15931234560 (Area Code + cell number)', // 新增
    checkMobileTip: 'Please input correct cell phone number', // 新增
    applyCancelDialog: {
      title: 'Applying for Account Cancellation',
      tips: [
        'Before the cancellation application you submitted takes effect, the Zhiyan Jizhi team will conduct the following verification to ensure the security of your account and property.',
        'After verification by the team, if there is no problem above, the system will cancel your account within 15 days.'
      ],
      process: {
        first: {
          title: '1.The account is in the secure state',
          content:
            'In the last two weeks, you have not carried out sensitive operations such as password change or binding change, and your account is not at risk of being stolen or blocked.'
        },
        second: {
          title: '2.The platform project is settled',
          content:
            'All projects and contracts in your account have been settled, but none have entered the contract negotiation stage or contract execution stage.'
        },
        third: {
          title: '3.Platform payment property settlement',
          content:
            'All payments or pending payments for contracts in your account have been settled.'
        },
        fourth: {
          title: '4.Platform permission Removal',
          content:
            'The enterprise administrator identity associated with the platform account is removed and transferred; If you are the only account of an enterprise on the platform, the enterprise account will be logged out at the same time.'
        }
      },
      warm_tips: {
        title: 'Warm Tips',
        content: [
          '1.If the system prompts that "cancellation cannot be completed", please follow the above prompts to exclude relevant reasons and reapply for cancellation.',
          '2.After logging out, all data in the account will be emptied and cannot be recovered. Please operate with caution.',
          '3.After the cancellation petition is filed, there is a 15-day retraction period. During the retraction period, you can log in to the platform and click "Cancellation Application". The cancellation will be terminated.'
        ]
      }
    },
    withdrawText:
      'You are applying to cancel your account. During this period, you cannot do anything else'
  },
  // 密码与安全
  security: {
    // title: 'Password and security',
    title: 'Change password',
    mail: 'Email',
    updateBtn: 'Change password',
    dialog: {
      tab_1: 'Password change by Email',
      tab_2: 'Password change by mobile phone',
      mobile: 'Mobile number',
      verifyCode: 'Verification code',
      verifyCodeMsg: 'Please enter verification code',
      pass: 'New password',
      passMsg: 'Please enter a 6-18 digit password',
      checkPass: 'Re-enter your password',
      checkPassMsg: 'Inconsistent passwords'
    }
  },
  // 设置 邀请好友 站内信
  setInvite: {
    title: 'Invite friends',
    text: 'You can copy the following contents to invite your friends to register here',
    infoTitle: 'Invitation from AirRnd',
    addr: 'Register address:',
    code: 'Invitation code:',
    copySuccess: 'Invitation link copied to clipboard',
    copyError: 'Copy failed',
    areaCode: 'Please enter the correct country(region) code',
    coypInEnglish: 'Copy in English'
  },
  // HP工作室简介
  hpHomepage: {
    work: 'General introduction',
    website: 'Enterprise official website',
    email: 'Email',
    phone: 'Telephone',
    projectList: 'Published project list',
    evaluate: 'Comment' // 新增加
  },
  letterTitle: 'Inbox',
  read: 'Read',
  unread: 'Unread',
  // 合同
  contract: {
    design: {
      name: 'Contract ',
      module: {
        base: {
          name: 'General information',
          partA: 'Party A',
          partB: 'Party B',
          form: {
            name: 'Name',
            addr: 'Address',
            legal: 'Name of legal representative',
            code: 'Uniform Social Credit Code',
            idcard: 'ID Card of legal representative'
          }
        },
        srvAndCost: {
          name: 'Service scope and fees',
          partA: 'Development project scope',
          partB: 'Pricing and payment',
          partC: 'Invoice',
          form: {
            projectName: 'Development project name',
            totalCost: 'Total contract value',
            cost: 'Fees',
            prePay: 'Pre payment',
            finalPay: 'Balance payment',
            corpName: 'Company name',
            code: 'Taxpayer Identification Number',
            addr: 'Address',
            mobile: 'Telephone',
            openBank: 'Bank account',
            acct: 'Account number',
            desc1: 'Payment within xx  Days',
            desc2: 'Phase',
            errTip:
              'Incorrect calculation of fee percentage: {0}%, current: {1}%'
          }
        },
        ownership: {
          name: 'Ownership',
          partA: 'Sole development',
          form: {
            num: 'Sole development duration'
          }
        },
        change: {
          name: 'Change and Rescission',
          partA: 'Contract Change and Rescission',
          form: {
            num: 'Change request response period',
            numPrefix: 'Working Days',
            numSuffix: 'After notification '
          }
        },
        contact: {
          name: 'Contact information',
          partA: 'Party A focal',
          partB: 'Party B focal',
          form: {
            name: 'Full name',
            addr: 'Address',
            mobile: 'Mobile',
            wechat: 'Social media account',
            email: 'Email'
          }
        },
        promise: {
          name: 'Commitment and Guarantee',
          partA: 'Liability for Breach',
          form: {
            userA: 'Party A',
            userAnum1:
              'In case of payment overdue, Party A shall pay xxx at a daily basis to Party B as penalty',
            userAnum2: 'Party A default, penalty is the total contract value',
            userB: 'Party B',
            userBnum1:
              'In case of delivery overdue, Party B shall pay yyy at a daily basis to Party A as penalty',
            userBnum2: 'Party B default, penalty is the total contract value'
          }
        },
        conflict: {
          name: 'Dispute resolution',
          partA: 'Dispute resolution',
          form: {
            addr: '起诉地址',
            addrPlaceholder: '协商不成向有管辖权的人民法院起诉'
          }
        },
        attachment: {
          name: 'Annex',
          partA: 'Annex',
          form: {
            name: 'Attachment name'
          }
        },
        sign: {
          name: 'Signature',
          partA: 'Signature',
          form: {
            signDate: 'Signature date'
          }
        },
        terms: {
          name: 'Standard terms',
          form: {
            content: 'Terms'
          }
        },
        cooperate: {
          name: 'HP support',
          partA: 'Date to provide technical materials',
          form: {
            num: 'Date to provide technical materials',
            numPrefix: 'After contract signed',
            numSuffix: 'Working days'
          }
        },
        accept: {
          name: 'Acceptance criteria',
          form: {
            criteria: 'Acceptance criteria',
            method: 'Acceptance method',
            time: 'Acceptance date',
            timeSuffix: 'Acceptance completed within...'
          }
        },
        train: {
          name: 'Technical service and training',
          form: {
            time: 'Training duration',
            cost: 'Fees covered by',
            num: 'Training times',
            hour: 'Duration of each session'
          }
        },
        warranty: {
          name: 'Warranty period',
          form: {
            warranty: 'Warranty period',
            penalty: 'Penalty',
            overdue: 'Overdue days'
          }
        },
        section: {
          form: {
            title: 'Title',
            pos: 'Placement',
            fontSize: 'Text size'
          }
        },
        group: {
          form: {
            title: 'Title',
            pos: 'Placement',
            fontSize: 'Text size',
            show: 'Whether to display'
          }
        },
        cost: {
          name: 'Price and payment method',
          partA: 'Price and payment method',
          form: {
            totalCost: 'Total contract amount',
            cost: 'Fees',
            prePay: 'Prepayment',
            finalPay: 'Balance payment',
            desc1: 'Pay within days',
            desc2: 'Stage',
            errTip:
              'Incorrect calculation of fee percentage: {0}%, current: {1}%',
            minimumValEachPaymentErr:
              "The minumum payment of every phase can't be below than 200,Please input a high number"
          }
        }
      },
      status_0: 'Incomplete',
      status_1: 'Completed',
      promptTitle: 'Change title',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      tempBtnText: 'Save',
      saveBtnText: 'Complete',
      generateContract: 'Generate Contract',
      pdfBtnText: 'Download PDF',
      notEmpty: "can't be empty",
      fontFamily: {
        SimSun: 'SimSun',
        SimHei: 'SimHei',
        YaHei: 'Microsoft YaHei'
      },
      fontFamilyTitle: 'Font',
      fontSizeTitle: 'Font size',
      undone: 'Incomplete contract'
    },
    task: {
      title: 'Work breakdown',
      workTitle: 'Hourly rate critical items',

      groupTitlePrefix: 'Phase',
      form: {
        hourlyRate: 'Hourly Rate',
        onlyHourlyRate: 'Hourly Rate',
        estimatedWorkingCycle: 'Expected Work Period',
        estimatedWeekWorkingHours: 'Estimated working hours per week',
        projectName: 'Project name',
        totalCost: 'Total contract value',
        step: 'Delivery phase',
        desc: 'Deliverable description',
        commitTime: 'Delivery date ',
        pct: 'Percentage',
        maxAcceptTime: 'Maximum acceptance period',
        dpTeamId: 'DP Team'
      }
    },
    tpl: {
      title: 'Contract template',
      btn: 'Apply template',
      personal: 'Personal', // 新增
      company: 'Corporate' // 新增
    },
    btnBack: 'Back',
    btnNext: 'Next step',
    btnPrev: 'Previous step',
    create: {}
  },
  // 评价
  myEvaluate: {
    tabOne: 'Received reviews',
    tabTwo: 'Posted reviews'
  },

  // 我的项目 整合(新增加)
  myProjects: {
    project: 'Publish list',
    order: 'Project list',
    tender: 'My bid'
  },
  // 我的项目
  myProject: {
    // 项目列表
    projectList: {
      // 项目工时
      projectHours: {
        confirmedWorkTime: 'Confirmed working hours',
        unConfirmedNumTimeCards: 'Unconfirmed working hours',
        date: 'Date',
        workingHours: 'Working hours',
        workingDescribe: 'Working description',
        timeConfirmation: 'Working hours confirmation',
        subtotalWeek: 'Total weekly working hours',
        placeholderSelectWeek: 'Select Week',
        pleaseSelectContract: 'Select Contract',
        confirmWorkingHours: 'Confirm the working hours?',
        enterDescription: 'Please describe your work',
        operationSuccess: 'Success',
        objectionTips:
          'You could describe the concerns or questions here, and send to DP (via email and mail system in Airrnd)',
        haveObjections: 'Have concern',
        haveObjectionsTips:
          'After clicking, you can enter your conerns about the bill and send them to DP',
        placeholderEnterReason: 'Please provide your rationale'
      }
    },
    radios: {
      allStatus: 'All status',
      noAudit: 'Not submitted',
      audit: 'Pending',
      error: 'Failed',
      success: 'Passed',
      release: 'Published',
      unshelve: 'Off the shelf',
      draft: 'Draft'
    },
    publishTip: 'Published after approval',
    errorReason: 'Failed reasons'
  },
  // 工作室信息 整合(新增加)
  studioTabs: {
    base: 'Basic information',
    homePage: 'Studio home',
    companyPage: 'Company home',
    cert: 'Identity verification',
    invoice: 'Invoice'
  },
  invoice: {
    english: 'English',
    companyName: 'Company name',
    taxNumber: 'Taxpayer identification Number',
    address: 'Address',
    tel: 'Tel',
    bank: 'Bank',
    account: 'Account Number',
    required: 'Please fill in the field'
  },
  // 我的账单
  bill: {
    billText: 'Amount totally paid',
    received: 'Amount totally received',
    unit: 'RMB',
    payNum: 'Transaction number',
    amount: 'Transaction amount',
    accountReceivable: 'Account Receivable',
    payee: 'Payee',
    payTime: 'Payment time',
    project: 'Associated project',
    status: 'Status',
    Arrival: 'Received',
    noArrival: 'Pending',
    hpArrival: 'Paid',
    hpNoArrival: 'To be paid',
    partAccount: 'Partially received',
    hpPartAccount: 'Partially paid',
    refund: 'Refunded',
    totalContract: 'Total Amount'
  },
  // 我的报酬
  reward: {
    needCard: "You haven't created an account yet",
    toAddCard: 'Create an account now',
    addCard: 'Add a bank account',
    editCard: 'Edit a bank account',
    please: 'Please enter',
    bankName: 'Bank name',
    subBranch: 'Branch',
    accountName: 'Account name',
    cardName: 'Account number',

    swiftCode: 'SWIFT CODE',
    bankAccount: 'Account number',
    bankCode: 'Bank code',
    bankAddress: 'Bank address'
  },
  talent: {
    title: 'Talent list',
    noActiveTip: 'You need to activate your account first'
  },
  // 订单
  order: {
    task: 'Work breakdown',
    delivery: 'Delivery phase',
    phase: 'Phase',
    desc: 'Delivery note ',
    overTime: 'Due date',
    data: 'Select date',
    descHolder: 'Please add delivery notes',
    count: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    contract: 'Contract template',
    company: 'Technical development contract template (Company)',
    personal: 'Technical development contract template (Individual)',
    orderList: {
      allStatus: 'All status', // 新增加
      tabOne: 'Pending contracts',
      tabTwo: 'In execution',
      tabThree: 'Pending acceptance',
      // tabFour: 'Accepted',
      tabFive: 'Accomplished',
      tabSix: 'Terminated',
      empty: 'No orders',
      timeText: 'Expected delivery period：',
      noCurrently: "You don't have an ongoing project now"
    },
    // 订单详情
    orderDetail: {
      hour: 'Hour',
      scoreHolder: 'Please enter your score',
      comHolder: 'Please enter your review comments',
      contract: {
        confirmTip: 'Have you confirmed the contract (on the left)?',
        esign: 'Do you want to apply an e-Signature',
        radioOne: 'No',
        radioTwo: 'Yes',
        noConstract: 'No contracts',
        hpPendingSign: "Pending HP's Signature",
        hpSign: 'HP Has Signed',
        dpPendingSign: "Pending DP's Signature",
        dpSign: 'DP Has Signed'
      },
      file: {
        projectInfo: 'Project materials',
        deliveryDoc: 'Project Deliverables',
        contractId: 'Relevant contract number',
        delivered: 'To be delivered',
        inDelivered: 'Delivered',
        forAcceptance: 'Pending acceptance',
        toPay: 'To be paid',
        havePay: 'Paid',
        deliverConfirm: 'Confirm the delivery?',
        projectTip: 'For project working materials for both parties',
        fileTip: 'For project deliverables'
      },
      cost: {
        associatedNum: 'Associated contract number',
        phase: 'Phase',
        price: 'Amount',
        status: 'Status',
        payTime: 'Payment time',
        operator: 'Operator',
        operation: 'Operate',
        payText: 'Pay',
        acceptance: 'Acceptance',
        amountPaid: 'Amout Paid',
        receivedAmount: 'Amout received',
        unpaidAmount: 'Unpaid Amount',
        unpaidAmAmountNotReceivedount: 'Amount not received',
        agreedRate: 'Agreed Hourly Rate',
        timeTnterval: 'Time period',
        subtotalWorkingHours: 'Total working hours',
        oneClickPayBtn: 'Pay together',
        oneClickMsg: 'No orders available for payment',
        existBillText:
          "You've already have an unpaid combination bill, please pay the this bill first. Before you pay it, you can't generate a new one.",
        payTogetherText:
          'Pay together will combine all current unpaid bills into one bill, are you sure to combine them?'
      },

      acptText: 'Accept the delivery?',
      noAcptText: 'Rejection confirmed?',
      constractCode: 'Contract number',
      submitText: 'Confirm the delivery?',
      reason: 'Please enter reasons',
      cancelSure: 'Contract cancellation confirmed?',
      signSure: 'Sign the contract? ',
      resetSend: 'Re-initiate contract signing?', // 新增加
      toInitiate: 'Re-initialize contract signing', // 新增加
      signed: 'Signing',
      prePay: 'Pre-payment',
      finalPay: 'Balance payment',
      payStep: 'Phase',
      tab1: 'Project contract', // 项目合同
      tab2: 'Project delivery', // 项目交付
      tab3: 'Project fees', // 项目费用
      tab4: 'Project reviews', // 项目评价
      tab5: 'Project members',
      projectHours: 'Project Working Hours', //项目工时
      over: 'Confirm the project completion?',
      terminationSure: 'Contract termination confirmed?',
      addMember: 'Add new members',
      intoChart: 'Join the chat',
      pleaseAdd: 'Please add team members' // 新增加
    },
    // 订单状态
    orderStatus: {
      payOver: 'Paid',
      inPayment: 'Payments in process',

      noPay: 'To be paid',
      acceptance: 'Accepted',
      noAcceptance: 'Pending acceptance',
      complete: 'Accomplished',
      inDelivery: 'Delivered',
      noDelivery: 'Pending delivery',

      progress: 'Ongoing',
      contract: 'Pending signature',
      completed: 'Accomplished',
      cancelled: 'Cancelled',
      projOver: 'Complete project'
    },
    // 评论
    comment: {
      comment: 'Review',
      score: 'Score',
      enterRating: 'Please enter your score',
      remark: 'Review comments',
      enerRemark: 'Please enter your review comments',
      comList: 'Comments list',
      noEvaluation: 'No comments yet', // 新增加
      toEvaluate: 'Go and comment', // 新增加
      // HP的列表
      hpList: [
        'On time payments',
        'Smooth communications',
        'Reasonable',
        'Clear requirements',
        'Timely response',
        'Delayed payments',
        'Difficult to communicate',
        'Unreasonable',
        'Ambiguous requirements ',
        'Slow response'
      ],
      dpList: [
        'On time delivery',
        'On quality delivery',
        'Good attitude',
        'Easy to work with',
        'Strong professional ability',
        'Excellent skillset',
        'Seasoned veteran',
        'Sharp mind',
        'Good insights',
        'Delayed delivery',
        'Delivery failed to meet cretiria',
        'Bad attitude',
        'Difficult to work with',
        'Lack of professional ability',
        'Limited skillset',
        'Inexperienced',
        'Slow response',
        'Lack of insights'
      ]
    }
  },

  // 招标审核
  tender: {
    tabAll: 'All DPs',
    tabIntent: 'Preferred DPs',
    bar: 'Bid check',
    orderType: 'Task type',
    seeMore: 'View more',
    bidding: 'In bidding',
    dpInfo: 'DP information',
    isTrue: 'Rejection confirmed?',
    reasons: 'Reasons',
    confirm: 'Check passed?',
    radioOne: 'Pass, DP to start the contract',
    radioTwo: 'Pass, HP to start the contract',
    consider: 'Let me think twice',
    under: 'Under HP review',
    inBidding: 'In bidding',
    success: 'Bid success',
    error: 'Bid failed',
    failedPass: 'HP review failed',
    rejected: 'Reject',
    empty: 'No bid check yet',
    dpEmpty: 'No bid check data yet',
    reason: 'Reasons for failed'
  },
  //工作台
  workbench: {
    dp: {
      title_bidding: 'In bidding',
      restTime: 'Remaining time'
    },
    hp: {
      title: 'Pending',
      tenderReview: 'Bid review',
      title_contract: 'Pending contracts',
      title_1: 'In execution',
      orderType: 'Task type',
      view: 'View',
      more: 'More',
      pay: 'Paid',
      deliveryTime: 'Delivery time',
      progress: 'In execution'
    }
  },
  // 首页
  home: {
    airAge: {
      title: 'Product R&D and Digitalization & Intelligentization Tool',
      text: 'At the same time, AirRnd offer a product R&D and project/program lifecycle management tool for discrete industries to further empower our company users to boost their efficiency',
      more: 'More'
    },
    findWork: {
      tip: 'For Development Partners',
      title: 'Freelance and increase output',
      title2: '',
      text: 'Individuals to wave goodbye to boring routine office lives, unleash potentials in a freelancing way. Small business to expand customers network and make more money.',
      find: 'Find work'
    },
    footer: {
      members: 'Member System', // 新修改
      tool: '产品研发工具AirEdge', // 修改
      join: 'Join AirRnd',
      firm: 'About us',
      companyLink: 'Zhiyanhui Company Website',
      airEdgeLink: 'AirEdge',
      service: 'User Agreement',
      privacy: 'Privacy Policy',
      questions: 'AirRnd FAQ',
      record1: '沪ICP备2022012135号-1',
      record2: '沪B2-20220458',
      record3: '沪闵人社3101120201033 号',
      copyright: '©2022 AirRnd (Shanghai) Technology Co.,Ltd.'
    },
    partner: {
      bar: 'For Hiring Partners',
      title: 'Lower cost, boost efficiency and fill capability gaps',
      text: 'Optimize cost structure, one stop solution for tidal demands and competence gap problems.',
      publish: 'Publish a project',
      find: 'Find talent',
      tips: 'Not sure how to start? We can help',
      toUpgrade: 'Only verified HPs can publish a project, please verify first',
      toCert: 'You need to verify your account to publish a project',
      goToCert: 'Go to verify',
      toRegister: 'Please activate your account via the link sent to your Email'
    },
    say: 'Their voices',
    hot: {
      title: 'Popular talent category',
      set: 'System development engineer',
      hardEngineer: 'Hardware development engineer',
      manager: 'Product manager',
      systemTest: 'System test engineer',
      hardTest: 'Hardware test engineer',
      pm: 'Program/Project manager',
      softEngineer: 'Software engineer',
      safety: 'Safety engineering',
      purchase: 'Sourcing/Procurement manager',
      softTest: 'Software test engineer',
      mode: 'Modelling and simulation',
      legal: 'Contract & Legal manager'
    },
    top: {
      title: 'AirRnd',
      text: 'Say goodbye to institutional way of work. Get job done, online.',
      trust: 'Trusted by'
    },
    why: {
      subTitle: 'Why AirRnd',
      teamTitle: 'Globally accessible resources from anywhere',
      teamText:
        'Seasoned global resources enlisted to help enterprise to optimize cost structure, smooth out tidal demands and fill the domain proprietary knowledge gap.',
      processTitle: 'Redefine work and benefit people',
      processText:
        'Redefine work, enable individuals creating their own values, help bordered and institutional organizations to evolve into border-less and crowded ones. ',
      safeTitle: 'Professional and swift operations',
      safeText:
        ' Electronic contracting, project tracking and online payment to flow in a seamless, safe and swift way.'
    },
    becomeDP: 'Sign up as a Development Partner (DP) first to find work', // 新增加
    becomeHP: 'Sign up as a Hiring Partner (HP) first to find talent' // 新增加
  },
  // 链接相关
  link: {
    // help: 'Help and Support',
    // email: 'Email',
    // phone: 'The phone',
    // public: 'The public',
    // code: 'link-in.png'
    help: 'Support and Service',
    email: 'Email',
    phone: 'Telephone',
    public: 'Linkedin + Facebook',
    code: 'footer-code.png'
  },
  linkMenu: {
    join: 'Join AirRnd',
    help: 'Support and Service',
    conditions: 'Terms and Conditions', // 新增加
    service: 'User Agreement',
    firm: 'About us',
    // terms: 'User Agreement',
    tort: 'Intellectual Property', // 新增加
    law: 'AirRnd Legal Statement', // 新增加
    privacy: 'Privacy Policy',
    user: 'User Agreement',
    questions: 'AirRnd FAQ',
    userEntry: 'User Entry',
    cancellation: 'Account Cancellation',
    credit: 'Credit',
    cookies: 'Cookie Policy'
  },
  // 相关提示信息
  tipMsg: {
    parbidding: "You cannot bid on your own team's projects",
    noTopic: "You don't have to talk to yourself",
    leaveMsg: 'Leave a message to HP',
    desc: 'A few sentences explaining to HP why you should take on this project'
  },
  // 会员卡相关
  members: {
    title: '成为智研汇高阶会员',
    secondTitle: '更超值 更高效',
    tipOne: '更高曝光，更易匹配',
    tipTwo: '1-1 专人服务',
    tipThree: '更多流程辅导与内容辅导',
    tipFour: '更大存储',
    free: '免费',
    determined: '待定',
    need: '每日仅需',
    unit: '元',
    month: '月',
    season: '季',
    year: '年',
    tabsHP: 'HP会员',
    tabsDP: 'DP会员',
    serive: '服务说明',
    certUser: '认证用户',
    advUser: '高级用户',
    enjoyUser: '尊享用户',

    task: '发布任务和招标',
    resource: '经过认证的DP资源',
    payText: '快捷的在线支付',
    contract: '快捷的电子合同',
    easy: '简易的线上合作',
    customer: '标准客户服务',
    cloudStorage: '标准云储存空间',
    support: '专人支持',
    release: '发布项目流程辅导',
    coach: '合同撰写流程辅导',
    addStorage: '额外云储存空间',
    mode: '项目发布专业模版库',
    mordStorage: '更多额外云储存空间',
    dpMatch: '每月三次保证DP匹配成功',
    resourceHp: '经过认证的HP资源',
    classic: '经典之选',
    best: '增强之选',
    ideal: '理想之选',
    now: '立即开通',
    recommend: '推荐',
    dialog: {
      amount: '应付金额',
      alipay: '支付宝支付',
      weChat: '微信支付'
    }
  },
  // 新增翻译内容
  addContent: {
    loginTip: 'Please log in for details ',
    residue: 'Remaining',
    day: 'days',
    autoAcceptance: 'Automatically accepted ',
    order: {
      apply: 'Contract cancellation request raised, pending confirmation', // 完整的话是 : 已申请作废合同 待DP/HP确认
      sure: 'Confirm ',
      noCancel: 'Deny contract cancellation',
      confirmContract: 'Confirm contract contents',
      stay: 'Awaiting {0} to confirm contract',
      confirmTrue:
        "Click 'confirm' button upon you agree with all contents in the contract ",
      viewerContract: 'Review contract',
      confirmVoid: 'Confirm to void the contract?',
      rejectedVoid: 'Contract voidance denied ',
      noBank: 'No bank account information, please add them in',
      confirmPay: 'Confirm the payment?',
      payTrue: 'Payment confirmed'
    },
    updateLink: 'Change link name',
    updateFileName: 'Change file name',
    allFile: 'All files',
    readError: 'Read error',
    changeTel: 'Change mobile number',
    prePayTip: "Pre-payment ratio can't be 0",
    finalPayTip: "Balance payment ratio can't be 0",
    stepTip: 'Phase ',
    stepTip2: "  Payment ratio can't be 0.",
    docsignTip:
      "Note: After initiating contract signing, please go to your registered email box to find the email from DocuSign and follow the instructions to complete the electronic signature. After you have signed, don't forget to remind the other party to sign in the same way.",
    signTip:
      "Please go to your registered email box to find the email from DocuSign and follow the instructions to complete the electronic signature. After you have signed, don't forget to remind the other party to sign in the same way.",
    sendSignSuccess:
      'Signing initiating succeeded！Please wait for text message from eqianbao, and operate as per the prompt.'
  },
  createAccount: {
    tabsTitle: {
      personal: 'Studio Account',
      enterprise: 'Company Account'
    },
    blankFieldTips: 'Mandatory fields cannot be empty characters',
    personal: {
      name: 'Name',
      certificateType: 'Certificate Type',
      cardId: 'ID Number',
      certExpDateStart: 'Certificate Start Date',
      certExpDateEnd: 'Certificate End Date',
      phoneNo: 'Phone number',
      address: 'Address',
      bank: 'Bank Name',
      accountType: 'Bank Account Type',
      accountNo: 'Bank Account Number',
      accountName: 'Bank Account Name',
      accountAddress: 'Bank Address',
      subbranch: 'Bank Branch Name',
      cardIdFront: 'ID Card Front',
      cardIdBack: 'ID Card Back',
      occupation: 'Occupation',
      nationality: 'Nationality'
    },
    enterprise: {
      name: 'Company Name',
      enterpriseType: 'Company Type',
      legalPerson: 'Legal Representative Name',
      certificateType: 'Legal Representative Certificate Type',
      legalPersonCardId: 'Legal Representative ID Card Number',
      startDate: 'Legal Representative Certificate Start Date ',
      endDate: 'Legal Representative Certificate End Date',
      phoneNo: 'Legal Representative Phone Number',
      contacts: 'Contact Person Name',
      contactEmail: 'Contact Person Email',
      contactsCertificate: 'Contact Person Certificate Type',
      certificateNo: 'Contact Person ID Card Number',
      contactsCertificateStartDate: 'Contact Person Certificate Start Date',
      contactsCertificateEndDate: 'Contact Person Certificate End Date',
      contactsPhoneNo: 'Contact Person Phone Number',
      enterpriseAddress: 'Company Address',
      enterprisePhone: 'Company Phone',
      license: 'Business License Number',
      licenseStartDate: 'Business License Start Date',
      licenseEndDate: 'Business License End Date',
      bank: 'Bank Name',
      accountType: 'Account Type',
      accountNo: 'Bank Account Number',
      accountName: 'Bank Accont Name',
      accountAddress: 'Bank Account Address',
      subbranch: 'Bank Branch Name',
      legalPersonCardIdFront: 'Legal Representative ID Card Front',
      legalPersonCardIdBack: 'Legal Representative ID Card Back',
      contactsCardIdFrontcardIdFront: 'Contact Person ID Card Front',
      contactsCardIdFrontcardIdBack: 'Contact Person ID Card Back',
      licenseImg: ' Business License',
      industry: 'Industry',
      nationality: 'Country/Area'
    },
    pleaseInput: 'Please input ',
    pleaseUpload: 'Please upload ',
    select: 'Please select ',
    uploadTips: 'The total size of the picture can not exceed 10M',
    province: 'Province',
    area: 'Area',
    abroad: {
      accountName: 'Account Name',
      bankName: 'Bank Name',
      cardNumber: 'Bank Account',
      bankCode: 'Bank Code',
      bankAddress: 'Bank Address',
      swiftCode: 'SWIFT CODE'
    },
    // 开户校验提示
    errorTips: {
      certTypeError:
        'The ID type of the contact is different from that of the legal representative',
      certNoError:
        'The ID number of the contact is different from that of the legal representative',
      certStartDate:
        "The effective date of the contact's certificate is different from that of the legal representative",
      certEndDate:
        "The expiration date of the contact's certificate is different from that of the legal representative",
      nameError1: 'The account name is inconsistent with the company name',
      nameError2:
        'The account name is inconsistent with the name of the legal representative'
    }
  },
  // 引导开户信息
  guideCreateAccount: {
    hpTips: 'You can only sign a contract with DP after creating the account',
    dpTips: 'You can only get paid after creating the account'
  },
  recruit: {
    joinAirRnd: 'Join AirRnd',
    grow: 'Grow up together',
    popularPositions: 'Popular Positions',
    hot: 'Popular',
    emergency: 'Urgent',
    name: 'Name',
    please: 'Please ',
    phoneNo: 'Phone number',
    backList: 'Return to job list',
    apply: 'Apply',
    uploadResume: 'Upload CV',
    uploadFile: 'Upload file',
    pleaseInput: 'Please input ',
    pleaseUpload: 'Please upload ',
    uploadTip1:
      'You can upload a CV in PDF, DOC, PPT, DOCx, PPTX, JPG, or PNG format, with a maximum size of 2 MB ',
    uploadTip2:
      'You can upload documents, pictures, compressed packages, videos, audio files, and design files of a maximum size of 20MB',
    cancel: 'Cancel ',
    submitResume: 'Submit CV'
  },
  // 举报弹窗
  reportDialog: {
    title: 'To report',
    list: [
      'Spam',
      'Illegal information related to pornography and violence',
      'Insulting, malicious, and abusive behavior',
      'Infringement',
      'I am the reason'
    ],
    reasonText: 'Please enter the reason'
  }
}
