<template>
  <div class="app-wrapper">
    <HeaderCommon mode="black" />
    <div class="content-box">
      <div class="router-content">
        <router-view :key="key" />
      </div>
    </div>
  </div>
</template>

<script>
// import Sidebar from './components/Sidebar/indexView'
// import AppMain from './components/AppMain'
import HeaderCommon from '@/components/HeaderCommon'
// import AirDialog from '@/components/AirIm/AirDialog.vue'
export default {
  components: {
    // Sidebar,
    // AppMain,
    HeaderCommon
    // AirDialog
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
    unread() {
      return parseInt(this.$store.state.im.unread)
    }
  },
  methods: {
    handleShowIm() {
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  background: #f7f8fa;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header-wrap {
    background: #303e47;
  }
  .content-box {
    // margin: 24px 120px 80px;
    display: flex;
    flex: 1;
    overflow: hidden;
    width: 100%;
    .router-content {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 24px 0px;
      background: #f7f8fa;
    }
  }
}
</style>
