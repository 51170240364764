import {
  login,
  getRegister,
  logout,
  getImToken,
  getListByUserId,
  getSession,
  getTeamChange,
  getSessionPlus,
  noAuthTeamProjJoin,
  getCount,
  accountCancelQuery
} from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getItem, setItem, removeItem } from '@/utils/storage'

const user = {
  namespaced: true,
  state: {
    token: getToken(),
    userId: getItem('userId'),
    session: getItem('session'),
    hasAuth: false,
    avatar: '',
    permissions: [],
    teamInfo: getItem('teamInfo'),
    imToken: getItem('imToken'),
    count: 0 // 站内信
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    SET_IM_TOKEN: (state, imToken) => {
      state.imToken = imToken
      setItem('imToken', imToken)
    },
    SET_USER: (state, userId) => {
      state.userId = userId
      setItem('userId', userId)
    },
    // SET_NAME: (state, name) => {
    //   state.name = name
    // },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_lETTER_COUNT: (state, count) => {
      state.count = count
    },
    // SET_PERMISSIONS: (state, permissions) => {
    //   state.permissions = permissions
    // },
    // SET_AUTHORITIES: (state, payload) => {
    //   state.authorities = payload
    // },
    // SET_OPERATION_AUTH: (state, payload) => {
    //   state.operationAuth = payload
    // },
    SET_AUTH: (state, hasAuth) => {
      state.hasAuth = hasAuth
    },
    SET_SESSION: (state, payload) => {
      state.session = payload
      setItem('session', payload)
    },
    SET_USER_STATUS: (state, payload) => {
      state.userStatus = payload
    },
    SET_TEAM_INFO: (state, payload) => {
      state.teamInfo = payload
      setItem('teamInfo', payload)
    }
  },

  actions: {
    GetRegister({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        getRegister(userInfo)
          .then((res) => {
            const { token, userId } = res.data
            commit('SET_USER', userId)
            commit('SET_TOKEN', token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 被邀请的注册
    GetTeamRegister({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        noAuthTeamProjJoin(userInfo)
          .then((res) => {
            const { token, userId } = res.data
            commit('SET_USER', userId)
            commit('SET_TOKEN', token)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((res) => {
            const token = res.data.jwtToken
            const { userId } = res.data
            commit('SET_TOKEN', token)
            commit('SET_USER', userId)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 登录
    GetImLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        getImToken(userInfo)
          .then((res) => {
            const { imToken } = res.data
            commit('SET_IM_TOKEN', imToken)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取站内信的条数
    GetLetterCount({ commit }) {
      return new Promise((resolve, reject) => {
        getCount()
          .then((res) => {
            commit('SET_lETTER_COUNT', res.data)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    GetSession(context) {
      const commit = context.commit
      return new Promise((resolve, reject) => {
        getSession({})
          .then((res) => {
            const data = res.data
            const avatar =
              data.avatar ||
              'https://res.airrnd.com/disk/load/28e2d95aeeafc2b30a71b05f9ab6b178.png'
            commit('SET_AVATAR', avatar)
            commit('SET_SESSION', data)
            let hasAuth =
              data && (data.auth == '1' || data.auth == '2') ? true : false
            commit('SET_AUTH', hasAuth)
            commit('SET_USER', data.userId)
            context.dispatch('GetLetterCount')
            resolve(res)
          })
          .catch((error) => {
            // this.$router.push({ path: '/login' }).catch(() => {})
            reject(error)
          })
      })
    },

    // 获取团队用户信息
    GetTeamInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getListByUserId()
          .then((res) => {
            const { data } = res.data
            // const user = res.data
            // const avatar =
            //   user.avatar === ''
            //     ? require('@/assets/images/profile.jpg')
            //     : process.env.VUE_APP_BASE_API + user.avatar
            commit('SET_TEAM_INFO', data)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 切换团队用户信息
    ChangeTeam({ commit }, info) {
      return new Promise((resolve, reject) => {
        getTeamChange(info)
          .then((res) => {
            const { token, userId } = res.data
            // const user = res.data
            // const avatar =
            //   user.avatar === ''
            //     ? require('@/assets/images/profile.jpg')
            //     : process.env.VUE_APP_BASE_API + user.avatar
            commit('SET_TOKEN', token)
            commit('SET_USER', userId)
            setToken(token)
            commit('SET_SESSION', null)
            removeItem('session')
            commit('SET_SESSION', null)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 新增团队后根据teamId获取用户session信息
    GetSessionPlus({ commit }, info) {
      return new Promise((resolve, reject) => {
        getSessionPlus(info)
          .then((res) => {
            const data = res.data.authInfo
            const avatar =
              data.avatar ||
              'https://res.airrnd.com/disk/load/28e2d95aeeafc2b30a71b05f9ab6b178.png'
            commit('SET_AVATAR', avatar)
            commit('SET_SESSION', data)
            let hasAuth =
              data && (data.auth == '1' || data.auth == '2') ? true : false
            commit('SET_AUTH', hasAuth)
            commit('SET_USER', data.userId)
            setToken(res.data.token)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 切换团队角色
    ChangeRole({ commit }, info) {
      return new Promise((resolve, reject) => {
        getTeamChange(info)
          .then((res) => {
            const { token, userId } = res.data
            // const user = res.data
            // const avatar =
            //   user.avatar === ''
            //     ? require('@/assets/images/profile.jpg')
            //     : process.env.VUE_APP_BASE_API + user.avatar
            commit('SET_TOKEN', token)
            commit('SET_USER', userId)
            setToken(token)
            this.GetSession()
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout({})
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_SESSION', null)
            commit('SET_USER', '')
            removeToken()
            removeItem('session')
            removeItem('userId')
            removeItem('teamInfo')
            removeItem('countryList')
            sessionStorage.clear()
            localStorage.clear()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 清除所有缓存
    ClearCache({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_SESSION', null)
            commit('SET_USER', '')
            removeToken()
            removeItem('session')
            removeItem('userId')
            removeItem('teamInfo')
            sessionStorage.clear()
            localStorage.clear()
            removeItem('countryList')
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    cleanSession({ commit }) {
      commit('SET_SESSION', null)
      removeItem('session')
      return Promise.resolve()
    },
    // 查询当前账户的状态 是否注销中
    GetAccountStatus() {
      return new Promise((resolve, reject) => {
        accountCancelQuery()
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}

export default user
