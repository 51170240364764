<template>
  <message-basic class="im-message-image" v-bind="$attrs" v-on="$listeners">
    <template v-slot:default="{ message }">
      <el-image
        style="width: 100px; height: 100px"
        :src="getUrl(message.content)"
        fit="contain"
        lazy
      >
      </el-image>
    </template>
  </message-basic>
</template>

<script>
import MessageBasic from '@/components/AirIm/message/Basic.vue'
export default {
  name: 'MessageImage',
  components: {
    MessageBasic
  },
  methods: {
    getUrl(obj) {
      const imgObj = JSON.parse(obj)
      return imgObj?.snapshotPicture?.url
    }
  }
}
</script>

<style lang="scss">
.im-message-image {
  .im-message-content {
    padding: 0;
    cursor: pointer;
    overflow: hidden;
    line-height: 1;
    font-size: 0;
    img {
      max-width: 100%;
      min-width: 100px;
      height: 100px;
      display: block;
      border-style: none;
      vertical-align: middle;
      border-radius: 2px;
    }
  }
}
</style>
