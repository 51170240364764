// 登录注册相关路由
const Login = [
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/login/RegisterView')
  },
  {
    path: '/register/result',
    name: 'RegisterResult',
    component: () => import('@/views/login/ActiveResultsPage')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/LoginView')
  },
  {
    path: '/login/forget',
    name: 'Forget',
    component: () => import('@/views/login/ForgotPassword')
  },
  {
    path: '/login/reset',
    name: 'LoginReset',
    component: () => import('@/views/login/LoginReset')
  },
  {
    path: '/login/forget/over',
    name: 'ForgetOver',
    component: () => import('@/views/login/ForgotOver')
  },
  {
    path: '/project',
    name: 'Project',
    component: () => import('@/views/login/ProjectExperience')
  },
  {
    path: '/register/active',
    name: 'RegisterActive',
    component: () => import('@/views/login/ActivePage')
  },
  {
    path: '/middle',
    name: 'InviteMiddle',
    component: () => import('@/views/login/InviteMiddle')
  },
  {
    path: '/register/audit',
    name: 'RegisterAuditPage',
    component: () => import('@/views/login/RegisterAuditPage')
  }
]

export default Login
