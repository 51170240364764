<template>
  <el-dialog
    :visible.sync="isVisible"
    width="480px"
    class="report-wrap"
    :title="$t('reportDialog.title')"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="content">
      <el-radio-group v-model="reportType">
        <el-radio v-for="(v, i) in 5" :key="i" :label="i.toString()">
          <template v-if="i !== 4">
            {{ $t(`reportDialog.list[${i}]`) }}
          </template>
          <template v-else>
            <el-input
              v-if="reportType == '4'"
              v-model="reason"
              :placeholder="$t('reportDialog.reasonText')"
              style="width: 400px"
              :maxlength="1000"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 6 }"
            />
            <template v-else>
              {{ $t(`reportDialog.list[${i}]`) }}
            </template>
          </template>
        </el-radio>
      </el-radio-group>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelHandle">{{ $t('btns.cancel') }}</el-button>
      <el-button type="primary" @click="submit">
        {{ $t('btns.sure') }}
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import { visibleMixin } from '@/common/mixin'
import { Message } from 'element-ui'
import { createComplaint } from '@/api/complaint'
export default {
  mixins: [visibleMixin],
  props: {
    reportInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      reportType: '0', // 举报类型
      reason: this.$t(`reportDialog.list[4]`) // 原因
    }
  },
  watch: {
    reportType() {
      this.reason = this.$t(`reportDialog.list[4]`)
    }
  },
  methods: {
    cancelHandle() {
      this.isVisible = false
      this.reportType = '0'
    },
    async submit() {
      if (this.reportType == '4' && !this.reason.trim()) {
        Message.error('请输入理由')
        return
      }
      const params = {
        type: '0' + (Number(this.reportType) + 1),
        content: this.reportInfo.content,
        userId: this.reportInfo.userId || this.reportInfo.sendID
      }
      if (this.reportType == '4') {
        params.argument = this.reason
      }
      try {
        await createComplaint(params)
        this.cancelHandle()
        Message.success('举报成功')
      } catch (err) {
        console.log('err', err)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.report-wrap {
  ::v-deep {
    .el-dialog__header {
      padding: 17px 24px 16px;
    }
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #333;
      display: block;
    }
    .el-dialog__body {
      border-top: 1px solid #f2f3f5;
      border-bottom: 1px solid #f2f3f5;
      padding: 16px 24px 49px;
      font-size: 14px;
      color: #333;
      max-height: 500px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 16px 24px;
    }
  }
  .content {
    ::v-deep {
      .el-radio {
        margin-bottom: 24px;
        line-height: 22px;
        margin-right: 0;
        color: #333;
        display: flex;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .el-radio__input {
        display: flex;
      }
      .el-radio__inner {
        width: 12px;
        height: 12px;
      }
    }
  }
  .dialog-footer {
    ::v-deep {
      .el-button {
        font-size: 14px;
        padding: 8px 15px;
        border-radius: 4px;
      }
    }
  }
}
</style>
