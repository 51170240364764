<template>
  <div class="footer-wrap">
    <div class="footer">
      <div class="img">
        <el-image :src="require('@/assets/images/home-footer-logo.png')" />
      </div>
      <div class="center">
        <div class="text">
          <template>
            <span
              class="hover"
              v-for="f in footerList"
              :key="f.id"
              @click="f.openUrl ? openUrl(f.link) : openPage(f.link)"
            >
              {{ f.name }}
            </span>
          </template>
        </div>
        <div class="p-tip">
          <span v-for="p in phoneList" :key="p.id" @click="openPage(p.link)">
            <i class="iconfont icon" :class="p.icon"></i>
            {{ p.name }}
          </span>
        </div>
        <div class="record">
          <span class="cursor" @click="openUrl('https://beian.miit.gov.cn/')">{{
            $t('home.footer.record1')
          }}</span>
          <span class="line">|</span>
          <span
            class="cursor"
            @click="openUrl('https://dxzhgl.miit.gov.cn/#/home')"
          >
            {{ $t('home.footer.record2') }}
          </span>
          <span class="line">|</span>
          <span class="cursor">
            {{ $t('home.footer.record3') }}
          </span>
          <span class="line">|</span>
          <span :style="{ marginRight: '10px' }">
            {{ $t('home.footer.copyright') }}
          </span>
          <div class="cursor">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014535"
              class="link"
            >
              <img src="https://www.beian.gov.cn/img/ghs.png" />
              沪公网安备 31011202014535号
            </a>
          </div>
        </div>
        <div class="code">
          <template v-if="lang === 'zh'">
            <el-image
              class="code-img"
              :src="require('@/assets/images/foot_zh_01.jpg')"
            />
            <el-image
              class="code-img"
              :src="require('@/assets/images/foot_zh_02.jpg')"
            />
          </template>
          <template v-else>
            <el-image
              class="link"
              style="width: 56px; margin-top: 18px"
              :src="require('@/assets/images/link-in.png')"
              @click="toLinkedIn"
            />
          </template>
        </div>
      </div>
      <div class="right">
        <template v-if="lang === 'zh'">
          <el-image
            class="code-img"
            :src="require('@/assets/images/foot_zh_01.jpg')"
          />
          <el-image
            class="code-img"
            :src="require('@/assets/images/foot_zh_02.jpg')"
          />
        </template>
        <template v-else>
          <el-image
            class="link"
            style="width: 56px; margin-top: 18px"
            :src="require('@/assets/images/linked.jpeg')"
            @click="toLinkedIn"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FooterView',
  data() {
    return {
      phoneList: [
        {
          name: '400-067-5578',
          icon: 'icon-lianxi-hetong',
          id: '6'
        },
        {
          name: 'service@IndusLAB.cc',
          icon: 'icon-youxiang',
          id: '7'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['lang']),
    footerList() {
      return [
        // {
        //   name: this.$t('home.footer.tool'),
        //   link: '',
        //   id: '1'
        // },
        // {
        //   name: this.$t('home.footer.join'),
        //   link: '',
        //   id: '2'
        // },
        // {
        //   name: this.$t('home.footer.members'),
        //   link: '/members',
        //   id: '2'
        // },
        // {
        //   name: this.$t('home.footer.firm'),
        //   link: '/company/culture',
        //   id: '3'
        // },
        {
          name: this.$t('home.footer.companyLink'),
          link: 'https://induslab.cc/',
          id: '7',
          openUrl: true
        },
        {
          name: this.$t('home.footer.airEdgeLink'),
          link: 'https://www.airedge.cc/',
          id: '8',
          openUrl: true
        },
        {
          name: this.$t('home.footer.join'),
          link: '/recruit/index',
          id: '2'
        },
        {
          name: this.$t('home.footer.service'),
          link: '/link/service',
          id: '4'
        },
        {
          name: this.$t('home.footer.privacy'),
          link: '/link/privacy',
          id: '5'
        },
        {
          name: this.$t('home.footer.questions'),
          link: '/answer/questions',
          id: '6'
        }
      ]
    }
  },
  methods: {
    openUrl(link) {
      if (!link) {
        return
      }
      window.open(link)
    },
    openPage(link) {
      if (!link) {
        return
      }
      let url = location.origin + link
      window.open(url)
    },
    toLinkedIn() {
      window.open('https://www.linkedin.com/company/airrnd')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrap {
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  background: #303e47;
}
.footer {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .center {
    flex: 1;
    text-align: left;
    padding-top: 42px;
  }
  .img {
    width: 56px;
    height: 42px;
    margin-right: 40px;
    padding-top: 42px;
  }
  span {
    margin-right: 40px;
    display: inline-block;
    padding-bottom: 8px;
    color: #cccccc;
    &.hover {
      cursor: pointer;
    }

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
  }
  .record {
    span {
      margin-right: 0;
    }
    .cursor {
      cursor: pointer;
      a {
        cursor: pointer;
        color: #ccc;
        text-decoration: none;
        img {
          vertical-align: sub;
          margin-right: 4px;
        }
        &:hover {
          color: #fff;
        }
      }
    }
    .line {
      margin: 0 12px;
    }
  }
  .code-img {
    width: 112px;
    height: 112px;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
  .link {
    cursor: pointer;
  }
  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 14px;
    text-align: center;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.2);
  }
}

@media screen and (min-width: 968px) {
  .footer {
    max-width: 1200px;
    min-width: 968px;
    width: 90%;
    min-height: 190px;
    .text {
      display: flex;
    }
    span {
      margin-right: 36px;
    }
    .right {
      padding-top: 39px;
    }
    .code {
      display: none;
    }
  }
}
@media screen and (min-width: 700px) and (max-width: 968px) {
  .footer {
    padding: 0 64px 55px;
    min-height: 190px;
    span {
      margin-right: 36px;
    }
    .record {
      padding-top: 20px;
    }
    .right {
      display: none;
    }
    .code {
      padding-top: 35px;
    }
  }
}
@media screen and(max-width: 700px) {
  .footer {
    padding: 0 64px 55px;
    min-width: 486px;
    span {
      margin-right: 36px;
    }
    .record {
      padding-top: 12px;
    }
    .right {
      display: none;
    }
    .code {
      padding-top: 35px;
    }
  }
}
</style>
