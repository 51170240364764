import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Cookies from 'js-cookie'
import Element from 'element-ui'
import './styles/element-variables.scss'
import './permission'
import VueQuillEditor from 'vue-quill-editor'

import '@/icons' // icon

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

store.dispatch('global/setLang', i18n.locale)

console.log('errcode====', i18n.t('code.0'))

import openIM, {
  CbEvents
  // pageCount
} from '@/components/AirIm/utils/openIM.js'

openIM.on(CbEvents.OnRecvNewMessage, (res) => {
  store.dispatch('im/recvNewMessage', res)
})

openIM.on(CbEvents.OnConversationChanged, () => {
  store.dispatch('im/getAllConversation')
})

openIM.on(CbEvents.OnNewConversation, () => {
  store.dispatch('im/getAllConversation')
})

Vue.use(VueQuillEditor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
