import Vue from 'vue'
import {
  loadContractForCreate,
  loadContractForEdit,
  createContract,
  updateContract
} from '@/api/contract/contract'
import { treeModuleToArray, parseModules } from '@/utils/tools'
import { debounce } from 'throttle-debounce'

const updateTop = debounce(
  100,
  (state, top) => {
    if (top !== undefined && top !== null) {
      state.top = top || 0
    }
  },
  { atBegin: false }
)
function isCostModule(module) {
  // 费用模块
  return ['srvAndCost', 'costCN', 'costEN'].indexOf(module.code) !== -1
}
function updateCostModule(info, item, deep = false) {
  if (item && info && isCostModule(item)) {
    const data = item.data || {}
    data.projectName = info.projectName || ''
    data.currency = info.currency
    data.totalCost = info.totalCost || 0
    data.prePay = Object.assign(data.prePay || {}, info.prePay || {})
    data.finalPay = Object.assign(data.finalPay || {}, info.finalPay || {})
    data.procPay = (info.procPay || []).map((item) => {
      return {
        desc: item.desc,
        pct: item.pct,
        commitTime: item.commitTime,
        day: 0
      }
    })
  }
  if (deep && item.children && item.children.length > 0) {
    updateCostModules(info, item.children, deep)
  }
}
function updateCostModules(info, items, deep = false) {
  if (info && items && items.length > 0)
    items.forEach((item) => {
      updateCostModule(info, item, deep)
    })
}
function updateCostModulesWithState(state, deep = false) {
  // const items = (state.modules || []).filter((el) => isCostModule(el))
  updateCostModules(state.info, state.modules, deep)
}
function fillSysData(item, data = {}, deep = false) {
  data = data || {}
  if (item.code === 'adv') {
    const paramConfig = item.paramConfig || []
    const keys = paramConfig
      .filter((item) => item.sysData)
      .map((item) => item.name)
    item.data = item.data || {}
    keys.forEach((key) => {
      item.data[key] = data[key] || ''
    })
  }
  if (deep && item.children && item.children.length > 0) {
    fileSysDataModules(item.children, data, deep)
  }
}
function fileSysDataModules(items, data = {}, deep = false) {
  items.forEach((item) => {
    fillSysData(item, data, deep)
  })
}
function siblingNodeList(arr, pids) {
  if (pids.length === 0) return arr
  let ret = []
  for (let i = 0; i < pids.length; i++) {
    const id = pids[i]
    const idx = arr.findIndex((item) => item.id === id)
    if (idx === -1) {
      break
    } else {
      if (i == pids.length - 1) {
        ret = arr[idx].children || []
      } else {
        arr = arr[idx].children || []
      }
    }
  }
  return ret
}
const contract = {
  namespaced: true,
  state: {
    selectModule: null, // 选中的模块
    style: {
      fontFamily: 'Microsoft YaHei',
      fontSize: '16px'
    },
    height: 0,
    top: 0,
    contractId: null,
    projId: null,
    tplId: null,
    info: null,
    modules: [],
    editModule: null, // 正在编辑的模块
    dpTeamInfo: [],
    contextParams: null, // 上下文参数
    action: 'create'
  },
  mutations: {
    SET_STYLE(state, payload) {
      state.style = payload
    },
    SET_MODULES(state, payload) {
      state.modules = payload
    },
    SET_SELECT_MODULE(state, payload) {
      state.selectModule = payload
      if (state.editModule && state.editModule.id !== payload.id) {
        state.editModule = null
      }
    },
    SET_EDIT_MODULE(state, payload) {
      state.editModule = payload
      state.selectModule = payload
      // if (payload) {
      //   const idx = state.modules.findIndex((item) => item.id === payload.id)
      //   state.top = state.modules
      //     .filter((item, index) => index < idx)
      //     .map((item) => item.height || 0)
      //     .reduce((a, b) => a + b, 0)
      // }
    },
    UPDATE_EDIT_MODULE(state, payload) {
      if (!state.editModule) {
        state.editModule = payload
      } else {
        // Object.assign(state.editModule, {
        //   ...payload
        // })

        const editModule = { ...state.editModule, ...payload }
        const arr = siblingNodeList(state.modules, payload.pids || [])
        const idx = arr.findIndex((item) => item.id === payload.id)
        if (idx !== -1) {
          arr.splice(idx, 1, editModule)
        }

        // const ids = [...payload.pids, payload.id]
        // let arr = state.modules
        // for(let i = 0; i < ids.length; i++ ) {
        //   const id = ids[i]
        //   const idx = arr.findIndex(item => item.id === id)
        //   if (idx === -1) {
        //     break
        //   } else {
        //     if (i == ids.length - 1) {
        //       arr.splice(idx, 1, editModule)
        //     } else {
        //       arr = arr[idx].children || []
        //     }
        //   }
        // }

        // const editModule = { ...state.editModule, ...payload }
        // state.editModule = editModule
        // const idx = state.modules.findIndex((item) => item.id === editModule.id)
        // if (idx != -1) {
        //   state.modules.splice(idx, 1, editModule)
        // }
      }
    },
    UPDATE_MODULE_HEIGHT(state, payload) {
      const module = state.modules.find((item) => item.id == payload.id)
      if (module) {
        module.height = payload.height
        state.height = state.modules
          .map((item) => item.height || 0)
          .reduce((a, b) => a + b)
      }
      if (state.editModule && state.editModule.id === module.id) {
        state.editModule.height = payload.height
      }
    },
    UPDATE_TOP(state, payload) {
      updateTop(state, payload)
      // state.top = payload || 0
    },
    DEL_MODULE(state, payload) {
      const arr = siblingNodeList(state.modules, payload.pids || [])
      const idx = arr.findIndex((item) => item.id === payload.id)
      if (idx !== -1) {
        if (state.editModule && state.editModule.id === payload.id) {
          contract.mutations['SET_EDIT_MODULE'](state, null)
        }
        arr.splice(idx, 1)
      }

      // const idx = state.modules.findIndex((item) => item.id === payload.id)
      // if (idx == -1) {
      //   return
      // }
      // if (state.editModule && state.editModule.id === payload.id) {
      //   let module = null
      //   if (idx > 0) {
      //     module = state.modules[idx - 1]
      //   }
      //   contract.mutations['SET_EDIT_MODULE'](state, module)
      // }
      // state.modules.splice(idx, 1)
    },
    ADD_MODULE(state, payload) {
      state.modules.push(payload)
    },
    ADD_MODULE_PLUS(state, item) {
      const info = state.info
      updateCostModule(info, item)
      fillSysData(item, state.contextParams)
      state.modules.push(item)
    },
    TO_UP(state, payload) {
      const arr = siblingNodeList(state.modules, payload.pids || [])

      const idx = arr.findIndex((item) => item.id === payload.id)
      if (idx === -1 || idx === 0) {
        return
      }
      if (idx === 0) return
      const module = arr[idx]
      const modulePre = arr[idx - 1]
      Vue.set(arr, idx - 1, module)
      Vue.set(arr, idx, modulePre)
      if (arr) {
        // const idx = state.modules.findIndex(
        //   (item) => item.id === state.editModule.id
        // )
        // state.top = state.modules
        //   .filter((item, index) => index < idx - 1)
        //   .map((item) => item.height || 0)
        //   .reduce((a, b) => a + b, 0)
        // console.log('top', state.top)
      }
    },
    TO_DOWN(state, payload) {
      const arr = siblingNodeList(state.modules, payload.pids || [])

      const idx = arr.findIndex((item) => item.id === payload.id)
      if (idx == -1 && idx === arr.length - 1) {
        return
      }
      if (idx === arr.length - 1) {
        return
      }
      const module = arr[idx]
      const moduleAft = arr[idx + 1]
      Vue.set(arr, idx + 1, module)
      Vue.set(arr, idx, moduleAft)
      if (arr) {
        // const idx = state.modules.findIndex(
        //   (item) => item.id === state.editModule.id
        // )
        // state.top = state.modules
        //   .filter((item, index) => index < idx + 1)
        //   .map((item) => item.height || 0)
        //   .reduce((a, b) => a + b, 0)
      }
    },
    TO_TOP(state, payload) {
      const idx = state.modules.findIndex((item) => item.id === payload.id)
      if (idx == -1) {
        return
      }
      const module = state.modules[idx]
      state.modules.splice(idx, 1)
      state.modules.splice(0, 0, module)
    },
    TO_BOTTOM(state, payload) {
      const idx = state.modules.findIndex((item) => item.id === payload.id)
      if (idx == -1) {
        return
      }
      const module = state.modules[idx]
      state.modules.splice(idx, 1)
      state.modules.push(module)
    },
    SET_TPLID(state, payload) {
      state.tplId = payload
    },
    SET_PROJID(state, payload) {
      state.projId = payload
    },
    SET_CONTRACTID(state, payload) {
      state.contractId = payload
    },
    SET_INFO(state, payload) {
      state.info = payload
    },
    UPDATE_INFO(state, payload) {
      if (!payload) return
      state.info = payload
      updateCostModulesWithState(state, true)
    },
    EDIT_TPL_MODULES(state) {
      updateCostModulesWithState(state, true)
    },
    SET_ACTION(state, payload) {
      state.action = payload
    },
    SET_DP_TEAMINFO(state, payload) {
      state.dpTeamInfo = payload || []
    },
    RESET(state) {
      state.style = {
        fontFamily: 'Microsoft YaHei',
        fontSize: '14px'
      }
      state.height = 0
      state.top = 0
      state.contractId = null
      state.projId = null
      state.tplId = null
      state.info = null
      state.modules = []
      state.editModule = null
      state.selectModule = null
      state.dbTeamInfo = []
      state.contextParams = null
      state.action = 'create'
    }
  },
  actions: {
    setTplId({ commit }, payload) {
      commit('SET_TPLID', payload)
      return Promise.resolve()
    },
    setProjId({ commit }, payload) {
      commit('SET_PROJID', payload)
      return Promise.resolve()
    },
    setEditModule({ commit }, payload) {
      commit('SET_EDIT_MODULE', payload)
      return Promise.resolve()
    },
    updateModule({ commit, state }, payload) {
      commit('UPDATE_EDIT_MODULE', payload)
      const req = {
        projId: state.projId,
        tplId: state.tplId,
        contractId: state.contractId,
        info: JSON.stringify(state.info || '{}'),
        config: JSON.stringify({ style: state.style }),
        modules: JSON.stringify(state.modules || '[]')
      }
      console.log(req)
      return Promise.resolve()
    },
    updateModuleHeight({ commit }, payload) {
      commit('UPDATE_MODULE_HEIGHT', payload)
      return Promise.resolve()
    },
    delModel({ commit }, payload) {
      commit('DEL_MODULE', payload)
      return Promise.resolve()
    },
    addModel({ commit }, payload) {
      commit('ADD_MODULE', payload)
      return Promise.resolve()
    },
    addModelPlus({ commit }, payload) {
      commit('ADD_MODULE_PLUS', payload)
      return Promise.resolve()
    },
    toUp({ commit }, payload) {
      commit('TO_UP', payload)
      return Promise.resolve()
    },
    toDown({ commit }, payload) {
      commit('TO_DOWN', payload)
      return Promise.resolve()
    },
    toTop({ commit }, payload) {
      commit('TO_TOP', payload)
      return Promise.resolve()
    },
    toBottom({ commit }, payload) {
      commit('TO_BOTTOM', payload)
      return Promise.resolve()
    },
    loadContractFromTpl({ commit, state }, payload) {
      if (!payload.projId) {
        payload.projId = state.projId
      }
      if (
        state.tplId === payload.tplId &&
        state.modules != null &&
        state.modules.length > 0
      ) {
        return Promise.resolve()
      }
      return loadContractForCreate(payload).then(({ data }) => {
        const modules = data.modules
        parseModules(modules)
        // const modules = data.modules.map((item) => {
        //   item.data = isString(item.data)
        //     ? JSON.parse(item.data)
        //     : item.data || {}
        //   item.paramConfig =
        //     item.paramConfig && isString(item.paramConfig)
        //       ? JSON.parse(item.paramConfig)
        //       : item.paramConfig || {}
        //   item.name = item.name
        //     ? isString(item.name)
        //       ? item.name
        //       : JSON.stringify(item.name)
        //     : ''
        //   return item
        // })
        state.contextParams = data.params || {}
        fileSysDataModules(modules, state.contextParams, true)
        commit('SET_MODULES', modules)
        const config = JSON.parse(data.config)
        if (config.style) {
          commit('SET_STYLE', config.style)
        }
        commit('SET_TPLID', payload.tplId)
      })
    },
    loadContractForEdit({ commit, state }, payload) {
      console.log(payload)
      return loadContractForEdit(payload).then(({ data }) => {
        // 模块信息
        // const modules = data.modules.map((item) => {
        //   item.data = isString(item.data)
        //     ? JSON.parse(item.data)
        //     : item.data || {}
        //   item.paramConfig =
        //     item.paramConfig && isString(item.paramConfig)
        //       ? JSON.parse(item.paramConfig)
        //       : item.paramConfig || {}
        //   item.name = item.name
        //     ? isString(item.name)
        //       ? item.name
        //       : JSON.stringify(item.name)
        //     : ''
        //   return item
        // })
        const modules = data.modules
        parseModules(modules)
        state.contextParams = data.params || {}
        commit('SET_MODULES', modules)
        // 基本信息
        if (data.info) {
          commit('SET_INFO', JSON.parse(data.info) || {})
        }
        // 配置信息
        const config = JSON.parse(data.config)
        if (config.style) {
          commit('SET_STYLE', config.style)
        }
      })
      // payload = payload || [
      //   {
      //     id: 1,
      //     code: 'base',
      //     status: '0',
      //     perm: 2,
      //     data: {
      //       name: '委托技术开发合同',
      //       userNameA: '12',
      //       userAddrA: '',
      //       userLegalA: '',
      //       userCodeA: '',
      //       userNameB: '',
      //       userAddrB: '',
      //       userLegalB: '',
      //       userCodeB: ''
      //     }
      //   },
      //   {
      //     id: 2,
      //     code: 'srvAndCost',
      //     status: '0',
      //     perm: 2,
      //     data: {
      //       projectName: '',
      //       totalCost: 10000,
      //       prePay: {
      //         enable: true,
      //         day: 5,
      //         pct: 10
      //       },
      //       procPay: [
      //         {
      //           desc: '完成阶段1任务',
      //           day: 5,
      //           pct: 30
      //         },
      //         {
      //           desc: '完成阶段2任务',
      //           day: 5,
      //           pct: 20
      //         },
      //         {
      //           desc: '完成阶段3任务',
      //           day: 5,
      //           pct: 25
      //         }
      //       ],
      //       finalPay: {
      //         enable: true,
      //         day: 3,
      //         pct: 15
      //       },
      //       corpName: '',
      //       code: '',
      //       addr: '',
      //       mobile: '',
      //       openBank: '',
      //       acct: ''
      //     }
      //   },
      //   {
      //     id: 10,
      //     code: 'terms',
      //     status: '1',
      //     perm: 3,
      //     data: {
      //       title: '转委托',
      //       content: `乙方必须自行完成本合同约定的主要工作，除部分辅助型工作以外，不得转交第三方完成。`
      //     }
      //   },
      //   {
      //     id: 11,
      //     code: 'terms',
      //     status: '1',
      //     perm: 3,
      //     data: {
      //       title: '开发失败风险承担',
      //       content: `	因出现无法克服的技术困难导致开发失败的，任何一方均有权提前解除本合同，并要求按下列约定分担损失与善后：<br />
      //       甲方仍需按本合同约定支付已经发生的开发费用，但无需再向乙方支付报酬；甲方多支付的开发费用和报酬乙方应予退还。<br />
      //       开发部分失败的，双方应参照上述约定处理失败部分的开发费用和报酬；双方另有约定的除外。<br />
      //       上述“无法克服的技术困难”应当从以下三个方面进行综合考量：<br />
      //       （1）乙方已经尽到足够的主观努力，进行了现有条件下力所能及的工作，并已取得实质性进展；<br />
      //       （2）本项目在现有技术条件下具有足够的难度；<br />
      //       （3）从事本项目研究领域的专家认为研究开发失败属于合理的失败。<br />
      //       一方发现因作为开发标的的技术已经由他人公开（包括以专利权方式公开）的，适用上述约定。<br />
      //       一方发现存在有可能致使研究开发失败或部分失败的风险时，应当在7天内通知另一方并采取适当措施减少损失。逾期未通知并未采取适当措施而致使损失扩大的，应当就扩大的损失承担赔偿责任。<br />`
      //     }
      //   },
      //   {
      //     id: 12,
      //     code: 'terms',
      //     status: '1',
      //     perm: 3,
      //     data: {
      //       title: '开发成果交付',
      //       content: `交付内容。本项目开发完成后，乙方应向甲方交付开发成果以及全部技术资料。<br />
      //       交付形式<br />
      //       乙方交付的开发成果及相关技术资料形式应该包括：<br />
      //       见附件约定。<br />
      //       开发成果涉及到计算机软件的，乙方应当向甲方提供源代码以及描述软件流程、算法、设计思想的软件设计文档。<br />`
      //     }
      //   },
      //   {
      //     id: 3,
      //     code: 'ownership',
      //     status: '0',
      //     perm: 3,
      //     data: {
      //       num: 1
      //     }
      //   },
      //   {
      //     id: 4,
      //     code: 'change',
      //     status: '0',
      //     perm: 3,
      //     data: {}
      //   },
      //   {
      //     id: 5,
      //     code: 'contact',
      //     status: '0',
      //     perm: 3,
      //     data: {}
      //   },
      //   {
      //     id: 6,
      //     code: 'promise',
      //     status: '0',
      //     perm: 3,
      //     data: {}
      //   },
      //   {
      //     id: 7,
      //     code: 'conflict',
      //     status: '0',
      //     perm: 3,
      //     data: {}
      //   },
      //   {
      //     id: 8,
      //     code: 'attachment',
      //     status: '0',
      //     perm: 3,
      //     data: {}
      //   },
      //   {
      //     id: 9,
      //     code: 'sign',
      //     status: '0',
      //     perm: 2,
      //     data: {}
      //   }
      // ]
      // commit('SET_MODULES', payload)
      // return Promise.resolve(payload)
    },
    mergeContract({ commit, state }, payload) {
      const html = payload.html || ''
      const modules = treeModuleToArray(state.modules)

      let status = '0' // 草稿
      const idx = modules.findIndex((item) => item.status === '0')
      if (idx === -1) {
        status = '1' // 待确认
      }
      const reqData = {
        contractId: state.contractId,
        projId: state.projId,
        tplId: state.tplId,
        config: JSON.stringify({
          style: state.style
        }),
        info: JSON.stringify(state.info),
        modules: JSON.stringify(state.modules),
        html: html,
        status: status
      }
      const action = state.action
      const fn = action === 'create' ? createContract : updateContract
      return fn(reqData).then((res) => {
        const data = res.data
        commit('SET_CONTRACTID', data.id)
        commit('SET_ACTION', 'edit')
      })
    }
  }
}
export default contract
