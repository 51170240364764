// 通用请求
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
import { getToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import i18n from '@/i18n'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // config.headers['X-Auth-Type'] = '2'
    // if (!config.headers['x-rule'] && process.env.VUE_APP_RULE) {
    //   config.headers['x-rule'] = process.env.VUE_APP_RULE
    // }
    let lang = Cookies.get('lang') || 'en'
    config.headers['lang'] = lang
    config.headers['Accept-Language'] = lang === 'zh' ? 'zh_CN' : 'en_US'
    // config.headers['X-Auth-Type'] = '2'
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = `${token}`
      // config.headers['checkToken'] = '0'
      config.data = Object.assign({}, config.data || {})
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.errorCode !== 9000 && res.errorCode !== 0) {
      if ([2000, 990105].indexOf(res.errorCode) !== -1) {
        // to re-login
        MessageBox.confirm(
          i18n.t('logout.confirmTip'),
          i18n.t('logout.confirmTitle'),
          {
            confirmButtonText: i18n.t('logout.submitBtn'),
            cancelButtonText: i18n.t('logout.cancelBtn'),
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('user/LogOut').then(() => {
            router.replace({ path: '/login' }).then(() => {
              location.reload()
            })
          })
          store.dispatch('im/logoutIm')
          console.log(router)
        })
      } else {
        console.log(res)
        Message({
          message: i18n.t(`code.${res.errorCode}`) || 'Request Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  (error) => {
    console.log('报错: ' + error) // for debug
    Message({
      message: error.errorMessage || 'Network Err',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
