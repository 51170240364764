<template>
  <el-dialog
    :visible.sync="isVisible"
    width="486px"
    :before-close="handleClose"
    class="dialog"
  >
    <Reset v-if="isVisible" :isDialog="true" />
  </el-dialog>
</template>

<script>
import Reset from '@/views/login/LoginReset.vue'
export default {
  components: {
    Reset
  },
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    show() {
      this.isVisible = true
    },
    handleClose() {
      this.isVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  ::v-deep {
    .el-dialog__body {
      padding: 24px 0;
    }
  }
}
</style>
