import axios from 'axios'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'

// import HTMLtoDOCX from 'html-to-docx'

export function changeToChineseMoney(Num) {
  //判断如果传递进来的不是字符的话转换为字符
  if (typeof Num == 'number') {
    Num = new String(Num)
  }
  Num = Num.replace(/,/g, '') //替换tomoney()中的“,”
  Num = Num.replace(/ /g, '') //替换tomoney()中的空格
  Num = Num.replace(/￥/g, '') //替换掉可能出现的￥字符
  if (isNaN(Num)) {
    //验证输入的字符是否为数字
    //alert("请检查小写金额是否正确");
    return ''
  }
  //字符处理完毕后开始转换，采用前后两部分分别转换
  var part = String(Num).split('.')
  var newchar = ''
  //小数点前进行转化
  for (var i = part[0].length - 1; i >= 0; i--) {
    if (part[0].length > 10) {
      return ''
      //若数量超过拾亿单位，提示
    }
    var tmpnewchar = ''
    var perchar = part[0].charAt(i)
    switch (perchar) {
      case '0':
        tmpnewchar = '零' + tmpnewchar
        break
      case '1':
        tmpnewchar = '壹' + tmpnewchar
        break
      case '2':
        tmpnewchar = '贰' + tmpnewchar
        break
      case '3':
        tmpnewchar = '叁' + tmpnewchar
        break
      case '4':
        tmpnewchar = '肆' + tmpnewchar
        break
      case '5':
        tmpnewchar = '伍' + tmpnewchar
        break
      case '6':
        tmpnewchar = '陆' + tmpnewchar
        break
      case '7':
        tmpnewchar = '柒' + tmpnewchar
        break
      case '8':
        tmpnewchar = '捌' + tmpnewchar
        break
      case '9':
        tmpnewchar = '玖' + tmpnewchar
        break
    }
    switch (part[0].length - i - 1) {
      case 0:
        tmpnewchar = tmpnewchar + '元'
        break
      case 1:
        if (perchar != 0) tmpnewchar = tmpnewchar + '拾'
        break
      case 2:
        if (perchar != 0) tmpnewchar = tmpnewchar + '佰'
        break
      case 3:
        if (perchar != 0) tmpnewchar = tmpnewchar + '仟'
        break
      case 4:
        tmpnewchar = tmpnewchar + '万'
        break
      case 5:
        if (perchar != 0) tmpnewchar = tmpnewchar + '拾'
        break
      case 6:
        if (perchar != 0) tmpnewchar = tmpnewchar + '佰'
        break
      case 7:
        if (perchar != 0) tmpnewchar = tmpnewchar + '仟'
        break
      case 8:
        tmpnewchar = tmpnewchar + '亿'
        break
      case 9:
        tmpnewchar = tmpnewchar + '拾'
        break
    }
    newchar = tmpnewchar + newchar
  }
  //小数点之后进行转化
  if (Num.indexOf('.') != -1) {
    if (part[1].length > 2) {
      // alert("小数点之后只能保留两位,系统将自动截断");
      part[1] = part[1].substr(0, 2)
    }
    for (i = 0; i < part[1].length; i++) {
      tmpnewchar = ''
      perchar = part[1].charAt(i)
      switch (perchar) {
        case '0':
          tmpnewchar = '零' + tmpnewchar
          break
        case '1':
          tmpnewchar = '壹' + tmpnewchar
          break
        case '2':
          tmpnewchar = '贰' + tmpnewchar
          break
        case '3':
          tmpnewchar = '叁' + tmpnewchar
          break
        case '4':
          tmpnewchar = '肆' + tmpnewchar
          break
        case '5':
          tmpnewchar = '伍' + tmpnewchar
          break
        case '6':
          tmpnewchar = '陆' + tmpnewchar
          break
        case '7':
          tmpnewchar = '柒' + tmpnewchar
          break
        case '8':
          tmpnewchar = '捌' + tmpnewchar
          break
        case '9':
          tmpnewchar = '玖' + tmpnewchar
          break
      }
      if (i == 0) tmpnewchar = tmpnewchar + '角'
      if (i == 1) tmpnewchar = tmpnewchar + '分'
      newchar = newchar + tmpnewchar
    }
  }
  //替换所有无用汉字
  while (newchar.search('零零') != -1) newchar = newchar.replace('零零', '零')
  newchar = newchar.replace('零亿', '亿')
  newchar = newchar.replace('亿万', '亿')
  newchar = newchar.replace('零万', '万')
  newchar = newchar.replace('零元', '元')
  newchar = newchar.replace('零角', '')
  newchar = newchar.replace('零分', '')
  if (newchar.charAt(newchar.length - 1) == '元') {
    newchar = newchar + '整'
  }
  return newchar
}

export function englishMoney(num) {
  const arr1 = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty'
  ]
  const arr2 = [
    '',
    'ten',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety'
  ]
  const billion = 1000000000
  const million = 1000000
  const thousand = 1000
  const hundred = 100
  const max = 999999999999.99

  // 小于100处理
  const minHundred = (n) => {
    if (n <= 20) {
      return arr1[n]
    } else {
      const s1 = arr2[Math.floor(n / 10)]
      let s2 = arr1[n % 10]
      return s2 ? `${s1} ${s2}` : s1
    }
  }
  const minThousand = (n) => {
    const a = Math.floor(n / hundred)
    const b = n % hundred
    const arr = []
    if (a > 0) {
      arr.push(`${arr1[a]} hundred`)
    }
    if (b > 0) {
      arr.push(`${minHundred(b)}`)
    }
    return arr.join(' and ')
  }
  const minMillion = (n) => {
    const a = Math.floor(n / thousand)
    const b = n % thousand
    const arr = []
    if (a > 0) {
      arr.push(`${minThousand(a)} thousand`)
    }
    if (b > 0) {
      arr.push(`${minThousand(b)}`)
    }
    return arr.join(',')
  }
  const minBillion = (n) => {
    const a = Math.floor(n / million)
    const b = n % million
    const arr = []
    if (a > 0) {
      arr.push(`${minThousand(a)} million`)
    }
    if (b > 0) {
      arr.push(`${minMillion(b)}`)
    }
    return arr.join(',')
  }
  const f1 = (n) => {
    if (n < hundred) {
      return minHundred(n)
    } else if (n < thousand) {
      return minThousand(n)
    } else if (n < million) {
      return minMillion(n)
    } else if (n < billion) {
      return minBillion(n)
    } else if (n < max) {
      const a = Math.floor(n / billion)
      const b = n % billion
      const arr = []
      if (a > 0) {
        arr.push(`${minThousand(a)} billion`)
      }
      if (b > 0) {
        arr.push(`${minBillion(b)}`)
      }
      return arr.join(',')
    }
  }

  if (num > max) {
    return ''
  } else {
    num = Math.round(num * 100) / 100
    const arr = num.toString().split('.')
    const i = parseInt(arr[0])
    let p = 0
    if (arr.length > 1) {
      p = arr[1].length === 1 ? parseInt(arr[1]) * 10 : parseInt(arr[1])
    }
    const ret = p > 0 ? `${f1(i)} and CENTS ${minHundred(p)}` : `${f1(i)}`
    return ret.toUpperCase() + ' ONLY'
  }
}

/*
 * 参数说明：
 * number：要格式化的数字
 * decimals：保留几位小数
 * dec_point：小数点符号
 * thousands_sep：千分位符号
 * */
export function numberFormat(number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec)
      return '' + Math.ceil(n * k) / k
    }

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export function downloadFile(content, type, fileName) {
  const blob = new Blob([content], { type: type })
  saveAs(blob, fileName)
}

export function download(url, method = 'post', data = {}, fileName) {
  axios({
    method,
    url,
    data,
    responseType: 'blob'
  }).then((res) => {
    console.log(res.headers)
    let type = ''
    if (res.headers['content-type']) {
      type = res.headers['content-type']
    }
    saveAs(new Blob([res.data], { type }), fileName)
  })
}

// export function downloadAsDocx(content, fileName) {
//   ;(async () => {
//     const fileBuffer = await HTMLtoDOCX(content, null, {
//       table: { row: { cantSplit: true } },
//       footer: true,
//       pageNumber: true
//     })

//     saveAs(new Blob([fileBuffer]), fileName)
//   })()
// }
export function filterUnit(unit) {
  let u = '¥'
  switch (unit) {
    case '01':
      u = '¥'
      break
    case '02':
      u = '$'
      break
    case '03':
      u = '€'
      break
  }
  return u
}
export function filterUnitCountry(unit) {
  let u = 'zh'
  switch (unit) {
    case '01':
      u = 'zh'
      break
    case '02':
      u = 'en'
      break
    case '03':
      u = 'de'
      break
  }
  return u
}

export function isString(v) {
  return Object.prototype.toString.call(v) === '[object String]'
}

export function isObject(v) {
  return Object.prototype.toString.call(v) === '[object Object]'
}

export function getContractHtml(body) {
  const html = `
      <!DOCTYPE html>
      <html lang="">
      <head>
        <meta charset="utf-8" />
        <style>
        * {
          box-sizing: border-box;
          color: #333333;
          word-break: break-word;
        }
        body {
          margin: 0;
          padding: 0;
        }
        .page-A4 {
          width: 210mm;
          background-color: #ffffff;
          padding: 0mm 10mm;
        }
        .module {
          position: relative;
        }
        .module .content {
          padding-top: 10px;
          color: #333333;
          font-weight: 400;
          line-height: 2em;
          word-break: break-word;
        }
        .module .content .title {
          font-size: 1.5em;
          font-weight: 500;
          line-height: 2.5em;
          text-align: center;
          margin-bottom: 10px;
        }
        .module .content .sub-title {
          font-size: 1.2em;
          line-height: 2em;
          font-weight: 500;
          margin: 10px 0px;
        }
        .module .content .text-underline {
          text-decoration: underline;
        }
        .module .content .sign-area {
          display: flex;
          margin-top: 50px;
        }
        .module .content .sign-area .item {
          flex: 1;
        }
        .op {
          display: none;
        }
        .esign {
          color: rgba(255, 255, 255, 0);
        }
        .attach-title {
          font-size: 1.5em;
          line-height: 2em;
          font-weight: 500;
          margin: 10px 0px;
          text-align: center;
        }
        .rich-content table {
          border-collapse: collapse;
        }
        .rich-content th, td {
          border: 1px solid #ccc;
          min-width: 50px;
          height: 30px;
        }
        .rich-content th {
          background-color: #f1f1f1;
        }
        </style>
      </head>
      <body>
        ${body}
      </body>
      </html>
      `
  return html
}

export function formatDate(date, pattern) {
  return dayjs(date).format(pattern || 'YYYY-MM-DD')
}

export function stageName(name, lang = 'zh') {
  let filtName = '--'
  // prePay 预付款 finalPay 尾款 pay_step_1 阶段1
  if (name == '') {
    filtName = '--'
  } else if (name == 'prePay') {
    filtName = lang == 'zh' ? '预付款' : 'Advance payment'
  } else if (name == 'finalPay') {
    filtName = lang == 'zh' ? '尾款' : 'Balance payment'
  } else {
    let str = name.split('_')
    filtName = (lang == 'zh' ? '阶段' : 'Phase') + str[str.length - 1]
  }
  return filtName
}

export function mapFolderName(name, $t) {
  let finalName = name
  if (!finalName) {
    finalName = '--'
  } else {
    if (name == 'prePay') {
      finalName = $t('inlineDisk.folderNameMap.prePay')
    } else if (name == 'finalPay') {
      finalName = $t('inlineDisk.folderNameMap.finalPay')
    } else if (name.indexOf('pay_step_') === 0) {
      const str = name.substr('pay_step_'.length)
      finalName = $t('inlineDisk.folderNameMap.payStep') + str
    } else if (name == '#$proj_mate$#') {
      finalName = $t('order.orderDetail.file.projectInfo')
    } else if (name == '#$deli_mate$#') {
      finalName = $t('order.orderDetail.file.deliveryDoc')
    }
  }
  return finalName
}

export function fileImg(imgId, type) {
  let reg = /(http|https):\/\/([\w.]+\/?)\S*/
  if (!imgId) {
    return type
      ? require('@/assets/images/default-icon.png')
      : require('@/assets/images/default-avatar.png')
  } else if (reg.test(imgId)) {
    return imgId
  } else {
    let img = `${location.origin}/api/crd/disk/file/loadFile/${imgId}`
    return img
  }
}
export function getFileIcon(extension) {
  let icon = ''
  let color = '#92999f'
  switch (extension) {
    case 'pdf':
      icon = 'icon-pdf'
      color = '#FB4236'
      break
    case 'doc':
    case 'docx':
      icon = 'icon-doc'
      color = '#1A7FFF'
      break
    case 'xls':
    case 'xlsx':
      icon = 'icon-excl'
      color = '#2AC33D'
      break
    case 'jpg':
    case 'jpeg':
    case 'png':
      icon = 'icon-tupian1'
      color = '#ecb720'
      break
    default:
      icon = 'icon-weizhiwenjian'
  }
  return { icon, color }
}
// 过滤英文名称
export function fullName(obj) {
  const { firstName, lastName } = obj
  if (!firstName || !lastName) {
    return ''
  }
  let reg = new RegExp(/^[a-zA-Z]{1}\w*$/)
  let first = reg.test(firstName)
  let last = reg.test(lastName)
  let space = first && last ? ' ' : ''
  return firstName.trim() + space + lastName.trim()
}

// 异步的方法
export function syncHandle(func) {
  let timer = setTimeout(async () => {
    await func()
    clearTimeout(timer)
  }, 0)
}
// 模块解析
export function parseModules(inputModules) {
  inputModules.forEach((item) => {
    item.data = isString(item.data) ? JSON.parse(item.data) : item.data || {}
    item.paramConfig =
      item.paramConfig && isString(item.paramConfig)
        ? JSON.parse(item.paramConfig)
        : item.paramConfig || {}
    item.name = item.name
      ? isString(item.name)
        ? item.name
        : JSON.stringify(item.name)
      : ''
    if (item.children && item.children.length > 0) {
      item.showChild = true
      parseModules(item.children)
    }
    item.pids = item.pids || []
  })
}

export function treeModuleToArray(input) {
  const fn = (input, ret) => {
    input.forEach((item) => {
      ret.push(item)
      if (item.children && item.children.length > 0) {
        fn(item.children, ret)
      }
    })
  }
  const modules = []
  fn(input, modules)
  return modules
}

export function formatTime(time, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(time).format(pattern)
}
// 获取参数
export function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}
