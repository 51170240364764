const Talent = [
  {
    path: '/talent',
    name: 'Talent',
    component: () => import('@/views/talent/indexView')
  },
  // 团队评论列表
  {
    path: '/team-comment',
    name: 'TeamComment',
    component: () => import('@/views/deal/evaluate/teamComment')
  }
]
export default Talent
