<template>
  <div class="im-editor">
    <div class="im-editor-tool">
      <div class="im-tool-item">
        <el-popover
          placement="top"
          width="420"
          trigger="click"
          popper-class="im-tool-popover"
        >
          <div class="im-emoji im-scrollbar">
            <div class="im-emoji-face">
              <img
                v-for="item in emojiData"
                :key="item.code"
                :src="`${emojiDir}${item.code}.png`"
                :alt="item.code"
                @click="handleSelectEmoji(item)"
              />
            </div>
          </div>
          <svg-icon slot="reference" icon-class="im-face" />
        </el-popover>
      </div>
      <div class="im-tool-item">
        <svg-icon icon-class="im-image" @click="selectFile" />
        <input
          style="display: none; opacity: 0; width: 0; height: 0"
          type="file"
          multiple="multiple"
          ref="fileInput"
          accept="image/*"
          @change="onChangeFile"
        />
      </div>
    </div>
    <div class="im-editor-inner im-scrollbar">
      <div
        class="im-editor-input"
        ref="textarea"
        contenteditable="true"
        spellcheck="false"
        @keyup="handleKeyup"
        @keydown="handleKeydown"
        @paste="handlePaste"
        @click="handleClick"
      ></div>
    </div>
    <div class="im-editor-footer">
      <div class="im-editor-tips">{{ $t('im.tips') }}</div>
      <el-button
        class="im-send-btn"
        size="mini"
        type="primary"
        @click="handleSend"
        :disabled="submitDisabled"
      >
        {{ $t('im.send') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  clearHtmlExcludeImg,
  emojiImageToName,
  formatTextMsg
} from '@/components/AirIm/utils/index.js'
import { emojiDir } from '@/config'
import emojiData from './data/emoji.js'
const selection = window.getSelection()
const command = (command, val) => {
  document.execCommand(command, false, val)
}
let range
export default {
  name: 'AirEditor',
  data() {
    return {
      emojiData,
      emojiDir,
      submitDisabled: true
    }
  },
  mounted() {},
  methods: {
    resolvePath(name) {
      // return require(getPath(name))
      return name
    },
    handlePaste(e) {
      e.preventDefault()
      const clipboardData = e.clipboardData || window.clipboardData
      const text = clipboardData.getData('Text')
      if (text) {
        if (window.clipboardData) {
          this.$refs.textarea.innerHTML = text
        } else {
          command('insertText', text)
        }
      }
    },
    handleKeydown(e) {
      // shift & enter 发送消息
      if (
        this.submitDisabled === false &&
        e.keyCode === 13 &&
        e.ctrlKey === true
      ) {
        this.handleSend()
      }
    },
    handleKeyup() {
      this.saveRange()
      this.checkSubmitDisabled()
    },
    handleClick() {
      this.saveRange()
    },
    saveRange() {
      range = selection.getRangeAt(0)
    },
    focusRange() {
      this.$refs.textarea.focus()
      if (range) {
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
    // 选择表情
    handleSelectEmoji(item) {
      console.log('face', item)
      // const path = this.resolvePath(item.name)
      this.inertContent(
        `<img data-code="${item.code}" src="${emojiDir}${item.code}.png" alt="${item.text}" />`
      )
      this.checkSubmitDisabled()
    },
    // 上传图片
    async selectFile() {
      await this.$nextTick()
      this.$refs.fileInput.click()
    },
    onChangeFile() {
      const { fileInput } = this.$refs
      console.log('1111111')
      Array.from(fileInput.files).forEach((file) => {
        this.$emit('upload', file)
      })
      fileInput.value = ''
    },
    handleSend() {
      const text = this.getFormatValue()
      this.$emit('send', text)
      this.clear()
      this.checkSubmitDisabled()
    },
    getFormatValue() {
      // return emojiImageToName(this.$refs.textarea.innerHTML).replace(
      //   /<.*?>/g,
      //   ''
      // ).replace(/<(?!img).*?>/g, '')
      const str = emojiImageToName(this.$refs.textarea.innerHTML)
      // return str.replace(/<(?!br).*?>/g, '').replace(/<br [^>]*>/gi, '/n')
      return formatTextMsg(str)
    },
    clear() {
      this.$refs.textarea.innerHTML = ''
    },
    inertContent(val) {
      this.focusRange()
      command('insertHTML', val)
      this.saveRange()
    },
    checkSubmitDisabled() {
      this.submitDisabled = !clearHtmlExcludeImg(
        this.$refs.textarea.innerHTML.trim()
      )
    }
  }
}
</script>
<style lang="scss">
.im-tool-popover {
  padding: 4px !important;
  z-index: 5005 !important;
}
.im-editor-input {
  img {
    height: 20px;
    padding: 0 2px;
    pointer-events: none;
    position: relative;
    vertical-align: middle;
    border-style: none;
  }
}
</style>
<style lang="scss" scoped>
.im-emoji {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px;
  img {
    width: 30px;
    height: 30px;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
    vertical-align: middle;
    border-style: none;
    &:hover {
      background: #f5f5f5;
    }
  }
}
.im-editor {
  display: flex;
  flex-direction: column;
  height: 200px;
}
.im-editor-tool {
  display: flex;
  padding: 0 8px;
  .im-tool-item {
    padding: 8px 10px;
    font-size: 20px;
    color: #666;
    cursor: pointer;
    &:hover {
      color: #333;
    }
  }
}
.im-editor-footer {
  display: flex;
  padding: 8px 16px;
  .im-editor-tips {
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
    color: #999;
    font-size: 12px;
  }
}
.im-send-btn {
  margin-left: 16px;
}
.im-editor-inner {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  .im-editor-input {
    height: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
  }
}
</style>
