<template>
  <div class="main-wrap" :loading="isLoading">
    <header-common v-if="!isDialog" />
    <div class="main" :class="{ white: isDialog }">
      <div class="content" :class="{ 'no-margin': isDialog }">
        <img class="img-logo" src="@/assets/images/logo@2x.png" alt="" />
        <div class="title">{{ $t('login.forget.changeMsgTitle') }}</div>
        <el-form :model="form" ref="form" class="form">
          <template v-if="isDialog">
            <el-form-item
              :label="$t('login.forget.oldPassword')"
              prop="oldPwd"
              :rules="rules"
            >
              <el-input
                :type="oldPwdType"
                :placeholder="$t('login.forget.oldPasswordTip')"
                v-model="form.oldPwd"
                name="password"
                auto-complete="on"
              >
                <span slot="prefix" class="icon">
                  <el-image
                    src="https://res.airrnd.com/disk/load/ea713399cbdf2d8495ca3efffe030b10.png"
                    alt=""
                    style="width: 16px; height: 16px"
                  ></el-image>
                </span>
                <span slot="suffix" class="icon tip" @click="showOldPwd">
                  <el-image
                    :src="
                      oldPwdType
                        ? 'https://res.airrnd.com/disk/load/b492806d893ed0f0a6d571b075de8c5e.png'
                        : 'https://res.airrnd.com/disk/load/dfe400e81668aa10d8e021a6e3b8e9ba.png'
                    "
                    alt=""
                    style="width: 16px; height: 16px"
                  ></el-image>
                </span>
              </el-input>
            </el-form-item>
          </template>
          <el-form-item
            :label="$t('login.forget.newMsg')"
            prop="passOne"
            :rules="rules"
          >
            <el-input
              :type="pswOneType"
              :placeholder="$t('login.forget.newPasswordTip')"
              v-model="form.passOne"
              name="password"
              auto-complete="on"
            >
              <span slot="prefix" class="icon">
                <el-image
                  src="https://res.airrnd.com/disk/load/ea713399cbdf2d8495ca3efffe030b10.png"
                  alt=""
                  style="width: 16px; height: 16px"
                ></el-image>
              </span>
              <span slot="suffix" class="icon tip" @click="showOnePwd">
                <el-image
                  :src="
                    pswOneType
                      ? 'https://res.airrnd.com/disk/load/b492806d893ed0f0a6d571b075de8c5e.png'
                      : 'https://res.airrnd.com/disk/load/dfe400e81668aa10d8e021a6e3b8e9ba.png'
                  "
                  alt=""
                  style="width: 16px; height: 16px"
                ></el-image>
              </span>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('login.forget.restNewMsg')"
            prop="passTwo"
            :rules="rules"
          >
            <el-input
              :type="pswType"
              v-model="form.passTwo"
              :placeholder="$t('login.forget.restPasswordTip')"
              name="password"
              auto-complete="on"
            >
              <span slot="prefix" class="icon">
                <el-image
                  src="https://res.airrnd.com/disk/load/ea713399cbdf2d8495ca3efffe030b10.png"
                  alt=""
                  style="width: 16px; height: 16px"
                ></el-image>
              </span>
              <span slot="suffix" class="icon tip" @click="showPwd">
                <el-image
                  :src="
                    pswType
                      ? 'https://res.airrnd.com/disk/load/b492806d893ed0f0a6d571b075de8c5e.png'
                      : 'https://res.airrnd.com/disk/load/dfe400e81668aa10d8e021a6e3b8e9ba.png'
                  "
                  alt=""
                  style="width: 16px; height: 16px"
                ></el-image>
              </span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="btn"
              :disabled="disabled"
              :loading="isLoading"
              @click="submitForm('form')"
              >{{ $t('btns.revise') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderCommon from '@/components/HeaderCommon.vue'
import { isvalidPassWord } from '@/utils/validate.js'
import { mapState } from 'vuex'
import { update, updatePassword } from '@/api/login.js'
export default {
  components: {
    HeaderCommon
  },
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      obj: {},
      isLoading: false,
      oldPwdType: 'password',
      pswOneType: 'password',
      pswType: 'password',
      form: {
        passOne: '',
        passTwo: '',
        oldPwd: ''
      },
      email: '',
      code: ''
    }
  },
  computed: {
    loginView() {
      return this.$t('login.loginView')
    },
    ...mapState('global', ['lang']),
    disabled() {
      const { passOne, passTwo, oldPwd } = this.form
      let isTrue = passOne.length > 0 && passTwo.length > 0 ? true : false
      if (this.isDialog) {
        isTrue = isTrue && oldPwd.length > 0 ? true : false
      }
      return !isTrue
    },
    rules() {
      let validate_types = (rule, value, callback) => {
        if (!isvalidPassWord(value)) {
          callback(new Error(this.$t('login.pass')))
        } else {
          callback()
        }
      }
      return [
        {
          required: true,
          message: this.$t('login.pass'),
          trigger: 'blur'
        },
        {
          validator: validate_types,
          trigger: 'change'
        }
      ]
    }
  },
  watch: {
    lang() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    }
  },
  mounted() {
    // this.email = this.$route.query.email ? this.$route.query.email : ''
    this.code = this.$route.query.code ? this.$route.query.code : ''
  },
  methods: {
    submitForm() {
      const { passOne, passTwo, oldPwd } = this.form
      if (passTwo != passOne) {
        this.$message.warning(this.$t('login.forget.passErrTip'))
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let obj = {
            code: this.code,
            password: passOne
          }
          let api = update
          if (this.isDialog) {
            obj.oldPwd = oldPwd
            delete obj.code
            api = updatePassword
          }
          this.isLoading = true
          api(obj)
            .then((res) => {
              this.isLoading = false
              if (res && res.errorCode === 0) {
                this.$message.success(res.errorMessage)
                this.$router.replace({ path: '/login' })
                this.$store.dispatch('user/LogOut')
              }
            })
            .catch(() => {
              this.isLoading = false
            })
        }
      })
    },
    resetForm() {
      this.$refs.form.resetFields()
    },
    showPwd() {
      if (this.pswType === 'password') {
        this.pswType = ''
      } else {
        this.pswType = 'password'
      }
    },
    showOnePwd() {
      if (this.pswOneType === 'password') {
        this.pswOneType = ''
      } else {
        this.pswOneType = 'password'
      }
    },
    showOldPwd() {
      if (this.oldPwdType === 'password') {
        this.oldPwdType = ''
      } else {
        this.oldPwdType = 'password'
      }
    },
    toRegister() {
      this.$router.push({ path: '/register' })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/login.scss';
.img-logo {
  width: 170px;
  height: 40px;
  margin-bottom: 16px;
}
.m_bottom16 {
  margin-bottom: 16px;
}
.m_bottom32 {
  margin-bottom: 32px;
}
.main-wrap {
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
    width: 100%;
    background-color: #f7f8fa;
    &.white {
      background-color: #fff;
    }
    .no-margin {
      margin: 0 auto;
      padding-top: 24px;
    }
  }
}
</style>
