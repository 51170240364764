export function getItem(key) {
  let newValue = localStorage.getItem(key) ? localStorage.getItem(key) : ''
  if (!newValue) {
    return JSON.parse(localStorage.getItem(key))
  } else {
    return ''
  }
}

export function setItem(key, value) {
  return localStorage.setItem(key, JSON.stringify(value))
}

export function removeItem(key) {
  return localStorage.removeItem(key)
}
