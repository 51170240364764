export default {
  team: {
    identity: {
      abroadAuth: {
        pleaseSelect: 'Please select',
        pleaseEnter: 'Please enter',
        yourRole: 'Your role',
        yourRoleOption: ['Non legal representative', 'Legal representative'],
        // --------------
        //         *
        perInfo: 'Personal information',
        fullName: 'Full name',
        // fullNamePla: 'Please enter your full name',
        docType: 'ID document type',
        cerNo: 'ID document number',
        teamName: 'Studio name',
        // cerNoPla: 'Please enter your ID document number',
        // --------
        entInfo: 'Enterprise information',
        entName: 'Enterprise name',
        entRegTime: 'Register date',
        regNum: 'Register number',
        regNumOption: [
          'Register document number'
          // 'Tax registration certificate number' // 之前有两个可选项
        ],
        regDocNo: 'Register document number',
        taxRegNum: 'Tax registration certificate number',
        entPerSize: 'Enterprise size',
        conNum: 'Telephone',
        email: 'Email',
        letOfCom: 'Commitment letter',
        uplLetOfCom: 'Upload Commitment letter',
        dowLetOfCom: 'Download Commitment letter',
        entPowOfAtt: 'Enterprise authorization letter',
        uplAuth: 'Upload enterprise authorization letter',
        dowAuth: 'Download enterprise authorization letter',
        // -------------
        otherInfo: 'Other information (optional)',
        mainBusScope: 'Industry',
        mainBusScopeOption: [
          {
            id: 1,
            label: 'Airlines/Aviation'
          }
        ],
        mainBusScopeOption1: [
          'Airlines/Aviation',
          'Animation',
          'Architecture & Planning',
          'Automotive',
          'Aviation & Aerospace',
          'Biotechnology',
          'Civil Engineering',
          'Computer & Network Security',
          'Computer Games',
          'Computer Hardware',
          'Computer Networking',
          'Computer Software',
          'Consumer Electronics',
          'Education Management',
          'E-Learning',
          'Electrical/Electronic Manufacturing',
          'Environmental Services',
          'Graphic Design',
          'Hospital & Health Care',
          'Industrial Automation',
          'Industrial Design',
          'Information Services',
          'Information Technology and Services',
          'Internet',
          'Machinery',
          'Management Consulting',
          'Maritime',
          'Market Research',
          'Marketing and Advertising',
          'Mechanical or Industrial Engineering',
          'Medical Devices',
          'Mobile Games',
          'Nanotechnology',
          'Oil & Energy',
          'Outsourcing/Offshoring',
          'Pharmaceuticals',
          'Plastics',
          'Professional Training & Coaching',
          'Program Development',
          'Railroad Manufacture',
          'Renewables & Environment',
          'Research',
          'Semiconductors',
          'Shipbuilding',
          'Telecommunications',
          'Translation and Localization',
          'Transportation/Trucking/Railroad',
          'Utilities',
          'Wireless'
        ],
        brandName: 'Brand name',
        entOffWeb: 'Official website',
        twitter: 'Twitter',
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        companyLogo: 'Company Logo',
        uploadAvatar: 'Upload head photo',
        determine: 'Confirm',
        reset: 'Reset',
        personal: 'Number of people',
        people: 'Person(s)',
        upd: 'Upload',
        puyra: 'Please upload your photo',
        updAva: 'Upload photo',
        result: {
          name: 'Name',
          docType: 'ID document type',
          cerNo: 'ID document number',
          studio: 'Studio name',
          company: 'Company name',
          regTime: 'Company registration date',
          regDocNo: 'Registration document number',

          entInfo: 'Company information',
          btnText: 'Verify again',
          failure: 'Check for verification failure reasons'
        }
      },
      cardType: [
        {
          id: '1',
          label: 'SSN',
          type: 'SSN'
        },
        {
          id: '2',
          label: 'ID Card',
          type: 'ID Card'
        },
        {
          id: '3',
          label: 'Driving License',
          type: 'Driving License'
        },
        {
          id: '4',
          label: 'Passport',
          type: 'Passport'
        }
      ]
    }
  }
}
