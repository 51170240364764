<template>
  <el-dialog
    v-dnd="{ drag: '.im-container-tittle', dom: '.el-dialog' }"
    ref="dialogRef"
    custom-class="im-dialog"
    append-to-body
    modal-append-to-body
    :visible.sync="dialogVisible"
    :modal="false"
    @opened="onOpened"
    width="668px"
  >
    <air-im ref="im" @close="handleClose" />
  </el-dialog>
</template>

<script>
import AirIm from '@/components/AirIm/index.vue'
import mixin from '@/components/AirIm/mixin.js'
import Dnd from '@/directive/dnd'
import { mapMutations } from 'vuex'
export default {
  name: 'AirDialog',
  mixins: [mixin],
  components: {
    AirIm
  },
  directives: {
    Dnd
  },
  props: {
    // show: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  watch: {
    imDialogShow(n) {
      this.dialogVisible = n
    },
    dialogVisible(n) {
      this.toggleDialog(n)
    }
  },
  methods: {
    ...mapMutations('im', {
      toggleDialog: 'TOGGLE_DIALOG'
    }),
    handleClose() {
      this.dialogVisible = false
    },
    onOpened() {
      /* eslint-disable */
      // const el = this.$refs.dialogRef.$el
      // const dialogHeaderEl = el.querySelector('.im-drag')
      // const dragDom = el.querySelector('.el-dialog')
      // dialogHeaderEl.style.cssText += ';cursor:move;'
      // // dragDom.style.cssText += ';top:0px;'
      // const getStyle = (function () {
      //   if (window.document.currentStyle) {
      //     return (dom, attr) => dom.currentStyle[attr]
      //   } else {
      //     return (dom, attr) => getComputedStyle(dom, false)[attr]
      //   }
      // })()
      // dialogHeaderEl.onmousedown = (e) => {
      //   e.stopPropagation()
      //   // 鼠标按下，计算当前元素距离可视区的距离
      //   const disX = e.clientX - dialogHeaderEl.offsetLeft + 178
      //   const disY = e.clientY - dialogHeaderEl.offsetTop

      //   const dragDomWidth = dragDom.offsetWidth
      //   const dragDomHeight = dragDom.offsetHeight

      //   const screenWidth = document.body.clientWidth
      //   const screenHeight = document.body.clientHeight

      //   const minDragDomLeft = dragDom.offsetLeft
      //   const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

      //   const minDragDomTop = dragDom.offsetTop
      //   const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight

      //   // 获取到的值带px 正则匹配替换
      //   let styL = getStyle(dragDom, 'left')
      //   let styT = getStyle(dragDom, 'top')

      //   if (styL.includes('%')) {
      //     styL = +document.body.clientWidth * (+styL.replace(/\%/g, '') / 100)
      //     styT = +document.body.clientHeight * (+styT.replace(/\%/g, '') / 100)
      //   } else {
      //     styL = +styL.replace(/\px/g, '')
      //     styT = +styT.replace(/\px/g, '')
      //   }

      //   document.onmousemove = function (e) {
      //     // 通过事件委托，计算移动的距离
      //     let left = e.clientX - disX
      //     let top = e.clientY - disY

      //     // 边界处理
      //     if (-left > minDragDomLeft) {
      //       left = -minDragDomLeft
      //     } else if (left > maxDragDomLeft) {
      //       left = maxDragDomLeft
      //     }

      //     if (-top > minDragDomTop) {
      //       top = -minDragDomTop
      //     } else if (top > maxDragDomTop) {
      //       top = maxDragDomTop
      //     }

      //     // 移动当前元素
      //     dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`
      //   }

      //   document.onmouseup = function (e) {
      //     document.onmousemove = null
      //     document.onmouseup = null
      //   }
      //   document.ondragstart = function (e) {
      //     e.preventDefault()
      //   }
      //   document.ondragend = function (e) {
      //     e.preventDefault()
      //   }
      // }
      this.$refs['im'] && this.$refs['im'].scrollToBottom()
    }
  }
}
</script>
<style lang="scss">
.im-dialog {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped></style>
