import request from '@/utils/request'
import download from '@/utils/requestDownload'

// 加载合同模板列表
export function queryTpl(data) {
  return request({
    url: '/crd/contract/queryTpl',
    method: 'post',
    data
  })
}

// 预览合同模板
export function previewTpl(data) {
  return request({
    url: '/crd/contract/previewTpl',
    method: 'post',
    data
  })
}

// 加载新增合同模板
export function loadContractForCreate(data) {
  return request({
    url: '/crd/contract/loadContractForCreate',
    method: 'post',
    data
  })
}

// 加载修改合同模板
export function loadContractForEdit(data) {
  return request({
    url: '/crd/contract/loadContractForEdit',
    method: 'post',
    data
  })
}
// 新增合同
export function createContract(data) {
  return request({
    url: '/crd/contract/createContract',
    method: 'post',
    data
  })
}
// 修改合同
export function updateContract(data) {
  return request({
    url: '/crd/contract/updateContract',
    method: 'post',
    data
  })
}

// 合同确认(走电子合同流程)
export function confirmContract(data) {
  return request({
    url: '/crd/contract/confirmContract',
    method: 'post',
    data
  })
}

// 合同确认(不走电子合同流程)
export function confirmContractWithNoESign(data) {
  return request({
    url: '/crd/contract/confirmContractWithNoESign',
    method: 'post',
    data
  })
}

// 下载PDF
export function downloadPdf(data) {
  return download({
    url: '/crd/contract/previewPdf',
    method: 'post',
    data
  })
}

// 查询合同模块
export function queryContractModules(data) {
  return request({
    url: '/crd/contract/queryContractModules',
    method: 'post',
    data
  })
}

// 查询合同默认参数
export function queryContractDefaultParams(data) {
  return request({
    url: '/crd/contract/queryContractDefaultParams',
    method: 'post',
    data
  })
}
