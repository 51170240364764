import Layout2 from '../../views/layout/indexView2'
const Link = [
  {
    path: '/support',
    name: 'Support',
    component: Layout2,
    meta: {
      isLink: false,
      title: 'linkMenu.help'
    },
    type: 'link',
    children: [
      {
        path: 'help',
        component: () => import('@/views/link/helpView.vue'),
        name: 'help',
        meta: { isLink: true, title: 'linkMenu.help' },
        type: 'link'
      }
    ]
  },
  // {
  //   path: '/company',
  //   name: 'Company',
  //   component: Layout2,
  //   meta: {
  //     isLink: false,
  //     title: 'linkMenu.firm'
  //   },
  //   type: 'link',
  //   children: [
  //     {
  //       path: 'culture',
  //       component: () => import('@/views/link/cultureView.vue'),
  //       name: 'culture',
  //       meta: { isLink: true, title: 'linkMenu.firm' },
  //       type: 'link'
  //     }
  //   ]
  // },
  {
    path: '/link',
    name: 'Link',
    component: Layout2,
    meta: {
      isLink: false,
      title: 'linkMenu.conditions'
    },
    type: 'link',
    children: [
      {
        path: 'service',
        component: () => import('@/views/link/termsView.vue'),
        name: 'service',
        meta: { isLink: true, title: 'linkMenu.service' },
        type: 'link'
      },
      {
        path: 'privacy',
        component: () => import('@/views/link/privacyView.vue'),
        name: 'privacy',
        meta: { isLink: true, title: 'linkMenu.privacy' },
        type: 'link'
      },
      // {
      //   path: 'user',
      //   component: () => import('@/views/link/userView.vue'),
      //   name: 'user',
      //   meta: { isLink: true, title: 'linkMenu.user' },
      //   type: 'link'
      // },
      {
        path: 'tort',
        name: 'Tort',
        component: () => import('@/views/link/tortView.vue'),
        meta: { isLink: true, title: 'linkMenu.tort' },
        type: 'link'
      },
      {
        path: 'law',
        name: 'Law',
        component: () => import('@/views/link/lawView.vue'),
        meta: { isLink: true, title: 'linkMenu.law' },
        type: 'link'
      },
      {
        path: 'userEntry',
        name: 'userEntry',
        component: () => import('@/views/link/userEntryView'),
        meta: { isLink: true, title: 'linkMenu.userEntry' },
        type: 'link'
      },
      {
        path: 'cancellation',
        name: 'cancellation',
        component: () => import('@/views/link/cancellationView'),
        meta: { isLink: true, title: 'linkMenu.cancellation' },
        type: 'link'
      },
      {
        path: 'credit',
        name: 'credit',
        component: () => import('@/views/link/creditView'),
        meta: { isLink: true, title: 'linkMenu.credit', hiddenOnEn: true },
        type: 'link'
      },
      {
        path: 'cookies',
        name: 'cookies',
        component: () => import('@/views/link/cookiesView'),
        meta: { isLink: true, title: 'linkMenu.cookies' },
        type: 'link'
      },
      {
        path: 'dispute',
        name: 'dispute',
        component: () => import('@/views/link/disputeView'),
        meta: { isLink: true, title: 'linkMenu.dispute', hiddenOnEn: true },
        type: 'link'
      },
      {
        path: 'deposit',
        name: 'deposit',
        component: () => import('@/views/link/depositView'),
        meta: { isLink: true, title: 'linkMenu.deposit', hiddenOnEn: true },
        type: 'link'
      }
    ]
  },
  {
    path: '/answer',
    name: 'Answer',
    component: Layout2,
    meta: {
      isLink: false,
      title: 'linkMenu.conditions'
    },
    type: 'link',
    children: [
      // {
      //   path: 'join',
      //   component: () => import('@/views/link/joinView.vue'),
      //   name: 'Join',
      //   meta: { isLink: true, title: 'linkMenu.join' }
      // }
      {
        path: 'questions',
        component: () => import('@/views/link/questionView.vue'),
        name: 'Questions',
        meta: { isLink: true, title: 'linkMenu.questions' },
        type: 'link'
      }
    ]
  },
  {
    path: '/authorization',
    name: 'Authorization',
    component: () => import('@/views/link/authorizationView.vue')
  },
  // 会员
  {
    path: '/members',
    name: 'Members',
    component: () => import('@/views/link/membersView.vue')
  }
]
export default Link
