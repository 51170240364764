import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from './modules/login'
// import HomeView from '../views/HomeView.vue'
// import Layout from '../views/layout/indexView'
import asyncRouter from './workbench/index'
import Talent from './modules/talent'
import Terms from './modules/terms'
import Link from './modules/link'
import Recruit from './modules/recruit'
// import Why from '@/views/home/com/whyView'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/',
    name: 'view',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/HomeView')
  },
  ...Login,
  ...asyncRouter,
  ...Talent,
  ...Terms,
  ...Link,
  ...Recruit,
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/printContract',
    name: 'printContract',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/contract/print/index.vue'
      )
  },
  {
    path: '/protocol/:code',
    name: 'protocolPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/link/protocolPage'),
    props: true
  },
  {
    path: '/withdrawApplyPage',
    name: 'WithdrawApplyPage',
    component: () =>
      import('../views/setting/personal-setting/withdrawApplyPage.vue')
  }
]
export const asyncRoutes = [{ path: '*', redirect: '/404', hidden: true }]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes
})

export default router
