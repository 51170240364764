<template>
  <div class="tip-head">
    <div v-if="tipType == 'comp'" class="info">
      <span>
        <i class="el-icon-question"></i>
        {{ $t('headTips.noCompleteTip', [session.perfectSchedule || 0]) }}
      </span>
      <el-button type="text" @click="toCert(1)">
        {{ $t('btns.toComp') }}
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
    <div v-else class="info">
      <span><i class="el-icon-question"></i> {{ $t('headTips.certTip') }}</span>
      <el-button type="text" @click="toCert(2)">
        {{ $t('btns.toCert') }}
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
    <div class="line"></div>
  </div>
</template>
<script>
export default {
  props: {
    session: {
      type: Object,
      default: () => {}
    },
    tipType: {
      type: String,
      default: 'comp' // cert : 认证 comp:完善
    }
  },
  data() {
    return {}
  },
  methods: {
    toCert(v) {
      if (v == 1) {
        this.session.perfectSchedule == 0
          ? this.$router.push({ path: '/project' }).catch(() => {})
          : this.$router.push({ path: '/setting/my-home-page' })
      } else {
        this.$router.push({ path: '/team/team-info/cert' }).catch(() => {})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-head {
  .info {
    padding: 0 32px;
    height: 46px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      color: #ffbf00;
    }
    .el-button {
      color: #ffbf00;
    }
  }
  .line {
    height: 16px;
    background: #f7f8fa;
  }
}
</style>
