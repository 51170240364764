import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
// import global from './modules/global'

Vue.use(Vuex)
// const modules = require.context('./modules', true, /([A-Za-z0-9-_,\s]+)\.js$/i)
// console.log('modules', modules.keys())

function loadModules() {
  const modules = require.context('./modules', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const ret = {}
  modules.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      ret[locale] = modules(key)['default']
    }
  })
  return ret
}
// console.log('modules', loadModules())
// const modules = {
//   global: global,
// }
// console.log(loadModules(), modules)
export default new Vuex.Store({
  state: {},
  getters,
  mutations: {},
  actions: {},
  modules: loadModules()
})
