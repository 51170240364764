<template>
  <div v-if="!item.hidden">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <ItemView
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="filterTitle($t(onlyOneChild.meta.title), item)"
          />
          <el-badge
            v-if="item.path == 'letter' && count && count > 0"
            class="count"
            :value="count"
          />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template slot="title">
        <ItemView
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="filterTitle($t(item.meta.title), item)"
        />
      </template>
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import { isExternal } from '@/utils/validate'
import ItemView from './itemView'
import AppLink from './appLink'
const path = require('path-browserify')
import { mapGetters } from 'vuex'
export default {
  name: 'SidebarItem',
  components: { ItemView, AppLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  computed: {
    ...mapGetters(['session', 'count'])
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      let r = path.resolve(this.basePath, routePath)
      if (r == '/setting/questions') {
        r = '/answer/questions'
      }
      if (r == '/team/upgrade') {
        r = '/members'
      }
      return r
    },
    filterTitle(title, item) {
      let name = title
      const { session } = this
      if (
        item &&
        (item.name == 'TeamMember' ||
          item.name == 'TeamInfo' ||
          item.name == 'Team') &&
        session &&
        session.certType == '2'
      ) {
        return this.$t(item.meta.title2)
      }
      return name
    }
  }
}
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
.count {
  margin-bottom: 10px;
  margin-left: 8px;
}
</style>
