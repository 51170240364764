import { getImToken, queryAvatar } from '@/api/login'
import openIM, { messageTypes } from '@/components/AirIm/utils/openIM.js'
// import { socketUrl } from '@/config'
import { dd } from '@/components/AirIm/utils/index.js'

const im = {
  namespaced: true,
  state: {
    imToken: '',
    unread: 0,
    userID: '',
    conversationList: [], // 所有回话列表
    conversation: null, // 当前回话
    groupMembers: [], // 当前会话的群组成员
    hasMoreMember: false, //
    currentMsg: [], // 当前会话的消息
    startClientMsgID: '', // 当前会话的最后拉取消息的消息ID
    hasMoreMsg: false,
    show: false,
    isLogin: false,
    hasNew: false,
    avatarCache: [],
    mode: 'all' // all single group
  },
  mutations: {
    SET_UNREAD(state, count) {
      state.unread = count
      state.hasNew = count > 0
    },
    TOGGLE_DIALOG(state, payload) {
      state.show = payload
    },
    SET_CONVERSATION(state, payload) {
      state.conversation = payload
    },
    SET_CONVERSATION_List(state, payload) {
      state.conversationList = payload || []
    },
    SET_MODE(state, payload) {
      state.mode = payload
    },
    SET_TOKEN(state, payload) {
      state.imToken = payload
    },
    SET_GROUPMEMBERS(state, payload) {
      state.groupMembers = payload
    },
    UPDATE_AVATAR(state, payload = []) {
      state.avatarCache = [...state.avatarCache, ...payload]
    },
    RESET(state) {
      state.show = false
      state.isLogin = false
      state.mode = 'all'
      state.conversation = null
      state.imToken = ''
      state.unread = 0
      state.userID = ''
      state.conversationList = [] // 所有回话列表
      state.groupMembers = []
      state.hasMoreMember = false
      state.currentMsg = []
      state.hasMoreMsg = false
      state.hasNew = false
      state.isLogin = false
      state.avatarCache = []
    }
  },
  actions: {
    // 获取IMTOKEN
    getImToken({ commit }) {
      return getImToken({
        platform: 5
      }).then(({ data }) => {
        commit('SET_TOKEN', data.imToken)
        return data.imToken
      })
    },
    // 获取未读消息数
    getUnread({ commit }) {
      openIM
        .getTotalUnreadMsgCount()
        .then((res) => {
          dd(res)
          if (res.errCode === 0) {
            commit('SET_UNREAD', Number(res.data))
          }
        })
        .catch((err) => {
          dd(err)
        })
    },
    // 登录IM
    async loginIM(context) {
      const state = context.state
      if (!state.isLogin) {
        const token = await context.dispatch('getImToken')
        const config = {
          userID: context.rootGetters.session?.userId,
          token: token,
          url: context.rootGetters.session.imWsUrl,
          platformID: 5
        }
        await openIM.login(config)
        openIM.getSelfUserInfo().then(({ data }) => {
          console.log(data)
        })
        state.isLogin = true
      }
      await context.dispatch('getUnread')
      await context.dispatch('getAllConversation')
    },
    // 登出IM
    logoutIm({ commit }) {
      openIM
        .logout()
        .then(() => {
          commit('RESET')
        })
        .catch(() => {
          commit('RESET')
        })
    },
    // 获取所有的会话
    async getAllConversation(context) {
      const { state, commit } = context
      const res = await openIM.getAllConversationList()
      if (res.errCode === 0) {
        console.log('JSON.parse(res.data)', JSON.parse(res.data))
        const conversationList = JSON.parse(res.data)
        const uids = conversationList
          .filter((item) => item.userID)
          .map((item) => item.userID)

        await context.dispatch('queryAvatar', uids)

        const cache = state.avatarCache
        cache.forEach((el) => {
          const item = conversationList.find(
            (item) => item.userID === el.userId
          )
          if (item) {
            item.faceURL = el.avatar
          }
        })
        commit('SET_CONVERSATION_List', conversationList)
      }
    },
    async queryAvatar({ state, commit }, uids) {
      uids = uids.filter((item) => {
        const idx = state.avatarCache.findIndex((e) => e.userId === item)
        return idx === -1
      })
      if (uids.length > 0) {
        const avatarRes = await queryAvatar({
          userIds: uids.join(',')
        })
        if (
          avatarRes.errorCode === 0 &&
          avatarRes.data &&
          avatarRes.data.length > 0
        ) {
          commit('UPDATE_AVATAR', avatarRes.data || [])
        }
      }
    },
    // 获取成员列表
    async getGroupMemberList({ state }, options) {
      if (!options) {
        options = {
          groupID: state.conversation.groupID,
          filter: 0,
          offset: 0,
          count: 50
        }
      }
      const res = await openIM.getGroupMemberList(options)
      const members = JSON.parse(res.data || '[]')
      state.hasMoreMember = members.length === options.count
      if (options.offset === 0) {
        state.groupMembers = [...members]
      } else {
        state.groupMembers = [...state.groupMembers, ...members]
      }
      // return openIM.getGroupMemberList(options).then(({ data }) => {
      //   const members = JSON.parse(data || '[]')
      //   state.hasMoreMember = members.length === options.count
      //   if (options.offset === 0) {
      //     state.groupMembers = [...members]
      //   } else {
      //     state.groupMembers = [...state.groupMembers, ...members]
      //   }
      // })
    },
    // 设置当前会话
    async setConversation(context, payload) {
      const commit = context.commit
      const state = context.state
      if (!payload) {
        commit('SET_CONVERSATION', null)
      } else {
        if (
          !state.conversation ||
          state.conversation.conversationID !== payload.conversationID
        ) {
          if (payload.userID && !payload.faceURL) {
            context.dispatch('queryAvatar', [payload.userID])
            const item = state.avatarCache.find(
              (item) => item.userId === payload.userID
            )
            if (item) {
              payload.faceURL = item.avatar
            }
          }
          commit('SET_CONVERSATION', payload)
          if (payload.groupID) {
            // 如果会话是群组，加载群组所有成员
            state.groupMembers = []
            state.hasMoreMember = false
            await context.dispatch('getGroupMemberList')
            openIM
              .getOneConversation({
                sourceID: payload.groupID,
                sessionType: 2
              })
              .then(({ data }) => {
                console.log('OneConversation', data)
              })
          } else {
            openIM
              .getOneConversation({
                sourceID: payload.userID,
                sessionType: 1
              })
              .then(({ data }) => {
                console.log('OneConversation', data)
              })
          }
          state.currentMsg = []
          state.startClientMsgID = ''
          state.hasMoreMsg = false
          await context.dispatch('getHistoryMessageList')
        }
        await context.dispatch('markMessageAsRead')
      }
      // return new Promise((resolve, reject) => {
      //   if (!payload) {
      //     commit('SET_CONVERSATION', null)
      //     resolve()
      //   } else
      // })
    },
    // 获取历史消息
    getHistoryMessageList({ state }, options) {
      if (!options) {
        options = {
          groupID: state.conversation.groupID,
          userId: state.conversation.userID,
          count: 10,
          startClientMsgID: state.startClientMsgID
        }
      }
      return openIM.getHistoryMessageList(options).then((res) => {
        if (res.errCode === 0) {
          const msgList = JSON.parse(res.data)
          state.hasMoreMsg = msgList.length === options.count
          state.currentMsg = [...msgList, ...state.currentMsg]
          state.startClientMsgID =
            state.currentMsg.length > 0 ? state.currentMsg[0].clientMsgID : ''
        }
        return state.currentMsg
      })
    },
    // 标记会话消息已读
    markMessageAsRead({ state, dispatch }, options) {
      if (!options) {
        options = {
          groupID: state.conversation.groupID,
          userID: state.conversation.userID,
          msgIDList: []
        }
      }
      if (options.userID) {
        openIM.markC2CMessageAsRead({
          userID: options.userID,
          msgIDList: options.msgIDList || []
        })
      }
      if (options.groupID) {
        openIM.markGroupMessageHasRead(options.groupID)
      }
      dispatch('getUnread')
    },
    // 接收新消息
    recvNewMessage({ state, dispatch }, payload) {
      console.log('recvNewMessage', payload)
      if (payload.errCode === 0) {
        const msg = JSON.parse(payload.data)
        // 如果是当前会话
        if (
          state.conversation &&
          (state.conversation.userID === msg.sendID ||
            state.conversation.groupID === msg.groupID)
        ) {
          state.currentMsg.push(msg)
        }
        // state.hasNew = true
        dispatch('getUnread')
      }
    },
    async sendMessageNotOss({ state }, msg) {
      if (msg) {
        const conversation = state.conversation
        if (conversation) {
          // 当前有会话的话，添加消息到会话列表
          const options = {
            recvID: conversation.userID || '',
            groupID: conversation.groupID || '',
            message: JSON.stringify(msg),
            offlinePushInfo: {
              title: 'you have a new message',
              desc: '',
              ex: '',
              iOSPushSound: '',
              iOSBadgeCount: true
            }
          }
          console.log(options)
          const rec = await openIM.sendMessageNotOss(options)
          if (rec.errCode === 0) {
            msg.status = 2
          } else {
            msg.status = 3
          }
        }
      }
    },
    async sendMessage({ state, dispatch }, payload) {
      const type = payload.type
      let obj = null
      let msg = null
      switch (type) {
        case messageTypes.TEXTMESSAGE:
          obj = await openIM.createTextMessage(payload.text)
          if (obj.errCode === 0) {
            msg = JSON.parse(obj.data)
          }
          break
        case messageTypes.PICTUREMESSAGE:
          obj = await openIM.createImageMessage(payload.info)
          if (obj.errCode === 0) {
            msg = JSON.parse(obj.data)
          }
          break
      }
      if (msg) {
        await dispatch('sendMessageNotOss', msg)
        state.currentMsg.push(msg)
      }
    },
    deleteConversation({ state }, payload) {
      const conversationID = payload.conversationID
      if (conversationID) {
        return openIM.deleteConversation(conversationID).then(() => {
          const idx = (state.conversationList || []).findIndex(
            (item) => item.conversationID === payload
          )
          if (idx !== -1) {
            state.conversationList.splice(idx, 1)
          }
        })
      }
    },
    singleIM(context, info) {
      const commit = context.commit
      context.dispatch('loginIM').then(() => {
        context.dispatch('setConversation', info)
        commit('TOGGLE_DIALOG', true)
        commit('SET_MODE', 'single')
      })
    },
    groupIM(context, info) {
      const commit = context.commit
      context.dispatch('loginIM').then(() => {
        context.dispatch('setConversation', info)
        commit('TOGGLE_DIALOG', true)
        commit('SET_MODE', 'group')
        context.dispatch('getGroupMemberList', {
          groupID: info.groupID,
          filter: 0,
          offset: 0,
          count: 20
        })
      })
    },
    allIM(context) {
      // const commit = context.commit
      const state = context.state
      context.dispatch('loginIM').then(() => {
        state.show = true
        state.hasNew = false
        state.mode = 'all'
        if (!state.conversation && state.conversationList.length > 0) {
          context.dispatch('setConversation', context.state.conversationList[0])
        }
      })
    }
    // ChangeIm({ commit }, info) {
    //   commit('SET_UNREAD', info)
    // }
  }
}

export default im
