export default [
  {
    code: '1F600',
    text: '😀',
    keyword: '😀^嘿嘿^^^嘿嘿|笑脸|脸',
    img: '1F600.png',
    url: 'https://res.airrnd.com/disk/load/d52832827aea05be306fdfdcd4f85e82'
  },
  {
    code: '1F643',
    text: '🙃',
    keyword: '🙃^倒脸^^^倒脸|脸|颠倒|颠倒的脸|呵呵',
    img: '1F643.png',
    url: 'https://res.airrnd.com/disk/load/72e439ec524e58b8ea0298b57f9d1592'
  },
  {
    code: '1F609',
    text: '😉',
    keyword: '😉^眨眼^^^媚眼|眨眼|笑',
    img: '1F609.png',
    url: 'https://res.airrnd.com/disk/load/0160b8226c883c3d96ce8a201c6466aa'
  },
  {
    code: '1F60A',
    text: '😊',
    keyword: '😊^羞涩微笑^^^害羞|微笑|羞涩|羞涩微笑|脸|脸红|友好',
    img: '1F60A.png',
    url: 'https://res.airrnd.com/disk/load/741ef953e4685009fcca8ff2f4665c1a'
  },
  {
    code: '1F607',
    text: '😇',
    keyword: '😇^微笑天使^^^光环|天使|天真|幻想|微笑|微笑天使|脸',
    img: '1F607.png',
    url: 'https://res.airrnd.com/disk/load/38a1f403e1169f0e04e7c5bd83d0cbd6'
  },
  {
    code: '1F603',
    text: '😃',
    keyword: '😃^哈哈^^^哈哈|开口笑|笑脸|脸',
    img: '1F603.png',
    url: 'https://res.airrnd.com/disk/load/834bdf429ed44b6e081231d8477e856a'
  },
  {
    code: '1F604',
    text: '😄',
    keyword: '😄^大笑^^^哈哈|大笑|开心|笑|脸|高兴|憨憨',
    img: '1F604.png',
    url: 'https://res.airrnd.com/disk/load/d5307d5328e7939004db496954890dbb'
  },
  {
    code: '1F601',
    text: '😁',
    keyword: '😁^嘻嘻^^^嘻嘻|笑脸|脸',
    img: '1F601.png',
    url: 'https://res.airrnd.com/disk/load/a9943064ea3f11265cd1b4bf06b188d0'
  },
  {
    code: '1F606',
    text: '😆',
    keyword: '😆^斜眼笑^^^开心|斜眼笑|眯眼|笑|脸|高兴',
    img: '1F606.png',
    url: 'https://res.airrnd.com/disk/load/66bf9666265697d4fd6c3e3cd7710099'
  },
  {
    code: '1F605',
    text: '😅',
    keyword: '😅^苦笑^^无语|尴尬^冷汗|汗|脸|苦笑',
    img: '1F605.png',
    url: 'https://res.airrnd.com/disk/load/17c5765c5e4f976964692614ee58e1d1'
  },
  {
    code: '1F923',
    text: '🤣',
    keyword: '🤣^笑得满地打滚^^^地板|打滚|笑|笑得满地打滚|脸|大笑',
    img: '1F923.png',
    url: 'https://res.airrnd.com/disk/load/32e0f036b8c44bf2ade23b9ed3a14ccf'
  },
  {
    code: '1F602',
    text: '😂',
    keyword: '😂^笑哭了^^^眼泪|笑|笑哭了|脸',
    img: '1F602.png',
    url: 'https://res.airrnd.com/disk/load/ddbe8cee9753d3e31a18c2c0f7f1a849'
  },
  {
    code: '1F642',
    text: '🙂',
    keyword: '🙂^呵呵^^^呵呵|开心|笑|脸',
    img: '1F642.png',
    url: 'https://res.airrnd.com/disk/load/067eaec3d51df9610efd071871da25d7'
  },
  {
    code: '1F615',
    text: '😕',
    keyword: '😕^困扰^^|撇嘴^不懂|困惑|困扰|疑惑|脸',
    img: '1F615.png',
    url: 'https://res.airrnd.com/disk/load/3613e131c5c92afd766ae744542da79e'
  },
  {
    code: '1F61F',
    text: '😟',
    keyword: '😟^担心^^|皱眉^不高兴|伤心|担心|担忧|焦虑|脸|皱眉',
    img: '1F61F.png',
    url: 'https://res.airrnd.com/disk/load/7e002991270901c146024e8f933f42e8'
  },
  {
    code: '1F641',
    text: '🙁',
    keyword: '🙁^微微不满^^^不开心|不高兴|委屈|微微不满|心情不好|脸',
    img: '1F641.png',
    url: 'https://res.airrnd.com/disk/load/091fb807c165baef20c11ac07004a0ce'
  },
  {
    code: '2639',
    text: '☹',
    keyword: '☹^不满^^^不满|不爽|不高兴|皱眉|脸',
    img: '2639.png',
    url: 'https://res.airrnd.com/disk/load/0b6ad96fe26f75244a23b597b7e36c8a'
  },
  {
    code: '1F62E',
    text: '😮',
    keyword: '😮^吃惊^^^吃惊|同情|啊|脸',
    img: '1F62E.png',
    url: 'https://res.airrnd.com/disk/load/44f056d76107fc9b1c77008c0a04d8ba'
  },
  {
    code: '1F62F',
    text: '😯',
    keyword: '😯^缄默^^^吃惊|缄默|脸',
    img: '1F62F.png',
    url: 'https://res.airrnd.com/disk/load/5ea71ff40bee3a4b9459fab430826305'
  },
  {
    code: '1F632',
    text: '😲',
    keyword: '😲^震惊^^|惊讶^惊|惊讶|脸|震惊',
    img: '1F632.png',
    url: 'https://res.airrnd.com/disk/load/ee31942968632ea42f1af339d8d124ef'
  },
  {
    code: '1F633',
    text: '😳',
    keyword: '😳^脸红^^^困惑|害羞|羞涩|脸|脸红|茫然|迷茫|愣住',
    img: '1F633.png',
    url: 'https://res.airrnd.com/disk/load/a1cc22aa96017db5bc780e208616bb4c'
  },
  {
    code: '1F97A',
    text: '🥺',
    keyword: '🥺^恳求的脸^^^可怜兮兮的眼神|怜悯|恳求的脸|祈求|好嘛',
    img: '1F97A.png',
    url: 'https://res.airrnd.com/disk/load/b36ed88d0213026f39d4f364acdce62b'
  },
  {
    code: '1F626',
    text: '😦',
    keyword: '😦^啊^^|惊讶^啊|惊讶|目瞪口呆|脸',
    img: '1F626.png',
    url: 'https://res.airrnd.com/disk/load/33e14d0665e4b93c31643a948c050598'
  },
  {
    code: '1F627',
    text: '😧',
    keyword: '😧^极度痛苦^^^极度痛苦|痛|脸|难受',
    img: '1F627.png',
    url: 'https://res.airrnd.com/disk/load/29f6b27b7333b51b16581af2ec644690'
  },
  {
    code: '1F628',
    text: '😨',
    keyword: '😨^害怕^^^害怕|怕|恐怖|恐惧|脸',
    img: '1F628.png',
    url: 'https://res.airrnd.com/disk/load/cf3c75860f7498142f4f8ab563ae690d'
  },
  {
    code: '1F630',
    text: '😰',
    keyword: '😰^冷汗^^^冷汗|无语|汗|焦虑|紧张|脸|满头大汗',
    img: '1F630.png',
    url: 'https://res.airrnd.com/disk/load/4f97e6e7cef51b53fec2a985386b8d0f'
  },
  {
    code: '1F625',
    text: '😥',
    keyword: '😥^失望但如释重负^^|衰^失望|失望但如释重负|如释重负|脸',
    img: '1F625.png',
    url: 'https://res.airrnd.com/disk/load/30308d58c7d7645c0498fa874e94dc0c'
  },
  {
    code: '1F622',
    text: '😢',
    keyword: '😢^哭^^|眼泪^伤心|哭|流泪|脸',
    img: '1F622.png',
    url: 'https://res.airrnd.com/disk/load/2d1ba14f2cffffbb6c75bed0d16b63ef'
  },
  {
    code: '1F62D',
    text: '😭',
    keyword: '😭^放声大哭^^^哭|大哭|放声大哭|泪|痛哭|脸',
    img: '1F62D.png',
    url: 'https://res.airrnd.com/disk/load/fc81497103d83db139246ffdf876ed7e'
  },
  {
    code: '1F631',
    text: '😱',
    keyword: '😱^吓死了^^|尖叫^吓死|吓死了|害怕|尖叫|恐怖|脸',
    img: '1F631.png',
    url: 'https://res.airrnd.com/disk/load/8a7c7c88b977837f8281eeb52da25d82'
  },
  {
    code: '1F616',
    text: '😖',
    keyword: '😖^困惑^^|紧张^困惑|焦头烂额|纠结|脸|难受|紧张',
    img: '1F616.png',
    url: 'https://res.airrnd.com/disk/load/34f96af70955f1e5f4aa4a1721c10c07'
  },
  {
    code: '1F623',
    text: '😣',
    keyword: '😣^痛苦^^|纠结^忍耐|痛苦|脸|难受|纠结',
    img: '1F623.png',
    url: 'https://res.airrnd.com/disk/load/cd2a0730235a87e6cf3432fd987b9057'
  },
  {
    code: '1F61E',
    text: '😞',
    keyword: '😞^失望^^^不高兴|失望|脸|难过',
    img: '1F61E.png',
    url: 'https://res.airrnd.com/disk/load/ec4b1a7d57a7bc55d3eef9f50ea5f817'
  },
  {
    code: '1F613',
    text: '😓',
    keyword: '😓^汗^^|无语^冷|尴尬|汗|脸',
    img: '1F613.png',
    url: 'https://res.airrnd.com/disk/load/ecb29f2395c39ac68300ecfe802870e4'
  },
  {
    code: '1F629',
    text: '😩',
    keyword: '😩^累死了^^^疲倦|疲劳|疲惫|累|累死了|脸',
    img: '1F629.png',
    url: 'https://res.airrnd.com/disk/load/426e7ec226362fbea37718b5e65371dc'
  },
  {
    code: '1F62B',
    text: '😫',
    keyword: '😫^累^^^疲倦|疲劳|疲惫|累|脸',
    img: '1F62B.png',
    url: 'https://res.airrnd.com/disk/load/82c9647a1b43ea34c44a279ec23fdae6'
  },
  {
    code: '1F971',
    text: '🥱',
    keyword: '🥱^打呵欠^^^呵欠|哈欠|困|打呵欠|无聊|累',
    img: '1F971.png',
    url: 'https://res.airrnd.com/disk/load/17db144a14591d55a4c03a18d3b01c2b'
  },
  {
    code: '1F624',
    text: '😤',
    keyword: '😤^傲慢^^|哼^傲慢|胜利|自负|赢|趾高气昂',
    img: '1F624.png',
    url: 'https://res.airrnd.com/disk/load/fd27249219936a261c52df7d904fb39a'
  },
  {
    code: '1F621',
    text: '😡',
    keyword: '😡^怒火中烧^^|红脸^发火|发飙|怒|怒火中烧|生气|脸',
    img: '1F621.png',
    url: 'https://res.airrnd.com/disk/load/b1835ec17fe58eace62a6697d70399d1'
  },
  {
    code: '1F620',
    text: '😠',
    keyword: '😠^生气^^^怒|愤怒|生气|脸',
    img: '1F620.png',
    url: 'https://res.airrnd.com/disk/load/7dc4e7385308f809bb3ffe44124788ea'
  },
  {
    code: '1F496',
    text: '💖',
    keyword: '💖^闪亮的心^^|粉色的心^激动|红心|闪亮|闪亮的心',
    img: '1F496.png',
    url: 'https://res.airrnd.com/disk/load/534b9be7f9f1ba3df2d8c3b3cbc1eb9a'
  },
  {
    code: '1F494',
    text: '💔',
    keyword: '💔^心碎^^|失恋^伤心|心碎|难过',
    img: '1F494.png',
    url: 'https://res.airrnd.com/disk/load/e31b4d84ebcddfe2ea511108647a409d'
  },
  {
    code: '2764',
    text: '❤',
    keyword: '❤^红心^^爱心^心|爱|红心|桃心|情人节|520',
    img: '2764.png',
    url: 'https://res.airrnd.com/disk/load/42a1617d6b67b660ef7ed14b31b8c4d5'
  },
  {
    code: '1F970',
    text: '🥰',
    keyword: '🥰^喜笑颜开^^^喜笑颜开|心|爱慕|迷恋|陷入爱河|喜欢',
    img: '1F970.png',
    url: 'https://res.airrnd.com/disk/load/e204b3f95c32b8dae0712dcdb4445e3c'
  },
  {
    code: '1F60D',
    text: '😍',
    keyword: '😍^花痴^^^爱|红心|脸|花痴',
    img: '1F60D.png',
    url: 'https://res.airrnd.com/disk/load/6bfb36f49e85a50d200766bcee8f9708'
  },
  {
    code: '1F929',
    text: '🤩',
    keyword: '🤩^好崇拜哦^^^咧嘴笑|好崇拜哦|脸|露齿笑|星星眼',
    img: '1F929.png',
    url: 'https://res.airrnd.com/disk/load/ae8f69e4b513116de46e878fd42de739'
  },
  {
    code: '1F618',
    text: '😘',
    keyword: '😘^飞吻^^亲亲^亲亲|眨眼|脸|飞吻',
    img: '1F618.png',
    url: 'https://res.airrnd.com/disk/load/ece5c2d884f4ffddad0d5227a4a1c7d0'
  },
  {
    code: '1F617',
    text: '😗',
    keyword: '😗^亲亲^^^亲亲|吻|脸',
    img: '1F617.png',
    url: 'https://res.airrnd.com/disk/load/51e31d514961c23fda4f7c1375ec0ea2'
  },
  {
    code: '263A',
    text: '☺',
    keyword: '☺^微笑^^^呵呵|开心|微笑|放松|笑|脸',
    img: '263A.png',
    url: 'https://res.airrnd.com/disk/load/21d95128412bcc19dda34bb84f2b520d'
  },
  {
    code: '1F61A',
    text: '😚',
    keyword: '😚^羞涩亲亲^^^亲亲|吻|羞涩|羞涩亲亲|脸|闭眼',
    img: '1F61A.png',
    url: 'https://res.airrnd.com/disk/load/b14cfd1b05831e8e276419641e13f546'
  },
  {
    code: '1F619',
    text: '😙',
    keyword: '😙^微笑亲亲^^^亲亲|吻|微笑|微笑亲亲|脸',
    img: '1F619.png',
    url: 'https://res.airrnd.com/disk/load/9af28bdc315172ff319c8a467ac2e0ec'
  },
  {
    code: '1F60B',
    text: '😋',
    keyword: '😋^好吃^^^好吃|津津有味|美味|脸',
    img: '1F60B.png',
    url: 'https://res.airrnd.com/disk/load/2f2b9d60723896651ee4be72353524e7'
  },
  {
    code: '1F61B',
    text: '😛',
    keyword: '😛^吐舌^^^吐舌|脸|舌头|调皮',
    img: '1F61B.png',
    url: 'https://res.airrnd.com/disk/load/c6c36dae76c52ac3fe9bf89d9c8b958f'
  },
  {
    code: '1F61C',
    text: '😜',
    keyword: '😜^单眼吐舌^^^单眼|单眼吐舌|吐舌|调皮|开玩笑|脸',
    img: '1F61C.png',
    url: 'https://res.airrnd.com/disk/load/2e164f29bc27cf4faf9d7b1a313557f4'
  },
  {
    code: '1F92A',
    text: '🤪',
    keyword: '🤪^滑稽^^^大眼|小眼|滑稽|滑稽的脸|疯狂的脸|脸',
    img: '1F92A.png',
    url: 'https://res.airrnd.com/disk/load/e841f845c8617a474379b11b4734b48c'
  },
  {
    code: '1F61D',
    text: '😝',
    keyword: '😝^眯眼吐舌^^^可怕|吐舌|眯眼|眯眼吐舌|眼睛|脸',
    img: '1F61D.png',
    url: 'https://res.airrnd.com/disk/load/a58e3353bc10dafc4d8c143fa3c05bc9'
  },
  {
    code: '1F911',
    text: '🤑',
    keyword: '🤑^发财^^^发财|拜金|脸|见钱眼开|钱',
    img: '1F911.png',
    url: 'https://res.airrnd.com/disk/load/e5330192b0ef0afd94510d9c8e63c9f6'
  },
  {
    code: '1F917',
    text: '🤗',
    keyword: '🤗^抱抱^^^抱|抱抱|拥抱|笑|脸|欢迎',
    img: '1F917.png',
    url: 'https://res.airrnd.com/disk/load/b184cd04cd1fd12fff41458c80de9f16'
  },
  {
    code: '1F92D',
    text: '🤭',
    keyword: '🤭^不说^^^不说|哎呀|脸',
    img: '1F92D.png',
    url: 'https://res.airrnd.com/disk/load/29204a50f77c42d2ab270bf2558e7500'
  },
  {
    code: '1F92B',
    text: '🤫',
    keyword: '🤫^安静的脸^^^嘘|安静|安静的脸|小声',
    img: '1F92B.png',
    url: 'https://res.airrnd.com/disk/load/83d62b779bf6927977cf9c6200442e51'
  },
  {
    code: '1F914',
    text: '🤔',
    keyword: '🤔^想一想^^^思考|想|想一想|脸',
    img: '1F914.png',
    url: 'https://res.airrnd.com/disk/load/49cfda0e761784c57157c939b3d7f5a9'
  },
  {
    code: '1F910',
    text: '🤐',
    keyword: '🤐^闭嘴^^^嘴|脸|闭嘴|秘密|不说',
    img: '1F910.png',
    url: 'https://res.airrnd.com/disk/load/9390368e38c9f1ccef8c9793c9cd30a9'
  },
  {
    code: '1F928',
    text: '🤨',
    keyword: '🤨^挑眉^^^不信任|怀疑|挑眉|眉毛上挑的脸|脸',
    img: '1F928.png',
    url: 'https://res.airrnd.com/disk/load/c0fcac3ce5aeaea91aed791ae4a7779d'
  },
  {
    code: '1F610',
    text: '😐',
    keyword: '😐^冷漠^^^冷漠|脸|面无表情|哦',
    img: '1F610.png',
    url: 'https://res.airrnd.com/disk/load/ef40e435ebd6c1e1339e102a2636a7e3'
  },
  {
    code: '1F611',
    text: '😑',
    keyword: '😑^无语^^^无语|脸|茫然|面无表情',
    img: '1F611.png',
    url: 'https://res.airrnd.com/disk/load/2546d5755f38621fed6a641ed2e209b7'
  },
  {
    code: '1F636',
    text: '😶',
    keyword: '😶^沉默^^^嘴|安静|沉默|脸',
    img: '1F636.png',
    url: 'https://res.airrnd.com/disk/load/ca89518f4beed47899b442ac28ae4c92'
  },
  {
    code: '1F60F',
    text: '😏',
    keyword: '😏^得意^^^假笑|得意',
    img: '1F60F.png',
    url: 'https://res.airrnd.com/disk/load/567931c6da732777465b0dcea9d576f9'
  },
  {
    code: '1F612',
    text: '😒',
    keyword: '😒^不高兴^^^不屑|不服|不高兴|脸|郁闷|鄙视',
    img: '1F612.png',
    url: 'https://res.airrnd.com/disk/load/2e83b66ffa6e12c5c1290d0b73147aec'
  },
  {
    code: '1F644',
    text: '🙄',
    keyword: '🙄^翻白眼^^^无语|白眼|翻白眼|脸',
    img: '1F644.png',
    url: 'https://res.airrnd.com/disk/load/f69ef6863ba20cd291448b03985eeae3'
  },
  {
    code: '1F62C',
    text: '😬',
    keyword: '😬^龇牙咧嘴^^^咬牙切齿|脸|露齿|鬼脸|龇牙咧嘴',
    img: '1F62C.png',
    url: 'https://res.airrnd.com/disk/load/252bb0ca9dcb4625a4e0b82acc6791c6'
  },
  {
    code: '1F925',
    text: '🤥',
    keyword: '🤥^说谎^^^匹诺曹|脸|说谎|长鼻子',
    img: '1F925.png',
    url: 'https://res.airrnd.com/disk/load/532732bb7c95b093cbf9d08141e7d796'
  },
  {
    code: '1F60C',
    text: '😌',
    keyword: '😌^松了口气^^^闭眼|如释重负|松了口气|松口气|脸',
    img: '1F60C.png',
    url: 'https://res.airrnd.com/disk/load/b0189e5301067fc66285b4598615f767'
  },
  {
    code: '1F614',
    text: '😔',
    keyword: '😔^沉思^^^心事重重|沉思|脸|皱眉',
    img: '1F614.png',
    url: 'https://res.airrnd.com/disk/load/a7f0fda712085f70f3e7fe5ff9985a0d'
  },
  {
    code: '1F62A',
    text: '😪',
    keyword: '😪^困^^^困|睡觉|瞌睡|脸|打呼噜|无聊',
    img: '1F62A.png',
    url: 'https://res.airrnd.com/disk/load/b353183b409891b18c5e9a0d421b8039'
  },
  {
    code: '1F924',
    text: '🤤',
    keyword: '🤤^流口水^^^口水|垂涎三尺|流口水|脸',
    img: '1F924.png',
    url: 'https://res.airrnd.com/disk/load/e7b619420d4bc465854615b6a536eb89'
  },
  {
    code: '1F634',
    text: '😴',
    keyword: '😴^睡着了^^^呼噜|打呼|睡着了|脸|瞌睡|睡觉',
    img: '1F634.png',
    url: 'https://res.airrnd.com/disk/load/b1b2379bf972e121be626e661e107e58'
  },
  {
    code: '1F637',
    text: '😷',
    keyword: '😷^感冒^^|口罩^医生|口罩|感冒|生病|脸',
    img: '1F637.png',
    url: 'https://res.airrnd.com/disk/load/6798625eb290b462806615663a2ae9c5'
  },
  {
    code: '1F912',
    text: '🤒',
    keyword: '🤒^发烧^^^体温计|发烧|温度计|生病|脸',
    img: '1F912.png',
    url: 'https://res.airrnd.com/disk/load/a45a5266f0ad96f37af5afd232b87549'
  },
  {
    code: '1F915',
    text: '🤕',
    keyword: '🤕^受伤^^^受伤|绷带|脸',
    img: '1F915.png',
    url: 'https://res.airrnd.com/disk/load/5e88b75d34cadf762b3f921f591de199'
  },
  {
    code: '1F922',
    text: '🤢',
    keyword: '🤢^恶心^^^吐|呕|恶心|脸',
    img: '1F922.png',
    url: 'https://res.airrnd.com/disk/load/a08959f1e89bbc0887f21db42b37e062'
  },
  {
    code: '1F92E',
    text: '🤮',
    keyword: '🤮^呕吐^^^不舒服|呕吐|脸',
    img: '1F92E.png',
    url: 'https://res.airrnd.com/disk/load/cf5e44656cc1acd142fea44bc69afa27'
  },
  {
    code: '1F927',
    text: '🤧',
    keyword: '🤧^打喷嚏^^^喷嚏|打喷嚏|脸|鼻涕|感冒|鼻炎',
    img: '1F927.png',
    url: 'https://res.airrnd.com/disk/load/50133e5f9a9b4a1591a5728f75aed347'
  },
  {
    code: '1F975',
    text: '🥵',
    keyword: '🥵^脸发烧^^^出汗|发烧|发热|脸发烧|脸红|口渴|口干|辣',
    img: '1F975.png',
    url: 'https://res.airrnd.com/disk/load/080ed900796f4ca0d358d718c4a1dd8a'
  },
  {
    code: '1F976',
    text: '🥶',
    keyword: '🥶^冷脸^^^冷|冷冰冰|冷脸|冻僵|满面寒霜',
    img: '1F976.png',
    url: 'https://res.airrnd.com/disk/load/2c2712a232c6c1f455f0b02c49a1fa72'
  },
  {
    code: '1F974',
    text: '🥴',
    keyword: '🥴^头昏眼花^^^嘴唇颤抖|头昏眼花|头晕眼花|微醺|眼花|喝醉',
    img: '1F974.png',
    url: 'https://res.airrnd.com/disk/load/6f1acbf32444299ef812a58965aa4df8'
  },
  {
    code: '1F635',
    text: '😵',
    keyword: '😵^晕头转向^^^头晕|头晕眼花|晕头|晕头转向|脸',
    img: '1F635.png',
    url: 'https://res.airrnd.com/disk/load/3200c4046064451a49791e65b6ccc429'
  },
  {
    code: '1F92F',
    text: '🤯',
    keyword: '🤯^爆炸头^^^爆炸头|震惊',
    img: '1F92F.png',
    url: 'https://res.airrnd.com/disk/load/3e4d6c0ccd5e8ef9bebbae14c5ef5fcb'
  },
  {
    code: '1F920',
    text: '🤠',
    keyword: '🤠^牛仔帽脸^^^帽|牛仔|牛仔帽脸|脸',
    img: '1F920.png',
    url: 'https://res.airrnd.com/disk/load/07116ddf80ac350fd2b4217798e7a57f'
  },
  {
    code: '1F973',
    text: '🥳',
    keyword: '🥳^聚会笑脸^^^号角|帽子|庆祝|聚会|聚会笑脸',
    img: '1F973.png',
    url: 'https://res.airrnd.com/disk/load/b9dc4d04edb2dc19b94d4909f976b645'
  },
  {
    code: '1F978',
    text: '🥸',
    keyword: '🥸^伪装的脸^^^伪装|伪装的脸|眼镜|脸|隐瞒身份|鼻子',
    img: '1F978.png',
    url: 'https://res.airrnd.com/disk/load/62c22295d485677f34d904f44ee0c9e4'
  },
  {
    code: '1F60E',
    text: '😎',
    keyword: '😎^墨镜笑脸^^|得意^太阳镜|眼镜|酷|墨镜|墨镜笑脸|自信',
    img: '1F60E.png',
    url: 'https://res.airrnd.com/disk/load/e764ed4010aa524106f7d1a7de57df21'
  },
  {
    code: '1F913',
    text: '🤓',
    keyword: '🤓^书呆子脸^^^书呆子脸|奇葩|脸',
    img: '1F913.png',
    url: 'https://res.airrnd.com/disk/load/c24bbed0ab5f93b536ddfcd69e9a9089'
  },
  {
    code: '1F9D0',
    text: '🧐',
    keyword: '🧐^带单片眼镜的脸^^^古板|带单片眼镜的脸|探究',
    img: '1F9D0.png',
    url: 'https://res.airrnd.com/disk/load/d0e57ae3317746588a15255d3d27b78b'
  }
]
