<template>
  <div id="app">
    <router-view />
    <air-dialog />
  </div>
</template>
<script>
import AirDialog from '@/components/AirIm/AirDialog.vue'
export default {
  components: {
    AirDialog
  }
}
</script>
<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  // overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
// p {
//   margin: 0px;
// }
.project-type-logo {
  width: 24px;
  height: 24px;
}
</style>
