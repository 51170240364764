export default {
  findItem: {
    itemList: {
      title: '项目列表',
      item: {
        currencyType: [
          {
            label: '人民币',
            value: 'CNY'
          },
          {
            label: '美元',
            value: 'USD'
          },
          {
            label: '欧元',
            value: 'EUR'
          }
        ],
        status: [
          {
            label: '草稿',
            value: '0'
          },
          {
            label: '有效',
            value: '1'
          },
          {
            label: '招标',
            value: '2'
          },
          {
            label: '已招标',
            value: '3'
          },
          {
            label: '待签合同',
            value: '4'
          },
          {
            label: '已签合同',
            value: '5'
          },
          {
            label: '已交付',
            value: '6'
          },
          {
            label: '已验收',
            value: '7'
          },
          {
            label: '意外终止',
            value: '8'
          },
          {
            label: '已完成',
            value: '8'
          }
        ],
        switchingState: ['展开', '折叠']
      }
    },
    itemDetails: {
      left: {
        title: '项目详情',
        sketch: {
          parBidding: '参与投标',
          biding: '已投标'
        },
        term: {
          taskReq: '任务需求',
          skillReq: '技能要求',
          expComTime: '期望完成时间：',
          enclosure: '附件'
        }
      },
      right: {
        title: '关于发布者', // 新修改
        contact: '联系TA',
        company: '公司：',
        country: '国家或地区：',
        numPubItems: '发布项目数：',
        name: '联系人' // 新增加
      },
      dialog: {
        title: '参与投标',
        reason: '请对HP说几句话，可以简单介绍您的背景或经验或具备的优势'
      },
      unitOfTime: ['日', '周', '月', '年'],
      deliveryUnit: [
        {
          label: '日',
          value: '1'
        },
        {
          label: '周',
          value: '2'
        },
        {
          label: '月',
          value: '3'
        },
        {
          label: '年',
          value: '4'
        }
      ]
    },
    certification: '请先完成身份认证，成为认证用户哦!'
  },
  personalHomepage: {
    header: {
      contactHim: '联系我',
      preview: '预览'
    },
    mainLeftArray: ['描述', '接单类型', '工作语言', '擅长技能'],
    mainLeft: {
      desc: '描述',
      orderRecType: '接单类型',
      workLang: '工作语言',
      goodSkills: '擅长技能'
    },
    mainRight: {
      proExper: '项目经历'
    },
    dialog: {
      edit: '编辑',
      aboutUs: '关于我们',
      prjName: '项目名称',
      prjDesc: '项目描述',
      uploadImg: '上传图片',
      tip: '最多上传8张'
    }
  }
}
