import Layout3 from '../../views/layout/indexView3'
// 招聘
const Recruit = [
  {
    path: '/recruit',
    name: 'Recruit',
    component: Layout3,
    children: [
      // 招聘主页
      {
        path: 'index',
        component: () => import('@/views/link/recruitView.vue'),
        name: 'recruitIndex'
      },
      {
        path: 'detail',
        component: () => import('@/views/link/recruitDetailView.vue'),
        name: 'recruitDetail'
      }
    ]
  }
]
export default Recruit
