import { getItem, setItem } from '@/utils/storage'
import { getCountry, getWorkLang, queryDictItemByType } from '@/api/login'
// removeItem
const state = {
  countryList: getItem('countryList') || null,
  langList: getItem('langList') || [],
  monetaryUnit: getItem('monetaryUnit') || []
}

const mutations = {
  SET_COUNTRY: (state, data) => {
    state.countryList = data
    setItem('countryList', data)
  },
  SET_WORK_LANG: (state, data) => {
    state.langList = data
    setItem('langList', data)
  },
  SET_MONETARY_UNIT: (state, data) => {
    state.monetaryUnit = data
    setItem('monetaryUnit', data)
  }
}

const actions = {
  GetCountry({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      getCountry(userInfo)
        .then((res) => {
          commit('SET_COUNTRY', res.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  GetWorkLang({ commit }, info = {}) {
    return new Promise((resolve, reject) => {
      getWorkLang(info)
        .then((res) => {
          commit('SET_WORK_LANG', res.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMonetaryUnit({ commit }, info) {
    return new Promise((resolve, reject) => {
      queryDictItemByType(info)
        .then((res) => {
          commit('SET_MONETARY_UNIT', res.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
const base = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default base
