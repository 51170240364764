export default {
  team: {
    identity: {
      abroadAuth: {
        pleaseSelect: '请选择',
        pleaseEnter: '请输入',
        yourRole: '你的角色',
        yourRoleOption: ['非法人', '企业法人'],
        // --------------
        //         *
        perInfo: '个人信息',
        fullName: '姓名',
        docType: '证件类型',
        cerNo: '证件号',
        teamName: '工作室名称',
        entInfo: '企业信息',
        entName: '企业名称',
        entRegTime: '企业注册时间',
        regNum: '注册号',
        regNumOption: ['注册文件号'], //'税务登记号码' 之前有两个可选项
        regDocNo: '注册文件号',
        taxRegNum: '税务登记号码', // 此项不需要了
        entPerSize: '企业人员规模',
        conNum: '联系电话',
        email: '电子邮箱',
        letOfCom: '承诺书',
        uplLetOfCom: '上传承诺书',
        dowLetOfCom: '下载承诺书',
        entPowOfAtt: '企业授权书',
        uplAuth: '上传授权书',
        dowAuth: '下载授权书',
        // -------------
        otherInfo: '其他信息(选填)',
        mainBusScope: '所属行业',
        mainBusScopeOption: [
          {
            id: 1,
            label: '民航'
          }
        ],
        // mainBusScopeOption: [
        //   '民航',
        //   '动漫制作',
        //   '建筑设计与规划',
        //   '汽车',
        //   '航空航天',
        //   '生物技术',
        //   '土木工程',
        //   '计算机与网络安全',
        //   '计算机游戏',
        //   '计算机硬件',
        //   '计算机网络',
        //   '计算机软件',
        //   '线上学习',
        //   '电子与电气制造',
        //   '环保服务',
        //   '图形设计',
        //   '医院与医疗',
        //   '工业自动化',
        //   '工业设计',
        //   '信息服务',
        //   '信息技术与服务',
        //   '互联网',
        //   '机械仪器',
        //   '管理咨询',
        //   '海事',
        //   '市场调研',
        //   '营销与广告',
        //   '机械与工业工程',
        //   '医疗设备',
        //   '移动端游戏',
        //   '纳米技术',
        //   '原油与能源',
        //   '外包与离岸服务',
        //   '药物学',
        //   '塑料工业',
        //   '专业培训与辅导',
        //   '软件编程与开发',
        //   '铁路制造',
        //   '研究',
        //   '半导体',
        //   '造船',
        //   '电信',
        //   '翻译与本地化',
        //   '公路与铁路运输',
        //   '基础设施',
        //   '无线通信'
        // ],
        brandName: '品牌名称',
        entOffWeb: '企业官网',
        twitter: 'Twitter',
        facebook: 'Facebook',
        linkedIn: 'LinkedIn',
        companyLogo: '公司logo',
        uploadAvatar: '上传头像',
        determine: '确定',
        reset: '重置',
        personal: '个人',
        people: '人',
        upd: '上传',
        puyra: '请上传你的真实头像',
        updAva: '上传头像',
        result: {
          name: '姓名',
          docType: '证件类型',
          cerNo: '证件号',
          studio: '工作室名称',
          company: '公司名称',
          regTime: '企业注册时间',
          regDocNo: '注册文件号',
          reaInfo: '创建人信息',
          entInfo: '企业信息',
          btnText: '重新认证',
          failure: '查看未通过原因'
        }
      },
      cardType: [
        {
          id: '1',
          label: '社会保障号',
          type: 'SSN'
        },
        {
          id: '2',
          label: '身份证',
          type: 'ID Card'
        },
        {
          id: '3',
          label: '驾驶执照',
          type: 'Driving License'
        },
        {
          id: '4',
          label: '护照',
          type: 'Passport'
        }
      ]
    }
  }
}
