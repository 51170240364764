const getters = {
  permission_routes: (state) => state.permission.routes,
  linkRoutes: (state) => state.permission.linkRoutes,
  sidebar: (state) => state.app.sidebar,
  session: (state) => state.user.session,
  hasAuth: (state) => state.user.hasAuth,
  count: (state) => state.user.count,
  contractModules: (state) => state.contract.modules,
  contractPreviewModules: (state) => {
    const fn = (input, ret) => {
      input.forEach((item) => {
        ret.push(item)
        if (item.children && item.children.length > 0) {
          fn(item.children, ret)
        }
      })
    }
    const modules = []
    fn(state.contract.modules, modules)
    return modules
  },
  contractEditModule: (state) => state.contract.editModule,
  contractHeight: (state) => state.contract.height,
  contractScrollTop: (state) => state.contract.top,
  contractStyle: (state) => state.contract.style,
  lang: (state) => state.global.lang,
  countryList: (state) => state.base.countryList,
  langList: (state) => state.base.langList,
  monetaryUnit: (state) => state.base.monetaryUnit,
  dictInfo: (state) => state.dict.info
}
export default getters
