import zhIndex from './zh/index'
export default {
  lang: 'zh',
  ...zhIndex,
  message: '你好 i18n !!',
  // cookies模块
  cookies: {
    text: '我们和选定的第三方为技术目的使用cookies或类似技术，以加强网站导航，分析网站使用情况，协助我们的营销工作，并用于Cookie政策中规定的其他目的。',
    learnMore: '了解更多并定制',
    accept: '接受全部',
    title: '隐私偏好中心',
    des: '当您访问任何网站时，它可能在您的浏览器上存储或检索信息，主要是以cookies的形式。这些信息可能是关于您、您的偏好或您的设备的，主要用于使网站按照您的期望运行。这些信息通常不会直接识别您的身份，但它可以给您一个更个性化的网络体验。因为我们尊重您的隐私权，您可以选择不允许某些类型的cookies。点击不同类别的标题，以了解更多信息并改变我们的默认设置。然而，阻止某些类型的cookies可能会影响您对网站的体验和我们所能提供的服务。',
    moreInfo: '更多信息',
    manageConsentPreferences: '管理许可偏好',
    strictlyNecessaryCookies: '绝对必要',
    strictlyNecessaryCookiesText:
      '这些 Cookie 对于网站的运行是绝对必要的，且无法在我们的系统中关闭。 通常，只有在您做出近乎服务请求的行为（例如，设置您的隐私偏好、登录或填写表单）时才会设置这些 Cookie。 您可以将浏览器设置为阻止或提醒您注意这些 Cookie，但网站的某些部分可能会无法运行。',
    performanceCookies: '性能',
    performanceCookiesText:
      '这些 Cookie 使我们能够计算访问量和流量来源，以便我们评估和改进我们的网站性能。 这些 Cookie 可帮助我们了解哪些页面最受欢迎，哪些页面最不受欢迎，并了解访问者在网站上的浏览方式。 如果您不允许这些 Cookie，我们将不知道您何时访问过我们的网站，也无法监测网站性能。',
    functionalCookies: '功能',
    functionalCookiesText:
      '这些 Cookie 支持网站提供增强型功能和定制功能。 Cookie 可能由我们或由在我们的网页上添加服务的第三方供应商设置。 如果您不允许这些 Cookie，那么部分或所有的这些服务可能无法正常运行。',
    targetingCookies: '目标定位',
    targetingCookiesText:
      '我们的广告合作伙伴可以通过我们的站点设置这些Cookie。 这些 Cookie可供广告合作伙伴公司根据自有策略跟踪您使用我们网站的情况，并可将相应信息与其他信息相结合，然后在我们的站点和其他站点上向您显示相关广告。 如果您不允许使用这些 Cookie，您将不会在智研集智网站或产品上看到个性化广告。',
    alwaysActive: '始终处于活动状态',
    confirmMyChoice: '确认我的选择'
  },
  login: {
    middlePage: {
      text: '诚邀您的加入',
      hasAccount: '已有账号，去验证',
      noAccount: '没有账号，去注册'
    },
    addTeam: '新增工作室/企业',
    pleaseEnterName: '请填写工作室/企业名称!',
    changeRole: '切换角色',
    changeToDP: '切换成为DP',
    changeToHP: '切换成为HP',
    become: '申请成为',
    zhLang: '简体中文',
    enLang: '英文',
    loginView: {
      tabs: {
        chooseAirRnd: '为什么',
        findTalent: '找英才',
        findWork: '找任务',
        findItem: '找项目',
        language: '简体中文',
        login: '登录',
        register: '注册',
        logout: '退出登录',
        findFriends: '邀请好友',
        workbench: '工作台',
        changeTeam: '切换工作室/企业',
        publishProject: '发布项目'
      },
      lanList: {
        zh: '简体中文',
        en: 'English'
      },
      airRnd: {
        about: '关于我们',
        ustomers: '我们的客户'
      },
      title: '智研集智邀请您注册',
      title2: '成为雇佣伙伴或开发伙伴',
      emillText: '请输入邮箱号',
      emailOrMobile: '请输入邮箱号/手机号',
      // pswText: '请输入邀请码',
      countryTip: '请选择国家/地区',
      mobileText: '手机号',
      verifyBtnText: '发送验证码',
      verifyCodeText: '输入验证码',
      resend: '重新发送',
      docTypeText: '请选择证件类型',
      idNumberText: '请输入证件号码',
      uploadDocText: '上传证件',
      docTypeList: [
        { key: '1', label: '护照' },
        { key: '2', label: '其他国籍证明证件' }
      ],
      uploadTip: '请上传您的护照或其他证件',
      sampleText: '查看示例',
      exampleText: '护照示例',
      nextText: '下一步',
      accountTip: '已有一个账号？',
      loginText: '登录',
      // 第二步
      firstTip: '请输入姓',
      lastTip: '请输入名',
      passTip: '请输入密码',
      need: '您想要',
      teamTypeOne: '成为雇佣伙伴  发布任务',
      teamTypeTwo: '成为开发伙伴  接单赚钱',
      teamTip: '请选择工作室/企业类型',
      teamNameTip: '请输入工作室/企业名称',
      contractText: '我已阅读并同意智研集智的',
      contractTextAnd: '和',
      agreement: '用户协议',
      policy: '隐私政策',
      buttonText: '立即创建',
      tipText: '确认后无法更改',
      registTitle: '提示',
      registConfirm1: '请再次确认您将成为雇佣伙伴，确认后不可更改。',
      registConfirm2: '请再次确认您将成为开发伙伴，确认后不可更改。',
      registConfirmSubmitBtn: '确认',
      registConfirmCancelBtn: '我再想想'
    },
    register: {
      title: '距离注册完成还差一步',
      sendEmail: '激活账户邮件已发送到您的邮箱',
      address: '登录您的电子邮箱，完成账户激活。',
      changeAddr: '更改邮箱地址',
      emailInput: '请输入更改邮箱号',
      sendBtn: '更新并重新发送',
      resend: '重新发送验证邮件',
      successTitle: '恭喜您激活成功',
      successText: '即将跳转至工作台…',
      errorTitle: '邮件已失效，请重新激活',
      errorBtn: '再次发送激活邮件',
      teamUnit: '的工作室'
    },
    registerAudit: {
      auditStatus: '身份审核中',
      auditTip: '我们将在1-3个工作日内通知您，请耐心等待',
      auditFailText: '审核失效，请重新上传',
      uploadBtnText: '重新上传证件'
    },
    registerFail: {
      title: '温馨提示',
      tip: '根据中国法律，非中国大陆手机用户，需上传护照或其他身份证件进行实名审核。智研集智团队不会将您的身份证件用作其他用途。',
      idType: '证件类型',
      idNumber: '证件号码',
      idFile: '证件',
      idFilePText: '请上传证件'
    },
    loginfo: {
      title: '欢迎登录',
      rememberPass: '记住密码',
      forgetPass: '忘记密码',
      loginBtn: '登录',
      noAccount: '没有账号？',
      goRegister: '现在注册'
    },
    teamErrTip: '获取团队失败, 请刷新当前页面',
    setpOne: {
      type: '您的工作室想要接单的类型是什么',
      project: '您的背景、专业知识和经验信息将会极大提高赢得工作任务的机会',
      selectTip: '请选择'
    },
    setpTwo: {
      title: '您的团队或您擅长哪些技能？',
      text: '与项目相关的技能会使您的简历脱颖而出',
      selectTip: '请选择技能类型'
    },
    setpThree: {
      title: '描述下您的工作室做过的项目',
      text: '尽可能多的项目经历能够为您精准匹配到符合 您要求的项目',
      addTeamTitle: '添加项目经验',
      editTeamTitle: '修改项目经验',
      teamName: '项目名称',
      teamNameTip: '请输入项目名称',
      teamDesc: '项目描述',
      teamDescTip: '请输入项目描述',
      uploadImg: '上传图片',
      upTips: '最多8张(支持png、jpg、jpeg、gif格式的图片，单张不大于2M。)',
      isDel: '确定删除此项吗？'
    },
    setpFour: {
      title: '描述下您的团队或者您自己',
      text: '描述下团队或者您的优势以及经验，是简历的一大加分项',
      entryTip: '请输入团队或您个人的简介'
    },
    setpFive: {
      title: '您支持什么工作语言？',
      text: '我们为您匹配精通该语言的雇佣伙伴（HP）',
      selectTip: '请选择工作语言'
    },
    setpSix: {
      title: '上传团队LOGO ，开启您在AirRnd的旅程吧！',
      tips: '上传一张图片,支持png、jpg、jpeg、gif格式的图片，且不大于2M'
    },
    // 忘记密码
    forget: {
      title: '更新您的密码',
      text: '输入您的电子邮件地址，然后选择发送电子邮件',
      emailTip: '请输入邮箱号',
      sendEmail: '发送邮件',
      overTitle: '邮件已发送',
      text1: '我们已向您的电子邮件发送了一封邮件。',
      text2: '请按照步骤更新您的密码。',
      text3: '如果不想修改密码，请选择登录',
      rSend: '重新发送',
      lSend: '立即登录',
      changeMsgTitle: '更新您的密码',
      newMsg: '新密码',
      restNewMsg: '再次输入密码',
      passErrTip: '两次输入密码不一致',
      oldPassword: '旧密码',
      oldPasswordTip: '请输入6-18位英文和数字组合的旧密码',
      newPasswordTip: '请输入6-18位英文和数字组合的新密码',
      restPasswordTip: '请再次输入6-18位英文和数字组合的新密码'
    },
    pass: '请输入6-18位英文和数字组合的密码'
  },
  logout: {
    successTip: '退出成功',
    confirmTip: '许可凭证过期，点击取消，停留在当前页面，或者点击重新登录。',
    confirmTitle: '确定登出',
    cancelBtn: '取消',
    submitBtn: '重新登录'
  },
  logoutSuccess: '退出成功',
  // 权限提示
  permis: {
    toActivate: '请先激活账户后使用此功能哦!',
    toCert: '请进行身份认证并审核通过后，才使用此功能!',
    inCert: '您的认证申请正在审核中，请耐心等待。审核通过后，才可使用此功能',
    toRegister:
      '请登录您的电子邮箱，完成账户激活后查看详情!  如未收到邮件或需更改邮箱地址请点击 确定 按钮后操作!'
  },
  // 发布项目需求
  publishProject: {
    indexView: {
      cooperationCycleList: {
        0: '0-4周',
        1: '1-2个月',
        2: '3-5个月',
        3: '6个月-1年',
        4: '1年以上'
      },
      averageWorkweekList: {
        0: '8小时以下',
        1: '9-24小时',
        2: '25-32小时',
        3: '33-40小时',
        4: '40小时以上'
      },
      rateGearList: {
        0: '30以下',
        1: '31-50',
        2: '51-80',
        3: '81-110',
        4: '111-140',
        5: '141-170',
        6: '170以上'
      },
      publishItemType: '您要发布什么类型的项目？',
      fixedPriceContract: '固定总价合同项目',
      laborRateContract: '工时费率合同项目',
      hopeCooperationTime: '您希望与他合作多久',
      averageWorkingHoursPerWeek: '平均每周工作几个小时',
      expectedHourlyRate: '期望的小时费率是',
      title: '发布项目需求',
      desc: '完整的项目描述可以吸引到更优质的人才接单',
      typeLabel: '您的项目属于什么类型？',
      typePlaceholder: '请选择项目类型',
      titleLabel: '为您的项目取个标题',
      titlePlaceholder: '请填写项目标题',
      descLabel: '详细描述下项目信息',
      uploadBtnText: '点击上传',
      uploadType: '支持以下格式：jpg、jpge、png、pdf、doc、docx',
      uploadLimitText:
        '支持以下格式：jpg、jpge、png、pdf、doc、docx，图片大小在2M以内,文件大小在10M以内',
      uploadSize: '上传图片大小在2M以内, 文件大小在10M以内!',
      skillsText: '需要什么技能？',
      skillPlaceholder: '请输入关键词',
      recommendSkillText: '以下技能可能适合您的项目',
      deliveryTimeText: '您希望服务或者合作的周期是多久？',
      deliveryTimePlaceholder: '请输入完成的时间',
      deliveryUnitPlaceholder: '请选择',
      skillTips: '请选择技能',
      timeTips: '请输入完成时间',
      deliveryUnit: {
        label1: '日',
        label2: '周',
        label3: '月',
        label4: '年'
      },
      tipText: '仅供提供服务方参考，具体时间见合同约定',
      budgetText: '您对这个项目的预算有多少？',
      budgetTypeObj: {
        label0: '人民币',
        label1: '美元',
        label2: '欧元'
      },
      budgetRangeObj: {
        label1: '10,000以内',
        label2: '10,000 到 50,000',
        label3: '50,000 到 500,000',
        label4: '500,000 到 1,000,000',
        label5: '1,000,000以上'
      },
      lowBudget: '最低预算',
      highBudget: '最高预算',
      customPlaceholderText: '输入金额',
      templateText: '插入模板',
      nextBtnText: '下一步',
      publishPrjBtnText: '发布项目',
      // 插入模板弹窗
      templateDialog: {
        title: '插入模板',
        sureBtnText: '确定',
        cancelBtnText: '取消'
      }
    },
    noModel: '暂时没有模板哦'
  },
  // 富文本组件
  richEditer: {
    headerList: {
      label1: '正文',
      label2: '标题1',
      label3: '标题2',
      label4: '标题3'
    },
    fontStyleList: {
      bold: '加粗',
      italic: '倾斜',
      underline: '下划线',
      strike: '删除线'
    },
    colorsList: {
      color: '文字颜色',
      background: '背景颜色'
    },
    list: {
      bullet: '无序列表',
      ordered: '有序列表'
    },
    alignList: {
      justify: '两端对齐',
      left: '左对齐',
      center: '居中对齐',
      right: '右对齐'
    }
  },
  // 菜单栏
  menuBar: {
    workbench: '工作台',
    project: '项目', // 新增加 => 此处为修改
    publishProject: '发布项目',
    myProject: '发布状态',
    deal: '交易',
    myTender: '招标审核',
    projectTender: '我的投标',
    myOrder: '我的合同',
    myEvaluate: '我的评价',
    myBill: '我的账单',
    myReward: '我的报酬',
    myProjects: '我的项目', // 新增加
    team: '工作室',
    team2: '企业',
    teamMember: '工作室成员',
    teamMember2: '企业成员',
    teamInfo: '工作室信息',
    teamInfo2: '企业信息',
    identity: '身份认证',
    upgrade: '立即升级', //新修改
    setting: '其他',
    cloud: '我的网盘', // 新修改
    personal: '个人设置',
    myHomePage: '工作室主页',
    // security: '密码与安全',
    security: '修改密码',
    invite: '邀请好友',
    letter: '站内信',
    questions: '智研问答'
  },
  btns: {
    notSubmitted: '未提交',
    previous: '上一步',
    next: '下一步',
    sure: '确 定',
    cancel: '取 消',
    uploadText: '上传',
    submit: '确认提交',
    resubmit: '再次提交',
    sureBtnText: '确认',
    revise: '确认修改',
    know: '我知道了',
    save: '保存',
    complete: '完成',
    toTrial: '送审',
    copy: '复制',
    addSure: '确认加入',
    sendMail: '发送邮件',
    addFriend: '邀请成员',
    download: '下载',
    uploadFile: '上传文件',
    newLink: '添加链接',
    newFolder: '新建文件夹',
    deleteText: '删除',
    contactMe: '联系我',
    please: '请输入',
    useMode: '使用模板',
    editText: '编辑',
    cancelRelease: '取消发布',
    shelve: '上架',
    unshelve: '下架',
    publish: '发布',
    toAudit: '提交审核',
    fold: '收起',
    unfold: '展开',
    hasIntent: '高意向',
    releaseProj: '发布项目',
    viewDetail: '查看详情',
    turnDown: '驳回',
    pass: '通过',
    dollar: '美元',
    rmb: '元',
    euro: '欧元',
    newContract: '新建合同',
    cancelled: '已取消',
    toSigne: '待签署',
    inSigne: '待双方签署中',
    toConfirm: '待确认',
    void: '作废',
    hasVoid: '已作废',
    effective: '已生效',
    acceptance: '验收',
    inConfirm: '已确认',
    toCert: '去认证',
    toComp: '去完善',
    acceptSure: '确认验收',
    confirmDelivery: '确认交付',
    terminate: '终止合作',
    terminateConfirm: '同意终止合作',
    terminateNo: '驳回终止合作',
    signe: '发起签署',
    deliver: '交付',
    btnBack: '返回',
    addProject: '新增项目经历',
    showDetail: '查看详情',
    send: '发送',
    linkGroup: '链接进团',
    addEmail: '添加邮箱',
    copyLink: '复制链接',
    addTemplate: '添加模板',
    preview: '预览',
    viewComments: '查看评论', // 新增加
    toActivate: '去激活', // 新增加
    checkDp: '招标审核', // 新增加
    saveDraf: '保存草稿', // 新增加
    cancellation: '注销账号',
    revocation: '撤销注销申请'
  },
  headTips: {
    certTip: '请进行身份认证并审核通过后，才使用此功能!',
    noData: '暂无数据',
    noCompleteTip: '您的个人资料完善度只有{0}%, 建议您进一步完善'
  },
  // 身份认证
  identity: {
    certTip: '身份认证通过后不可更改',
    chooseTypeText: '请选择认证方式',
    personalText: '工作室认证-自由职业者',
    firmText: '企业认证',
    authTips: '您还不是管理员，请管理员进行身份认证操作。',
    domestic: {
      stepOneText: '办理人认证',
      stepTwoText: '组织信息核验',
      tip: '请把必要项填写完毕', // 新增加
      // stepThreeText: '授权书认证',
      stepFourText: '完成',
      stepOne: {
        tip: '填写的信息仅用于完成本次认证，平台方不会留存或泄露，请放心填写',
        typeText: '认证方式',
        type: '人脸识别',
        desc_1: '1、仅支持大陆人士',
        desc_2: '2、填写中国大陆居民身份证姓名、证件号',
        name: '姓名',
        agentName: '办理人姓名',
        namePlaceholder: '请输入姓名',
        sfz: '身份证号',
        agentSfz: '办理人身份证号',
        sfzPlaceholder: '请输入身份证号',
        mobile: '手机号',
        agentMobile: '办理人手机号',
        mobilePlacehoder: '请输入手机号',
        workName: '工作室名称',
        workPlaceholder: '请输入工作室名称',
        headImg: '上传头像',
        headImgTip: '请上传您的真实头像',
        imgTip: '一张.png / .jpg / .jpeg且不大于2M的头像',
        qrCodeBtn: '获取二维码'
      },
      stepTwo: {
        organTypeText: '组织类型',
        organList: ['其他类型企业', '个体工商户'],
        // authMethodText: '认证方式',
        // authMethodList: ['法定代表人认证', '授权书认证'],
        // authMethodDesc: {
        //   desc_1: [
        //     '1、当前操作人账号需要法定代表人所有',
        //     '2、需填写法定代表人基本信息，并与办理人信息相同即可完成认证'
        //   ],
        //   desc_2: [
        //     '1、若贵司实名需法定代表人先进行授权，方可代理授权，建议选择该方式',
        //     '2、需填写法定代表人手机号并发送签署通知，法定代表人收到后对授权书进行签署'
        //   ]
        // },
        sample: '示例',
        firmNameText: '企业名称',
        firmPlaceholder: '请输入企业名称',
        codeText: ['统一社会信用代码', '或工商注册号'],
        codePlaceholder: '请输入统一社会信用代码或工商注册号',
        operateText: '法定代表人名称',
        operatePlaceholder: '请输入法定代表人名称',
        idType: '法定代表人证件类型',
        idNumber: '法定代表人证件号',
        idNumberPlaceholder: '请输入法定代表人证件号',
        tipText: '认证不成功？',
        ID: '身份证'
      },
      dialog: {
        title: '扫码认证',
        type: '方式',
        zfb: '支付宝',
        txy: '腾讯云',
        qrCodeText: '请使用支付宝或微信扫描二维码，并依照提示完成人脸识别',
        exampleTitle: '示例图片',
        tipText: '提示',
        tipDesc: [
          '以下场景无法支持：',
          '非大陆注册企业无法支持',
          '少数数据源未覆盖到的社会组织、律所、个体工商户暂不支持'
        ]
      },
      result: {
        name: '姓名',
        ID: '身份证号',
        phone: '手机号',
        orgType: '组织类型',
        company: '企业名称',
        businessNumber: '统一社会信用代码 或工商注册号',
        legal: '法定代表人',
        legalID: '法人证件号',
        btnText: '重新认证',
        studio: '工作室名称',
        tName: '办理人姓名',
        tTD: '办理人身份证号',
        tPhone: '办理人手机号',
        failure: '查看未通过原因',
        per: '工作室认证',
        firm: '企业认证',
        status1: '工作室认证审核中',
        status2: '工作室已认证',
        status3: '工作室认证审核未通过',
        firmStatus1: '审核中',
        firmStatus2: '企业已认证',
        firmStatus3: '企业认证未通过',
        status: '认证状态'
      },
      faceCode: {
        title:
          '扫描二维码后，将有以下四种企业认证方式供您选择，您可根据情况选择最便利的方式：',
        legal: '法定代表人认证：',
        legalText: '如果您是法定代表人本人，建议使用本方式',
        payment: '对公打款认证：',
        paymentTitle:
          '需要操作企业对公账户打款，如果您比较容易实现企业对公账户打款，建议使用本方式',
        alipay: '企业支付宝认证（仅通过支付宝扫码认证时出现）：',
        alipayText:
          '需要企业支付宝账号，如果您已拥有企业支付宝账号，建议使用本方式',
        auth: '授权书认证：',
        authText:
          '需要法定代表人手机短信授权操作，如果您拥有企业法定代表人的手机号并能较方便联系上他/她，建议使用本方式'
      }
    },
    foreign: {
      name: '请输入名称',
      docType: '请选择证件类型',
      cerNo: '请输入证件号',
      cerNomLong: '长度在 3 到 18 个字符',
      regNum: '请输入选择注册号',
      regDocNo: '请输入注册文件号',
      teamName: '请输入工作室名称',
      uploadAvatar: '请上传头像',
      read: '我已阅读并同意智研集智的',
      book: '入驻协议',
      // 企业认证
      role: '请选择选择角色',
      entName: '请填写企业名称',
      onePeople: '个人',
      people: '人',
      companyName: '企业名称',
      entRegTime: '请输入选择企业注册时间',
      taxRegNum: '请输入税务登记号',
      entPerSize: '请选择企业人员规模',
      conNum: '手机号不能为空',
      email: '请输入电子邮箱',
      letOfCom: '请上传承诺书',
      entPowOfAtt: '请上传企业授权书',
      companyLogo: '请上传公司Logo',
      Letter: '承诺书',
      upgrade: '升级为企业认证'
    }
  },
  // 团队信息
  teamInfo: {
    title: '基本信息',
    teamName: '工作室名称',
    firmName: '企业名称',
    website: '官网',
    brandName: '品牌名称',
    englishName: '英文名称',
    peopleNum: '人员规模',
    phone: '联系电话',
    email: '电子邮箱',
    please: '请输入',
    address: '联系地址'
  },
  teamMember: {
    bar: '工作室成员',
    firmBar: '企业成员',
    textOne: '当前已有',
    textTwo: '名成员，您还可以邀请',
    textThree: ' 名成员',
    textFour: '以实际进入团队的数量算。', // 新增加
    roles: '角色',
    superAdmin: '超级管理员',
    admin: '管理员',
    tabOne: '链接邀请',
    tabTwo: '邮箱邀请',
    members: '成员',
    lastOperation: '最后操作',
    inviter: '邀请人',
    selectProject: '选择参与项目',
    checked: '已选',
    allProject: '全部项目',
    joinProject: '加入项目',
    operate: '操作',
    InviteFriends: {},
    copySuccess: '链接复制成功',
    copyError: '复制失败',
    emailPlaceholder: '请填写邮箱地址',
    tip: '链接有效期30天',
    contactHim: '联系他'
  },

  // 网盘
  inlineDisk: {
    volume: {
      text_1: '网盘容量已使用',
      text_2: '剩余'
    },
    steps: ['全部文件', '合同号', '阶段', '阶段详情'],
    cols: ['文件名', '大小', '修改时间', '发布人'],
    emptyText: '暂无文件',
    more: '更多',
    forbid: '禁止删除',
    dialog: {
      title: '添加链接',
      name: '文件名',
      link: '链接',
      nameMsg: '请输入文件名',
      linkMsg: '请输入链接'
    },
    folderNameMap: {
      prePay: '预付款',
      finalPay: '尾款',
      payStep: '阶段'
    }
  },
  // 个人设置
  personal: {
    title: '个人信息',
    name: '姓名',
    country: '国家或地区',
    mobile: '手机号',
    mobilePlaceholder: '请输入手机号',
    codePlaceholder: '请输入验证码',
    verifyBtn: '发送验证码',
    verifyBtn_1: '后重新发送',
    tips: '头像格式: 只能上传一张png/jpg/jpeg图片, 且不超过2M',
    noPerfect: '待完善',
    bindPhone: '绑定手机号',
    changeMobile: '切换手机号',
    sample: '示例: +86 15931234560 (地区号 手机号)',
    checkMobileTip: '请输入正确的手机号',
    applyCancelDialog: {
      title: '申请账号注销',
      tips: [
        '您提交的注销申请生效前，智研集智团队将进行以下验证，以保证您的账号、财产安全。',
        '经过团队验证，如无上述问题，系统将在15天内将您的账号注销。'
      ],
      process: {
        first: {
          title: '1.账号处于安全状态',
          content:
            '在最近两周内，您没有进行改密、改绑等敏感操作，您的账号没有被盗、被封等风险。'
        },
        second: {
          title: '2.平台项目结清',
          content:
            '您的账号内所有项目与合同已结清，没有以及进入合同谈判阶段或合同执行阶段的项目。'
        },
        third: {
          title: '3.平台支付财产结清',
          content: '您的账号内所有合同的支付或待支付款项已结清。'
        },
        fourth: {
          title: '4.平台权限解除',
          content:
            '该平台账号关联的企业管理员身份解除，转移；如您是企业在平台的唯一账号，则该企业账号也将同步注销。'
        }
      },
      warm_tips: {
        title: '温馨提示',
        content: [
          '1. 如系统提示【注销无法完成】，请按上述提示排除相关原因，重新发起注销申请。',
          '2. 注销后账号内所有数据都将被清空，且无法恢复，请谨慎操作。',
          '3. 注销申请提交后，有15天反悔期。反悔期内，您可登录平台，点击“撤销注销申请”，注销将被终止。'
        ]
      }
    },
    withdrawText: '您正在申请注销账号，在此期间，不能做任何其他操作'
  },
  // 密码与安全
  security: {
    // title: '密码与安全',
    title: '修改密码',
    mail: '邮箱号',
    updateBtn: '修改密码',
    dialog: {
      tab_1: '邮件修改密码',
      tab_2: '手机号修改密码',
      mobile: '手机号',
      verifyCode: '验证码',
      verifyCodeMsg: '请输入验证码',
      pass: '新密码',
      passMsg: '请输入6-13位数字组成的密码',
      checkPass: '重复密码',
      checkPassMsg: '两次输入密码不一致'
    }
  },
  // 设置 邀请好友 站内信
  setInvite: {
    title: '邀请好友',
    text: '您可以复制以下内容邀请好友注册',
    infoTitle: '邀请您注册「智研集智」',
    addr: '注册地址: ',
    code: '邀请码: ',
    copySuccess: '邀请链接已复制到剪切板',
    copyError: '复制失败',
    areaCode: '请输入正确的手机区域号',
    coypInEnglish: '复制成英文'
  },
  // HP工作室简介
  hpHomepage: {
    work: '工作室简介',
    website: '企业官网',
    email: '电子邮箱',
    phone: '联系电话',
    projectList: '已发项目列表',
    evaluate: '评价' // 新增加
  },
  letterTitle: '站内信',
  read: '已读',
  unread: '未读',
  // 合同
  contract: {
    design: {
      name: '合同模块',
      module: {
        base: {
          name: '基本信息',
          partA: '甲方信息',
          partB: '乙方信息',
          form: {
            name: '名称',
            addr: '地址',
            legal: '法定代表人姓名',
            code: '社会统一信用代码',
            idcard: '法人身份证'
          }
        },
        srvAndCost: {
          name: '服务内容及费用',
          partA: '开发项目内容',
          partB: '价格及付款方式',
          partC: '发票',
          form: {
            projectName: '开发项目名称',
            totalCost: '合同总金额',
            cost: '费用',
            prePay: '预付款',
            finalPay: '尾款',
            corpName: '公司名称',
            code: '纳税人识别号',
            addr: '地址',
            mobile: '电话',
            openBank: '开户行',
            acct: '账号',
            desc1: '日内支付',
            desc2: '阶段',
            errTip: '费用比例计算错误期望: {0}%, 当前: {1}%'
          }
        },
        ownership: {
          name: '所有权及知识产权归属',
          partA: '独家开发',
          form: {
            num: '独家开发年限'
          }
        },
        change: {
          name: '变更与解除',
          partA: '合同变更与解除',
          form: {
            num: '变更需求答复时间',
            numPrefix: '收到通知后',
            numSuffix: '工作日内'
          }
        },
        contact: {
          name: '联系方式',
          partA: '甲方联系人',
          partB: '乙方联系人',
          form: {
            name: '联系人姓名',
            addr: '地址',
            mobile: '手机',
            wechat: '微信',
            email: '电子邮件'
          }
        },
        promise: {
          name: '承诺与保证',
          partA: '违约责任',
          form: {
            userA: '甲方违约',
            userAnum1: '甲方逾期，每逾期一天，应支付逾期金额',
            userAnum2: '甲方违约，赔付合同金额',
            userB: '乙方违约',
            userBnum1: '乙方逾期，每逾期一天，应支付逾期金额',
            userBnum2: '乙方违约，赔付合同金额'
          }
        },
        conflict: {
          name: '争议处理',
          partA: '争议解决',
          form: {
            addr: '起诉地址',
            addrPlaceholder: '协商不成向有管辖权的人民法院起诉'
          }
        },
        attachment: {
          name: '附则',
          partA: '附则',
          form: {
            name: '附件名称'
          }
        },
        sign: {
          name: '签名',
          partA: '签名',
          form: {
            signDate: '签订日期'
          }
        },
        terms: {
          name: '格式条款',
          form: {
            content: '条款内容'
          }
        },
        cooperate: {
          name: '委托方配合',
          partA: '提供技术资料时间',
          form: {
            num: '提供技术资料时间',
            numPrefix: '合同签订后',
            numSuffix: '个工作日'
          }
        },
        accept: {
          name: '验收标准',
          form: {
            criteria: '验收标准',
            method: '验收方式',
            time: '验收时间',
            timeSuffix: '内完成验收'
          }
        },
        train: {
          name: '配套技术服务于技术培训',
          form: {
            time: '培训时间',
            cost: '费用承担',
            num: '培训次数',
            hour: '每次时长'
          }
        },
        warranty: {
          name: '质保期',
          form: {
            warranty: '质保期',
            penalty: '违约金',
            overdue: '逾期天数'
          }
        },
        section: {
          form: {
            title: '标题',
            pos: '摆放位置',
            fontSize: '文字大小'
          }
        },
        group: {
          form: {
            title: '标题',
            pos: '摆放位置',
            fontSize: '文字大小',
            show: '是否显示'
          }
        },
        cost: {
          name: '价格及付款方式',
          partA: '价格及付款方式',
          form: {
            totalCost: '合同总金额',
            cost: '费用',
            prePay: '预付款',
            finalPay: '尾款',
            desc1: '日内支付',
            desc2: '阶段',
            errTip: '费用比例计算错误期望: {0}%, 当前: {1}%',
            minimumValEachPaymentErr: '每笔款项的最小值不能低于200,请重新填入'
          }
        }
      },
      status_0: '未完成',
      status_1: '已完成',
      promptTitle: '修改标题',
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      tempBtnText: '暂存',
      saveBtnText: '保存',
      generateContract: '生成合同',
      pdfBtnText: '下载PDF',
      notEmpty: '不能为空',
      fontFamily: {
        SimSun: '宋体',
        SimHei: '黑体',
        YaHei: '微软雅黑'
      },
      fontFamilyTitle: '字体',
      fontSizeTitle: '字号',
      undone: '合同内容未全部完成'
    },
    task: {
      title: '分解任务',
      workTitle: '工时费率关键条款',

      groupTitlePrefix: '阶段',
      form: {
        hourlyRate: '每小时费率',
        onlyHourlyRate: '小时费率',
        estimatedWorkingCycle: '预计工作周期',
        estimatedWeekWorkingHours: '预计每周工作时长',
        projectName: '项目名称',
        totalCost: '合同总金额',
        step: '交付阶段',
        desc: '交付物说明',
        commitTime: '交付时间',
        pct: '比例',
        maxAcceptTime: '最长验收时间',
        dpTeamId: 'DP团队'
      }
    },
    tpl: {
      title: '合同模板',
      btn: '使用模板',
      personal: '个人', // 新增
      company: '企业' // 新增
    },
    btnBack: '返回',
    btnNext: '下一步',
    btnPrev: '上一步',
    create: {}
  },
  // 评价
  myEvaluate: {
    tabOne: '收到的评价',
    tabTwo: '给他人的评价'
  },

  // 我的项目 整合(新增加)
  myProjects: {
    project: '发布列表',
    order: '项目列表',
    tender: '我的投标'
  },
  // 我的项目
  myProject: {
    // 项目列表
    projectList: {
      // 项目工时
      projectHours: {
        confirmedWorkTime: '已确认工时',
        unConfirmedNumTimeCards: '未确认工时卡数',
        date: '日期',
        workingHours: '工作时长(小时)',
        workingDescribe: '工作描述',
        timeConfirmation: '工时确认',
        subtotalWeek: '一周小计',
        placeholderSelectWeek: '选择周',
        pleaseSelectContract: '请选择合同',
        confirmWorkingHours: '确认该工时？',
        enterDescription: '输入工作描述',
        operationSuccess: '操作成功',
        objectionTips: '您可以详细说明对帐单的疑问，并发给DP（站内信与邮件）',
        haveObjections: '有异议',
        haveObjectionsTips: '点击后可输入您对帐单的疑问，并发送给DP',
        placeholderEnterReason: '请填写理由'
      }
    },
    radios: {
      allStatus: '全部状态',
      noAudit: '未审核',
      audit: '审核中',
      error: '审核失败',
      success: '审核成功',
      release: '已发布',
      unshelve: '已下架',
      draft: '草稿'
    },
    publishTip: '平台审核通过后发布', // 新增加
    errorReason: '失败原因' // 新增加
  },
  // 工作室信息 整合(新增加)
  studioTabs: {
    base: '基本信息',
    homePage: '工作室主页',
    companyPage: '企业主页',
    cert: '身份认证',
    invoice: '发票信息'
  },
  // 发票信息
  invoice: {
    english: '英语',
    companyName: '公司名称',
    taxNumber: '纳税人识别号',
    address: '地址',
    tel: '电话',
    bank: '开户行',
    account: '账号',
    required: '请填写内容'
  },
  // 我的账单
  bill: {
    billText: '已支出金额共计',
    received: '已收到金额共计',
    unit: '元',
    payNum: '交易编号',
    amount: '交易金额',
    accountReceivable: '应到账金额',
    payee: '收款人',
    payTime: '支付时间',
    project: '关联项目',
    status: '状态',
    Arrival: '已到账',
    noArrival: '待到账',
    hpArrival: '已支付',
    hpNoArrival: '待支付',
    partAccount: '部分到账',
    hpPartAccount: '部分支付',
    refund: '已退款',
    totalContract: '总计合约'
  },
  // 我的报酬
  reward: {
    needCard: '您还未开户',
    toAddCard: '立即开户',
    addCard: '添加银行卡',
    editCard: '修改银行卡',
    please: '请输入',
    bankName: '开户银行',
    subBranch: '支行名称',
    accountName: '账户名',
    cardName: '账号/卡号',

    swiftCode: 'SWIFT CODE',
    bankAccount: '银行账号',
    bankCode: '开户行代码',
    bankAddress: '开户行地址'
  },

  talent: {
    title: '英才列表',
    noActiveTip: '您还未激活账户,请先激活哦!'
  },
  // 我的订单
  order: {
    task: '分解任务',
    delivery: '交付阶段',
    phase: '阶段',
    desc: '交付说明',
    overTime: '完成时间',
    data: '选择日期',
    descHolder: '请填写交付说明',
    count: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
    contract: '合同模板',
    company: '委托技术开发合同模版(企业)',
    personal: '委托技术开发合同模版(个人)',
    // 订单列表
    orderList: {
      allStatus: '全部状态', // 新增加
      tabOne: '待签合同',
      tabTwo: '执行中',
      tabThree: '待验收',
      // tabFour: '已验收',
      tabFive: '已完成',
      tabSix: '已终止',
      empty: '暂无任何订单',
      timeText: '约定交付时间：',
      noCurrently: '您暂无参与的项目'
    },
    // 订单详情
    orderDetail: {
      hour: '小时',
      scoreHolder: '请输入评分',
      comHolder: '请输入评语',
      contract: {
        confirmTip: '是否已确认合同文本（左侧图片中）？',
        esign: '确认要使用电子合同吗?',
        radioOne: '不使用',
        radioTwo: '使用',
        noConstract: '暂无合同',
        hpPendingSign: '待HP签署',
        hpSign: 'HP已签署',
        dpPendingSign: '待DP签署',
        dpSign: 'DP已签署'
      },
      file: {
        projectInfo: '项目资料',
        deliveryDoc: '交付文件',
        contractId: '关联合同编号',
        delivered: '待交付',
        inDelivered: '已交付',
        forAcceptance: '待验收',
        toPay: '待支付',
        havePay: '已支付',
        deliverConfirm: '确定要交付吗?',
        projectTip: '这里存放双方交流的项目资料', // 新增加
        fileTip: '这里存放项目交付物' // 新增加
      },
      cost: {
        associatedNum: '关联合同编号',
        phase: '阶段',
        price: '金额',
        status: '状态',
        payTime: '支付时间',
        operator: '操作人',
        operation: '操作',
        payText: '支付',
        acceptance: '验收',
        amountPaid: '已支付金额',
        receivedAmount: '已收到金额',
        unpaidAmount: '未支付金额',
        unpaidAmAmountNotReceivedount: '未收到金额',
        agreedRate: '约定费率',
        timeTnterval: '时段',
        subtotalWorkingHours: '工时小计',
        oneClickPayBtn: '一并支付',
        oneClickMsg: '暂无可支付的订单',
        existBillText:
          '您有已合并的账单，还未支付成功，请先支付之前的账单，才能生成新的一并支付账单',
        payTogetherText:
          '一并支付将合并当前所有未支付账单成一份账单，确定合并吗？'
      },
      acptText: '确定验收吗?',
      noAcptText: '确定驳回吗?',
      constractCode: '合同编号',
      submitText: '确认交付吗?',
      reason: '请输入原因',
      cancelSure: '确定取消合同吗?',
      signSure: '确定发起签署合同吗 ? ',
      resetSend: '确定重新发起签署合同吗?', // 新增加
      toInitiate: '重新发起签署', // 新增加
      signed: '签署',
      prePay: '预付款',
      finalPay: '尾款',
      payStep: '阶段',
      tab1: '项目合同', // 新修改
      tab2: '项目交付', // 新修改
      tab3: '项目费用', // 新修改
      tab4: '项目评价',
      tab5: '项目成员', // 新增加
      projectHours: '项目工时',
      over: '确定完成吗?',
      terminationSure: '确定终止合作吗?',
      addMember: '添加成员',
      intoChart: '进入群聊'
    },
    // 订单状态
    orderStatus: {
      payOver: '已支付',
      inPayment: '支付中',
      noPay: '待支付',
      acceptance: '已验收',
      noAcceptance: '待验收',
      complete: '已完成',
      inDelivery: '已交付',
      noDelivery: '未交付',

      progress: '进行中',
      contract: '待签合同',
      completed: '已完成',
      cancelled: '已取消',
      projOver: '完成项目'
    },
    // 评论
    comment: {
      comment: '评论',
      score: '评分',
      enterRating: '请评分哦',
      remark: '评语',
      enerRemark: '请输入评语',
      comList: '评论列表',
      noEvaluation: '暂无评价', // 新增加
      toEvaluate: '去评价', // 新增加
      // HP的列表
      hpList: [
        '付款及时',
        '沟通顺畅',
        '通情达理',
        '需求明确',
        '反应及时',
        '付款拖沓',
        '沟通不顺',
        '不通人情',
        '需求模糊',
        '反应缓慢'
      ],
      dpList: [
        '按时交付',
        '保质保量交付',
        '态度好',
        '容易沟通',
        '专业能力强',
        '技能优异',
        '经验丰富',
        '思维敏捷',
        '有洞察力',
        '交付延误',
        '交付不达标',
        '态度恶劣',
        '沟通不畅',
        '专业能力弱',
        '技能缺乏',
        '经验不足',
        '反应缓慢',
        '缺乏洞见'
      ]
    }
  },
  // 招标审核
  tender: {
    tabAll: '全部DP',
    tabIntent: '高意向DP',
    bar: '招标审核',
    orderType: '接单类型',
    seeMore: '查看更多',
    bidding: '招标中',
    dpInfo: 'DP信息',
    isTrue: '确定驳回吗?',
    reasons: '申请理由',
    confirm: '确认审核通过?',
    radioOne: '通过，合同由DP发起',
    radioTwo: '通过，合同由HP发起',
    consider: '我再想想',
    under: 'HP评估中',
    inBidding: '竞标中',
    success: '竞标成功',
    error: '竞标失败',
    failedPass: 'HP评估未通过',
    rejected: '驳回',
    empty: '暂无任何招标审核',
    dpEmpty: '暂无投标审核数据',
    reason: '未通过原因'
  },
  //工作台
  workbench: {
    dp: {
      title_bidding: '竞标中',
      restTime: '剩余时间'
    },
    hp: {
      title: '待审核',
      tenderReview: '招标审核',
      title_contract: '待签合同',
      title_1: '执行中',
      orderType: '接单类型',
      view: '查看',
      more: '更多',
      pay: '已打款',
      deliveryTime: '交付时间',
      progress: '执行中'
    }
  },
  // 首页
  home: {
    airAge: {
      title: '产品研发与数智化工具',
      text: '同时，智研汇提供面向工业装备研发的全生命周期产品与项目研发管理工具，为企业赋能提效',
      more: '了解更多'
    },
    findWork: {
      tip: '对开发伙伴',
      title: '自由受雇，提升人效',
      title2: '',
      text: '帮助自由职业者，告别乏味的朝九晚五，拥抱地点和时间的自由，开创个体价值；帮助小企业拓展客户网络，创造财富机会',
      find: '找任务'
    },
    footer: {
      members: '会员体系',
      tool: '产品研发工具AirEdge',
      join: '加入智研汇',
      firm: '关于我们',
      companyLink: '智研汇企业官网',
      airEdgeLink: 'AirEdge智研爱智',
      service: '服务协议',
      privacy: '隐私协议',
      questions: '智研问答',
      record1: '沪ICP备2022012135号-1',
      record2: '沪B2-20220458',
      record3: '沪闵人社3101120201033 号',
      copyright: '©2022 智研汇（上海）科技有限公司'
    },
    partner: {
      bar: '对雇佣伙伴',
      title: '降本提效，取长补短',
      text: '优化成本结构，一站式解决资源需求潮汐性与能力短板难题',
      publish: '发布项目',
      find: '找人才或团队',
      tips: '不知道自己的需求？我们帮助您',
      toUpgrade: '请申请成为HP或切换为HP用户后再发布项目',
      toCert: '您还未认证, 请先去认证后再发布项目!',
      goToCert: '去认证',
      toRegister: '您还未激活账号, 请去您的注册邮箱激活邮件!'
    },
    say: '他们怎么说？',
    hot: {
      title: '热门人才分类',
      set: '系统开发工程师',
      hardEngineer: '硬件开发工程师',
      manager: '产品经理',
      systemTest: '系统测试工程师',
      hardTest: '硬件测试工程师',
      pm: '项目经理',
      softEngineer: '软件工程师',
      safety: '安全性能工程师',
      purchase: '采购经理',
      softTest: '软件测试工程师',
      mode: '建模与仿真',
      legal: '合同与法务经理'
    },
    top: {
      title: '智研集智',
      text: '告别刻板的工作方式，一切线上搞定。',
      trust: '受信任'
    },
    why: {
      subTitle: '为什么智研集智',
      teamTitle: '全球精英，随处可及',
      teamText:
        '网罗全球研发精英，为企业优化成本结构、满足资源的潮汐性需求，补齐专有知识短板。',
      processTitle: '重塑工作，助益人群',
      processText:
        '重新定义工作，助力个体创造价值，帮助有边界的机构式组织向柔性和无边界的群体组织进化。',
      safeTitle: '专业高效，安全快捷',
      safeText:
        '电子合同、项目追踪和在线支付功能，让每个环节无缝衔接，安全便捷。'
    },
    becomeDP: '请注册成为DP用户后找任务', // 新增加
    becomeHP: '请注册成为HP用户后找英才' // 新增加
  },
  // 链接相关
  link: {
    help: '帮助与支持',
    email: '邮箱',
    phone: '电话',
    public: '公众号',
    code: 'footer-code.png'
  },
  linkMenu: {
    join: '加入智研汇',
    help: '帮助与支持',
    conditions: '条款说明',
    firm: '关于我们',
    service: '服务协议',
    tort: '知识产权',
    law: '法律声明',
    privacy: '隐私协议',
    user: '服务协议',
    questions: '智研问答',
    userEntry: '入驻协议',
    cancellation: '退出协议',
    credit: '智信用',
    cookies: 'Cookie政策',
    dispute: '纠纷处理规则',
    deposit: '诚信保证金规则'
  },
  tipMsg: {
    parbidding: '您不能竞标自己团队的项目哦',
    noTopic: '您无需和自己对话',
    leaveMsg: '请对HP说几句话，可以简单介绍您的背景或经验或具备的优势',
    desc: '几句话向HP说明为什么你可以承接此项目的理由'
  },
  // 会员卡相关
  members: {
    title: '成为智研汇高阶会员',
    secondTitle: '更超值 更高效',
    tipOne: '更高曝光，更易匹配',
    tipTwo: '1-1 专人服务',
    tipThree: '更多流程辅导与内容辅导',
    tipFour: '更大存储',
    free: '免费',
    determined: '待定',
    need: '每日仅需',
    unit: '元',
    month: '月',
    season: '季',
    year: '年',
    tabsHP: 'HP会员',
    tabsDP: 'DP会员',
    serive: '服务说明',
    certUser: '认证用户',
    advUser: '高级用户',
    enjoyUser: '尊享用户',

    task: '发布任务和招标',
    resource: '经过认证的DP资源',
    payText: '快捷的在线支付',
    contract: '快捷的电子合同',
    easy: '简易的线上合作',
    customer: '标准客户服务',
    cloudStorage: '标准云储存空间',
    support: '专人支持',
    release: '发布项目流程辅导',
    coach: '合同撰写流程辅导',
    addStorage: '额外云储存空间',
    mode: '项目发布专业模版库',
    mordStorage: '更多额外云储存空间',
    dpMatch: '每月三次保证DP匹配成功',
    resourceHp: '经过认证的HP资源',
    classic: '经典之选',
    best: '增强之选',
    ideal: '理想之选',
    now: '立即开通',
    recommend: '推荐',

    dialog: {
      amount: '应付金额',
      alipay: '支付宝支付',
      weChat: '微信支付'
    }
  },
  // 新增翻译内容
  addContent: {
    loginTip: '请登录后查看详情!',
    residue: '剩余',
    day: '天',
    autoAcceptance: '自动验收',
    order: {
      apply: '已申请作废合同 待', // 完整的话是 : 已申请作废合同 待DP/HP确认
      sure: '确认',
      noCancel: '不同意作废合同',
      confirmContract: '确认合同内容',
      stay: '等待 {0} 确认合同',
      confirmTrue: '待您查看合同确认合同内容无误后点击确认按钮',
      viewerContract: '查看合同',
      confirmVoid: '确定作废合同吗?',
      rejectedVoid: '驳回作废',
      noBank: '银行账户缺失,请添加银行账户',
      confirmPay: '您确定支付吗?',
      payTrue: '确认支付'
    },
    updateLink: '修改链接名称',
    updateFileName: '修改文件名',
    allFile: '全部文件',
    readError: '读取出错了',
    changeTel: '更换手机号',
    prePayTip: '预付款支付比例不能为0',
    finalPayTip: '尾款支付比例不能为0',
    stepTip: '阶段',
    stepTip2: '比例不能为0',
    docsignTip:
      '发起签署后，请到注册邮箱找到DocuSign发来的邮件，根据提示完成电子签署。在你完成签署后别忘记提醒对方也用相同方式完成签署',
    signTip:
      '请到注册邮箱找到DocuSign发来的邮件，根据提示完成电子签署。在你完成签署后别忘记提醒对方也用相同方式完成签署。',
    sendSignSuccess: '发起签署成功！请等待e签宝发送的手机短信，并根据提示操作。'
  },
  // 开户页面
  createAccount: {
    tabsTitle: {
      personal: '工作室账户',
      enterprise: '企业账户'
    },
    blankFieldTips: '必填项不能为空字符',
    personal: {
      name: '姓名',
      certificateType: '证件类型',
      cardId: '证件号码',
      certExpDateStart: '证件有效期始',
      certExpDateEnd: '证件有效期末',
      phoneNo: '手机号',
      address: '地址',
      bank: '开户银行',
      accountType: '账户类型',
      accountNo: '账号',
      accountName: '户名',
      accountAddress: '开户地址',
      subbranch: '支行名称',
      cardIdFront: '身份证正面(人像)',
      cardIdBack: '身份证背面(国徽)',
      occupation: '职业',
      nationality: '国籍',
      agreement: '协议'
    },
    enterprise: {
      name: '企业姓名',
      enterpriseType: '企业类型',
      legalPerson: '法定代表人姓名',
      certificateType: '法定代表人证件类型',
      legalPersonCardId: '法定代表人证件号码',
      startDate: '法定代表人证件生效日期',
      endDate: '法定代表人证件失效日期',
      phoneNo: '法定代表人手机号',
      contacts: '联系人姓名',
      contactEmail: '联系人邮箱',
      contactsCertificate: '联系人证件类型',
      certificateNo: '联系人证件号码',
      contactsCertificateStartDate: '联系人证件生效日期',
      contactsCertificateEndDate: '联系人证件失效日期',
      contactsPhoneNo: '联系人手机号',
      enterpriseAddress: '企业地址',
      enterprisePhone: '企业电话',
      license: '营业执照号',
      licenseStartDate: '营业执照生效日期',
      licenseEndDate: '营业执照失效日期',
      bank: '开户银行',
      accountType: '账户类型',
      accountNo: '账号',
      accountName: '户名',
      accountAddress: '开户地址',
      subbranch: '支行名称',
      legalPersonCardIdFront: '法定代表人身份证正面(人像)',
      legalPersonCardIdBack: '法定代表人身份证背面(国徽)',
      contactsCardIdFrontcardIdFront: '联系人身份证正面(人像)',
      contactsCardIdFrontcardIdBack: '联系人身份证背面(国徽)',
      licenseImg: '营业执照',
      industry: '行业',
      nationality: '企业所在国家地区',
      agreement: '协议'
    },
    pleaseInput: '请输入',
    pleaseUpload: '请上传',
    select: '请选择',
    uploadTips: '图片总大小不得超过10M',
    province: '省份',
    area: '地区',
    abroad: {
      accountName: '账户名',
      bankName: '开户银行',
      cardNumber: '银行账号',
      bankCode: '开户行代码',
      bankAddress: '开户行地址',
      swiftCode: 'SWIFT CODE'
    },
    // 开户校验提示
    errorTips: {
      certTypeError: '联系人的证件类型与法定代表人的不一致',
      certNoError: '联系人的证件号码与法定代表人的不一致',
      certStartDate: '联系人的证件生效日期与法定代表人的不一致',
      certEndDate: '联系人的证件失效日期与法定代表人的不一致',
      nameError1: '户名与企业名称不一致',
      nameError2: '户名与法定代表人姓名不一致'
    }
  },
  // 引导开户信息
  guideCreateAccount: {
    hpTips: '开户后才可以跟DP签合同哦',
    dpTips: '开户后才可以拿到报酬哦'
  },
  // 招聘
  recruit: {
    joinAirRnd: '加入智研汇',
    grow: '我们一起成长',
    popularPositions: '热门岗位',
    hot: '热',
    emergency: '急',
    backList: '返回职位列表',
    apply: '申请职位',
    name: '姓名',
    phoneNo: '手机号码',
    please: '请',
    uploadResume: '上传简历',
    uploadFile: '上传附件',
    pleaseInput: '请输入',
    pleaseUpload: '请上传',
    uploadTip1: '支持pdf、doc、ppt、docx、pptx、jpg、png等简历格式，不超过2M',
    uploadTip2:
      '支持文档、图片、压缩包、视频、音频、设计文件等格式文件，不超过20MB',
    cancel: '取消',
    submitResume: '提交简历'
  },
  // 举报弹窗
  reportDialog: {
    title: '举报',
    list: [
      '垃圾广告',
      '涉黄涉暴等违法信息',
      '侮辱、恶意及辱骂行为',
      '侵权',
      '我是理由'
    ],
    reasonText: '请输入理由'
  }
}
