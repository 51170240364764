<template>
  <div class="menu-box">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <div v-if="session" class="firm">
        <div @click="toHomePage()">
          <el-avatar
            class="list-img"
            :size="32"
            :src="fileImg(session.logo, 'logo')"
          >
            <img src="@/assets/images/default-icon.png" />
          </el-avatar>
        </div>
        <div class="text" @click="toWorkbench()">
          <el-tooltip :content="session.teamName" placement="top">
            <span> {{ session.teamName || '' }}</span>
          </el-tooltip>
          <div class="customer-type">
            <span class="hp" v-show="session.teamType == 1">HP</span>
            <span class="dp" v-show="session.teamType == 2">DP</span>
            <!-- 智信分 -->
            <span @click="toZhiXin" class="zhixinfen" v-if="lang === 'zh'"
              >智信分 80 <img :src="arrow" alt=""
            /></span>
          </div>
        </div>
        <el-popover trigger="click" size="left" v-model="isShowPop">
          <div slot="reference" class="right-icon">
            <i
              :class="!isShowPop ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"
            ></i>
          </div>
          <div class="drop-list" v-if="isShowPop">
            <!-- <div v-if="hasMoreRole" class="item" @click="changeRole">
              <i class="iconfont icon-yonghuqiehuan mr_8"></i>
              {{
                session.teamType == '1'
                  ? $t('login.changeToDP')
                  : $t('login.changeToHP')
              }}
            </div>
            <div v-if="newRole" class="item" @click="upgrade(session.teamType)">
              <i class="el-icon-s-custom mr_8" />
              {{ newRole }}
            </div> -->
            <div class="item" @click="isShowChange = !isShowChange">
              <svg-icon icon-class="nav-team" class="drop-down" />
              <span>{{ tabs.changeTeam }}</span>
              <i
                :class="
                  !isShowChange ? 'el-icon-caret-bottom' : 'el-icon-caret-top'
                "
              ></i>
            </div>
            <template v-if="isShowChange">
              <div
                v-for="item in teamList"
                :key="item.teamId"
                class="team-list"
                :class="{ active: session.teamId == item.teamId }"
                @click="handelChange('a', item)"
              >
                <el-avatar
                  class="list-img"
                  :size="32"
                  :src="fileImg(item.logo, 'logo')"
                >
                  <img src="@/assets/images/default-icon.png" />
                </el-avatar>
                <span>{{ item.teamName }}</span>
              </div>
              <!-- <div
                v-if="
                  session &&
                  (session.userStatus == '1' || session.userStatus == '2')
                "
                class="team-list"
                @click="addTeam"
              >
                <i class="el-icon-plus" style="margin-right: 10px"></i>
                {{ $t('login.addTeam') }}
              </div> -->
            </template>
          </div>
        </el-popover>
      </div>
      <el-menu
        ref="menus"
        :default-active="activeMenu"
        text-color="#666"
        :unique-opened="false"
        active-text-color="#FB4236"
        :collapse-transition="false"
        :default-openeds="opens"
        @close="handleClose"
      >
        <sidebar-item
          v-for="route in permission_routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
    <AddTeam ref="addTeam" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import { fileImg } from '@/utils/tools.js'
import AddTeam from '@/components/addTeam'
import { getListByUserId, upgradeTeam } from '@/api/login.js'
import arrow from '@/assets/images/icon-arrow.png'
export default {
  components: { AddTeam, SidebarItem },
  data() {
    return {
      isShowPop: false, // pop下拉弹窗
      isShowChange: false, // 控制切换团队
      tabs: this.$t('login.loginView.tabs'),
      team: null,
      teamList: [],
      arrow
    }
  },
  computed: {
    ...mapGetters(['permission_routes', 'session', 'lang']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    opens() {
      return this.filterRouterKeys()
    },
    hasMoreRole() {
      const { session } = this
      let b =
        this.session &&
        (session.userStatus == '1' || session.userStatus == '2') &&
        session.teamTypeList.length == 2
          ? true
          : false
      return b
    },
    newRole() {
      const { session } = this
      let role = null
      if (session && session.teamTypeList.length == 1) {
        role =
          session.teamType == 1
            ? `${this.$t('login.become')}DP`
            : `${this.$t('login.become')}HP`
      }
      return role
    }
  },
  watch: {
    isShowPop(v) {
      if (v) {
        this.getTeamInfo()
      }
    }
  },
  methods: {
    toZhiXin() {
      window.open('/link/credit')
    },
    fileImg,
    filterRouterKeys() {
      const keys = []
      const showRoutes = this.permission_routes.filter((item) => !item.hidden)
      showRoutes.forEach((item) => {
        const children = (item.children || []).filter((child) => !child.hidden)
        if (children.length > 1) {
          keys.push(item.path)
        }
      })
      return keys
    },
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath)
    },
    getTeamInfo() {
      getListByUserId().then((res) => {
        if (res && res.errorCode === 0) {
          this.teamList = res.data || []
        }
      })
    },
    // 切换角色
    changeRole() {
      let type = this.session.teamType == 1 ? 2 : 1
      this.isLoading = true
      this.$store
        .dispatch('user/GetSessionPlus', { teamType: type })
        .then(() => {
          this.isLoading = false
          this.$router.replace({ path: '/work/workbench' })
          location.reload()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    handelChange(v, item) {
      this.isShowPop = false
      this.isShowChange = false
      if (v === 'a') {
        this.updateTeam(item)
      }
    },
    updateTeam(item) {
      if (item.teamId === this.session.teamId) {
        return
      }
      this.$store
        .dispatch('user/ChangeTeam', {
          changeTeamId: item.teamId,
          teamType: item.teamTypeList[0]
        })
        .then(() => {
          this.$route.path == '/work/workbench'
            ? location.reload()
            : this.$router.push({ path: '/work/workbench' }).then(() => {
                location.reload()
              })
        })
    },
    // 升级成为DP/HP角色
    upgrade(type) {
      // 1:hp 2.dp
      this.isLoading = true
      let teamType = type == 1 ? 2 : 1
      upgradeTeam({ teamType: teamType })
        .then(() => {
          this.isLoading = false
          this.$store.dispatch('user/GetSessionPlus', { teamType: teamType })
          teamType == 1
            ? this.$router.push({ path: '/work/workbench' }).catch(() => {})
            : this.$router.push({ path: '/project' }).catch(() => {})
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    // 添加团队
    addTeam() {
      this.$refs.addTeam.show()
    },
    toWorkbench() {
      this.$router.push({ path: '/work/workbench' }).catch(() => {})
    },
    toHomePage() {
      const { session } = this
      if (session.teamType == 1) {
        this.$router.push({ name: 'HpHomepage' })
      } else if (session.teamType == 2) {
        this.$router.push({ name: 'myHomePage' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-box {
  height: $content-height;
  background-color: #ffffff;
  ::v-deep {
    .el-scrollbar {
      height: 100%;
    }
    .el-scrollbar__thumb {
      display: none;
    }
    .el-scrollbar__view {
      overflow: hidden;
    }
    .el-menu {
      border: 0;
      padding: 8px 8px 16px;
    }
    .el-menu--inline {
      padding: 0;
      margin-bottom: 0;
    }
    .el-menu-item {
      height: 38px;
      line-height: 38px;
      margin-bottom: 4px;
      padding-left: 16px !important;
      span {
        margin-left: 8px;
      }
      svg {
        font-size: 16px;
        vertical-align: -0.25em;
      }
    }
    .el-submenu .el-menu-item {
      min-width: 100px;
    }
    .el-submenu__icon-arrow,
    .el-icon-arrow-down {
      display: none;
    }
    .el-submenu__title {
      font-size: 12px;
      color: #999 !important;
      padding: 0 0 0 16px;
      height: 48px;
      line-height: 60px;
    }
    .el-submenu__title:hover {
      background: transparent;
    }
    .el-menu-item.is-active {
      background: #fff5f5 !important;
      border-radius: 4px;
    }
    .el-menu-item:hover {
      background: #f7f8fa;
      border-radius: 4px;
    }
  }
}
::v-deep {
  .el-scrollbar__wrap {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .el-popover {
    padding: 0 0 24px;
  }
}
.firm {
  height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f3f5;
  padding: 0 16px;
  cursor: pointer;
  .text {
    padding-left: 12px;
    width: 140px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .customer-type {
      margin-top: 4px;
      font-size: 10px;
      line-height: 14px;
      > span {
        display: inline-block;
        border-radius: 2px;
        font-weight: 500;
        color: #ffffff;
        line-height: 10px;
        padding: 3px;
        margin-right: 4px;
      }
      .hp {
        background: #ff7d00;
      }
      .dp {
        background: #ffbf00;
      }
      .zhixinfen {
        background: #2ac33d;
      }
    }
  }
  .right-icon {
    width: 40px;
    // height: 40px;
    text-align: center;
    // line-height: 40px;
  }
}
.drop-list {
  max-height: 400px;
  overflow-y: auto;
  .item {
    cursor: pointer;
    margin-bottom: 4px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    .mr_8 {
      margin-right: 8px;
    }
    &:last-child {
      margin-bottom: none;
    }
    &:active,
    &:hover {
      background-color: #f7f8fa;
    }
    > span {
      display: inline-block;
      padding-left: 8px;
      flex: 1;
    }
    > i {
      color: #333333;
    }
  }
  .team-list {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    padding: 12px;

    .list-img {
      margin-right: 8px;
      cursor: pointer;
      // .img {
      //   width: 100%;
      // }
    }
    > span {
      display: inline-block;
      width: 136px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover,
    &:active,
    &.active {
      background-color: #e7ecee;
      border-radius: 4px 4px 0px 0px;
    }
    .img {
      width: 32px;
      height: 32px;
    }
  }
  .icon-yonghuqiehuan {
    color: #333333;
  }
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 4px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(17, 16, 16, 0.13);
  -webkit-box-shadow: rgba(0, 0, 0, 0.9);
  box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(211, 173, 209, 0.4);
}
</style>
