export default {
  code: {
    0: 'Success',
    999999: 'System exception',
    990000: 'Gateway exception',
    990105: 'Token check failure or invalid, log in again please',
    990108: 'Request information update failed',
    990109: 'Signature check failed',
    888801:
      'Please go to your email address to activate your DocuSign account so that you can close your contract signature loop', // 新增加
    100001: "Parameters can't be null",
    100002: 'Invalid verification code',
    100003: 'Failed to send out verification code',
    100101: 'Invalid invitation code',
    100102: 'Register failure',
    100103: 'User already activated',
    100104: 'Email already registered',
    100105: 'Wrong invitation code',
    100106: 'Invalid activation code',
    100107: 'User not invited',
    100108: 'Invalid link for password change, please resend the link',
    100109: 'Old password incorrect',
    100201: 'Group not exist',
    100202: 'Account not exist',
    100203: 'Bank account not exist',
    100204: 'Group not verified',
    100205: 'Failed to join the team',
    100206:
      'The invoice information is incomplete, please make it up before continuing',
    100301: 'Project not exist',
    100302: 'Project delivery information not exist',
    100303: 'Project comments not exist',
    100304: 'Project status exception',
    100305: 'Project termination request already exist',
    100306: 'Project termination request not exist',
    100307:
      'According to the rules, please bind your bank card before bidding. You can bind your bank card in the "My payments" module',
    100308: 'Project comments already exist',
    100309: 'Failed to send project invitation',
    100310: "Can't select a project published by yourself",
    100311: "You've already bid for this project",
    100312: 'Receiving bank account not exist',
    100313: 'Project acceptance not completed yet',
    100314: 'Not all payment have been made. ',
    100315: 'Project acceptance failed due to unfinished contract(s)', // 新增加
    100316:
      'Please confirm all unpaid working sheet has been verified and paied', // 新增加
    100331: 'Contract template not exist',
    100332: 'Contract not exist',
    100333: 'Contract status exception',
    100334: 'Contract signing information already exist',
    100335: 'Contract signature failed',
    100336: 'Contract signature service exception',
    100337: 'Contract signed',
    100338: 'Resigning contract failed, contact Admin please', // 新增加
    100339: 'DocuSign failed to send out the signature email ', // 新增加
    100340: 'HP account does not exist', // 新增加
    100341: 'DP accoutn does not exist', // 新增加
    100401: 'User not exist',
    100402: 'Incorrect user name or password',
    100403: 'Falied to get user IMToken',
    100404: 'User already activated, no need to resend activation email',
    100405: 'User DocuSign account activation failed', // 新增加
    100501: 'File upload failed',
    100502: 'File not exist',
    100601: 'Cloud drive not exist',
    100602: 'Parent folder not exist',
    100603: 'Folder not exist',
    100604: 'Not enough cloud drive storage',
    100605: 'File not exist',
    100701: 'Bidding record not exist',
    100702: "Bid already attended, can't re-attend",
    100801: 'Verification failed',
    60000001:
      "Can't get verification link; Reason: wrong parameter: wrong ID card format",
    101001: 'Current user account is in review, do not repeat creating',
    101002: 'Fail to create the account',
    101003: "The third party payment account hasn't been created",
    101004: 'Abnormal merchant information',
    101005: 'Pre-order failure',
    101006: 'Corporate account has been created',
    101007: 'Fail to change the payment account',
    100317:
      'The post cannot be cancelled if a DP has participated in the bidding',
    100344: 'The contract amount shall not be less than 200',
    100318: 'The project has ended',
    100319: 'Project type is not matched',
    100320: 'Time card input error',
    100321: 'The time card already exists',
    100322: 'The time card has been confirmed and cannot be saved or submitted',
    101011: 'Payment has been completed, please refresh the page',
    100112: 'Mobile phone number has been registered',
    100406: 'Mobile phone number has been registered',
    100407: 'User account cancellation application is under way',
    100408: 'The user account cancellation application is abnormal',
    100409: 'The user registration audit record does not exist'
  }
}
