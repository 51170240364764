import { OpenIMSDK } from 'open-im-sdk'

const openIM = new OpenIMSDK()

// openIM.on('OnProgress', (res) => {
//   console.log('OnProgress', res)
// })

export default openIM

export const messageTypes = {
  TEXTMESSAGE: 101,
  PICTUREMESSAGE: 102
}

export const CbEvents = {
  OnRecvNewMessage: 'OnRecvNewMessage', // 接收到新消息
  OnRecvMessageRevoked: 'OnRecvMessageRevoked', // 其他用户撤回信息回执
  OnRecvC2CReadReceipt: 'OnRecvC2CReadReceipt', // 消息已读回执
  OnNewConversation: 'OnNewConversation', // 有新会话产生
  OnConversationChanged: 'OnConversationChanged' // 会话列表发生改变
}
// 分页条数
export const pageCount = 12
