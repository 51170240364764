const Terms = [
  {
    path: '/privacy/zh',
    name: 'privacyZh',
    component: () => import('@/views/login/terms/privacyZh')
  },
  {
    path: '/privacy/en',
    name: 'privacyEn',
    component: () => import('@/views/login/terms/privacyEn')
  },
  {
    path: '/agreement/zh',
    name: 'agreementZh',
    component: () => import('@/views/login/terms/agreementZh')
  },
  {
    path: '/agreement/en',
    name: 'agreementEn',
    component: () => import('@/views/login/terms/agreementEn')
  }
]
export default Terms
