function auto() {
  const files = require.context('./', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const messages = {}
  files.keys().forEach((key) => {
    Object.assign(messages, files(key)['default'])
  })
  return messages
}
export default {
  ...auto()
}
