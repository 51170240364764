export default {
  findItem: {
    itemList: {
      title: 'Project list',
      item: {
        currencyType: [
          {
            label: 'RMB',
            value: 'CNY'
          },
          {
            label: 'US Dollar',
            value: 'USD'
          },
          {
            label: 'Euro',
            value: 'EUR'
          }
        ],
        status: [
          {
            label: 'Draft',
            value: '0'
          },
          {
            label: 'Valid',
            value: '1'
          },
          {
            label: 'Open for bid',
            value: '2'
          },
          {
            label: 'Bid awarded',
            value: '3'
          },
          {
            label: 'Pending contracts',
            value: '4'
          },
          {
            label: 'Signed contracts',
            value: '5'
          },
          {
            label: 'Delivered',
            value: '6'
          },
          {
            label: 'Accepted',
            value: '7'
          },
          {
            label: 'Unexpected termination',
            value: '8'
          },
          {
            label: 'Accomplished',
            value: '8'
          }
        ],
        switchingState: ['Unfold', 'Fold']
      }
    },
    itemDetails: {
      left: {
        title: 'Project details',
        sketch: {
          parBidding: 'Join the bid',
          biding: 'Already bid'
        },
        term: {
          taskReq: 'Task descriptions',
          skillReq: 'Required skill sets',
          expComTime: 'Expected delivery date：',
          enclosure: 'Attachment'
        }
      },
      right: {
        title: 'About the publisher', // 新增加
        contact: 'Contact her/him',
        company: 'Company:',
        country: 'Country or region:',
        numPubItems: 'Number of projects:',
        name: 'Contact' // 新增加
      },
      dialog: {
        title: 'Join the bid',
        reason:
          'Please say several words to HP to show your competance in terms of background, experiences or unique advantages.'
      },
      unitOfTime: ['Day', 'Week', 'Month', 'Year'],
      deliveryUnit: [
        {
          label: 'Day',
          value: '1'
        },
        {
          label: 'Week',
          value: '2'
        },
        {
          label: 'Month',
          value: '3'
        },
        {
          label: 'Year',
          value: '4'
        }
      ]
    },
    certification:
      'Oops...you need to get yourself verified to become a Verified User first.'
  },
  personalHomepage: {
    header: {
      contactHim: 'Contact us',
      preview: 'Preview'
    },
    mainLeftArray: [
      'Description',
      'Task types',
      'Working language',
      'Specialized skill sets'
    ],
    mainLeft: {
      desc: 'Description',
      orderRecType: 'Task types',
      workLang: 'Working language',
      goodSkills: 'Specialized skill sets'
    },
    mainRight: {
      proExper: 'Project experience'
    },
    dialog: {
      edit: 'Edit',
      aboutUs: 'About us',
      prjName: 'Project name',
      prjDesc: 'Project description',
      uploadImg: 'Upload pictures',
      tip: 'Maximum 8'
    }
  }
}
