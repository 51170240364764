import Layout1 from '@/views/layout/indexView1'
import Layout from '@/views/layout/indexView'

const contract = [
  {
    path: '/contract',
    component: Layout1,
    hidden: true,
    children: [
      {
        path: 'design',
        component: () => import('@/views/contract/design/index.vue'),
        name: 'contractDesign',
        meta: { isFull: true },
        props: true
      }
    ]
  },
  {
    path: '/contract',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'task',
        component: () => import('@/views/contract/projecttask.vue'),
        name: 'contractTask',
        meta: { isFull: false },
        props: true
      },
      {
        path: 'work',
        component: () => import('@/views/contract/work.vue'),
        name: 'contractWork',
        meta: { isFull: false },
        props: true
      },
      {
        path: 'tpllist',
        component: () => import('@/views/contract/tpllist.vue'),
        name: 'contractTpl',
        meta: { isFull: false },
        props: true
      }
    ]
  }
]
export default contract
