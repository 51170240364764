<template>
  <div v-loading="isLoading" class="header-wrap" :loading="flag">
    <div class="header">
      <div class="header-left">
        <svg-icon
          @click="showMore"
          icon-class="header-more"
          class="more-icon hidden"
        />
        <!-- safari样式存在问题 -->
        <!-- <el-image
          @click="toRoute('/home')"
          style="height: 40px; max-width: 283px"
          :src="mode === 'white' ? logoRed : logo"
          fit="cover"
        /> -->
        <img
          :src="mode === 'white' ? logoRed : logo"
          style="width: 147px"
          @click="toRoute('/home')"
        />
      </div>
      <div class="header-right" :class="[mode == 'white' ? 'black' : 'white']">
        <div class="big-info hidden">
          <template v-if="!token">
            <div @click="toRoute('/talent')">{{ tabs.findTalent }}</div>
            <div @click="toRoute('/find-project/project-list')">
              {{ tabs.findWork }}
            </div>
            <div @click="clickWhy">{{ tabs.chooseAirRnd }}</div>
          </template>
          <template v-else-if="session && session.teamType == 2">
            <div @click="toRoute('/find-project/project-list')">
              {{ tabs.findItem }}
            </div>
          </template>
          <template v-else>
            <div @click="toRoute('/talent')">{{ tabs.findTalent }}</div>
            <div @click="toRoute('/projects/publish-project')">
              {{ tabs.publishProject }}
            </div>
          </template>
          <div>
            <el-dropdown @command="changeLang">
              <span class="el-dropdown-link">
                <i class="iconfont icon-yuyan1" style="margin-right: 4px"></i>
                Language
                <i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu
                slot="dropdown"
                :class="[mode === 'white' ? 'black' : 'white']"
              >
                <el-dropdown-item command="zh">
                  {{ lanList.zh }}
                </el-dropdown-item>
                <el-dropdown-item command="en">
                  {{ lanList.en }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <template v-if="token">
            <div @click="sendFriend">{{ tabs.findFriends }}</div>
            <div @click="toWorkHandle">{{ tabs.workbench }}</div>
            <div class="pop" @click="toRoute('/setting/letter', 1)">
              <el-icon class="el-icon-message"></el-icon>
              <span v-if="count > 0">{{ count }}</span>
            </div>
          </template>
        </div>
        <div class="right-menu" v-if="token">
          <el-popover
            trigger="click"
            size="bottom"
            title=""
            width="300"
            v-model="isShowPop"
          >
            <div class="userName" slot="reference">
              <div class="user-img">
                <el-avatar
                  v-if="avatar"
                  class="list-img"
                  :size="32"
                  :src="avatar | filterLogo"
                >
                  <img src="@/assets/images/default-icon.png" />
                </el-avatar>
              </div>
            </div>
            <div class="head">
              <div>
                <el-avatar
                  class="list-img"
                  :size="32"
                  :src="avatar | filterLogo"
                >
                  <img src="@/assets/images/default-icon.png" />
                </el-avatar>
              </div>
              <div>
                <p v-if="session">
                  {{ session | filterName }}
                </p>
                <p v-else>--</p>
                <p class="text">{{ session ? session.teamName : '' }}</p>
              </div>
            </div>
            <div class="drop-list">
              <template v-if="!isCancelAccount && !isRegisterAudit">
                <div class="item" @click="toPersonSet">
                  <i class="iconfont icon-gerenzhongxin drop-down"></i>
                  <span>{{ $t('menuBar.personal') }}</span>
                </div>
                <div class="item" @click="changePassword">
                  <i class="iconfont icon-mima drop-down"></i>
                  <span>{{ $t('menuBar.security') }}</span>
                </div>
              </template>
              <div class="item" @click="logout()">
                <svg-icon icon-class="nav-logout" class="drop-down" />
                <span>{{ tabs.logout }}</span>
              </div>
            </div>
          </el-popover>
        </div>
        <div v-else>
          <span
            class="btn"
            :class="{ active: $route.path == '/login' }"
            @click="toRoute('/login')"
            >{{ tabs.login }}</span
          >
          <span
            class="btn"
            :class="{ active: $route.path == '/register' }"
            @click="toRoute('/register')"
            >{{ tabs.register }}</span
          >
        </div>
      </div>
    </div>
    <el-drawer
      class="draw"
      size="100%"
      :visible.sync="drawer"
      direction="ltr"
      :modal="false"
      :withHeader="false"
    >
      <div class="draw-wrap">
        <template v-if="!token">
          <div @click="toRoute('/talent')">{{ tabs.findTalent }}</div>
          <div @click="toRoute('/find-project/project-list')">
            {{ tabs.findWork }}
          </div>
          <div>{{ tabs.chooseAirRnd }}</div>
        </template>
        <template v-else-if="session && session.teamType == 2">
          <div @click="toRoute('/find-project/project-list')">
            {{ tabs.findItem }}
          </div>
        </template>
        <template v-else>
          <div @click="toRoute('/talent')">{{ tabs.findTalent }}</div>
          <div @click="toRoute('/projects/publish-project')">
            {{ tabs.publishProject }}
          </div>
        </template>
        <div>
          <el-dropdown @command="changeLang">
            <span class="el-dropdown-link">
              {{ tabs.language }}
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu
              slot="dropdown"
              :class="[mode === 'white' ? 'black' : 'white']"
            >
              <el-dropdown-item command="zh">{{ lanList.zh }}</el-dropdown-item>
              <el-dropdown-item command="en">{{ lanList.en }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <template v-if="token">
          <div @click="sendFriend()">{{ tabs.findFriends }}</div>
          <div @click="toWorkHandle()">{{ tabs.workbench }}</div>
          <div class="pop" @click="toRoute('/setting/letter')">
            <el-icon class="el-icon-message"></el-icon>
            <span v-if="count > 0">{{ count }}</span>
          </div>
        </template>
      </div>
    </el-drawer>
    <div v-show="drawer" class="mask" @click="drawer = false"></div>
    <UpdateSecurity ref="updateSecurity" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fileImg, fullName } from '@/utils/tools.js'
import { mapGetters } from 'vuex'
import UpdateSecurity from '@/views/setting/security/dialog/updateSecurity.vue'
export default {
  components: {
    UpdateSecurity
  },
  props: {
    mode: {
      type: String,
      default: 'white'
    }
  },
  data() {
    return {
      certType: '1',
      num: 0, // 消息数量
      logoRed: require('@/assets/images/logo-red.png'),
      logoWhite: require('@/assets/images/logo-white-zh.png'),
      tabs: this.$t('login.loginView.tabs'),
      lanList: this.$t('login.loginView.lanList'),
      airRnd: this.$t('login.loginView.airRnd'),
      isLoading: false,
      flag: false,
      isShowPop: false, // pop下拉弹窗
      teamList: [],
      drawer: false // 左侧按钮展示更多
    }
  },
  filters: {
    filterLogo(v) {
      return fileImg(v)
    },
    filterName(v) {
      return fullName(v)
    }
  },
  computed: {
    ...mapState('user', ['token', 'session', 'avatar']),
    ...mapGetters(['count', 'lang']),
    hasMoreRole() {
      const { session } = this
      let b =
        this.session &&
        (session.userStatus == '1' || session.userStatus == '2') &&
        session.teamTypeList.length == 2
          ? true
          : false
      return b
    },
    newRole() {
      const { session } = this
      let role = null
      if (session && session.teamTypeList.length == 1) {
        role =
          session.teamType == 1
            ? `${this.$t('login.become')}DP`
            : `${this.$t('login.become')}HP`
      }
      return role
    },
    logo() {
      return require('@/assets/images/logo-white.png')
      // return this.lang === 'en'
      //   ? require('@/assets/images/logo-white.png')
      //   : require('@/assets/images/logo-white-zh.png')
    },
    // 是否在注销账号
    isCancelAccount() {
      return this.$route.name == 'WithdrawApplyPage'
    },
    // 注册审核中 / 审核失败
    isRegisterAudit() {
      const { session } = this
      return (
        session &&
        session.userStatus === '1' &&
        session.check &&
        (session.check.status === '1' || session.check.status === '3')
      )
    }
  },
  methods: {
    // 未审核通过跳转审核页面
    jumpToAudit() {
      if (this.isRegisterAudit) {
        if (this.$route.path !== '/register/audit') {
          this.$router.replace({ path: '/register/audit' })
        }
        return true
      }
      return false
    },
    clickWhy() {
      console.log(this.$router, 123)
      if (this.$router.currentRoute.path === '/home') {
        this.$emit('clickWhy', 'why')
      } else {
        this.$router.push({ path: '/home', query: { type: 'why' } })
      }
    },
    showMore() {
      this.drawer = !this.drawer
    },
    changeLang(v) {
      if (v === 'zh') {
        this.$i18n.locale = 'zh'
        this.$store.dispatch('global/setLang', 'zh')
      } else {
        this.$i18n.locale = 'en'
        this.$store.dispatch('global/setLang', 'en')
      }
      if (this.drawer) {
        this.drawer = false
      }
      this.$store.dispatch('base/GetCountry', { lang: v })
      this.$store.dispatch('base/getMonetaryUnit', {
        dictType: 'currency',
        lang: v
      })
      this.tabs = this.$t('login.loginView.tabs')
      this.lanList = this.$t('login.loginView.lanList')
      this.airRnd = this.$t('login.loginView.airRnd')
    },
    toRoute(v) {
      if (this.drawer) {
        this.drawer = false
      }
      if (this.$route.path == v || this.isCancelAccount || this.jumpToAudit()) {
        return
      }
      const { session } = this
      if (v == '/setting/letter' || v == '/projects/publish-project') {
        if (session.userStatus == 0) {
          if (this.$route.path == '/register/active') {
            this.$alert(this.$t('permis.toActivate'), '', {
              confirmButtonText: this.$t('btns.sure'),
              type: 'warning'
            })
          } else {
            this.$confirm(this.$t('permis.toRegister'), '', {
              confirmButtonText: this.$t('btns.sure'),
              cancelButtonText: this.$t('btns.cancel'),
              type: 'warning'
            }).then(() => {
              this.$router.push({ path: '/register/active' })
            })
          }
        } else if (v == '/projects/publish-project' && session.isCert == '0') {
          if (this.$route.path !== '/team/team-info/cert') {
            this.$confirm(this.$t('headTips.certTip'), '', {
              confirmButtonText: this.$t('btns.toCert'),
              cancelButtonText: this.$t('btns.cancel'),
              type: 'warning'
            }).then(() => {
              this.$router.push({ path: '/team/team-info/cert' })
            })
          } else {
            this.$message.warning(this.$t('permis.toCert'))
          }
        } else {
          this.$router.replace({ path: v }).catch(() => {})
        }
      } else {
        // 注册时，当状态是注册审核中，展示审核中的页面
        this.$router.replace({ path: v }).catch(() => {})
      }
    },
    logout() {
      this.isLoading = true
      this.$store
        .dispatch('user/LogOut')
        .then(() => {
          this.isLoading = false
          this.$message.success(this.$t('logout.successTip'))
          if (this.$route.path == '/home') {
            this.$router.go(0)
          } else {
            setTimeout(() => {
              this.$router.replace({ path: '/home' })
            }, 1000)
          }
        })
        .catch(() => {
          this.isLoading = false
        })
      this.$store.dispatch('im/logoutIm')
    },
    toWorkHandle() {
      if (this.isCancelAccount || this.jumpToAudit()) {
        return
      }
      if (this.drawer) {
        this.drawer = false
      }
      const { session } = this
      if (session.userStatus == 0) {
        if (this.$route.path == '/register/active') {
          this.$alert(this.$t('permis.toActivate'), '', {
            confirmButtonText: this.$t('btns.sure'),
            type: 'warning'
          })
        } else {
          this.$confirm(this.$t('permis.toRegister'), '', {
            confirmButtonText: this.$t('btns.sure'),
            cancelButtonText: this.$t('btns.cancel'),
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/register/active' })
          })
        }
      } else {
        this.$router.push({ path: '/work/workbench' }).catch(() => {})
      }
    },
    // 发送好友邀请
    sendFriend() {
      if (this.isCancelAccount || this.jumpToAudit()) {
        return
      }
      const { session } = this
      if (this.drawer) {
        this.drawer = false
      }
      if (session) {
        if (session.userStatus == 0) {
          if (this.$route.path == '/register/active') {
            this.$alert(this.$t('permis.toActivate'), '', {
              confirmButtonText: this.$t('btns.sure'),
              type: 'warning'
            })
          } else {
            this.$confirm(this.$t('permis.toRegister'), '', {
              confirmButtonText: this.$t('btns.sure'),
              cancelButtonText: this.$t('btns.cancel'),
              type: 'warning'
            }).then(() => {
              this.$router.push({ path: '/register/active' })
            })
          }
        } else {
          this.$router.push({ path: '/setting/invite' }).catch(() => {})
        }
      } else {
        this.$message.warning('请登录后访问哦!')
      }
    },
    // // 添加团队
    // addTeam() {
    //   this.$refs.addTeam.show()
    // },
    // 修改密码
    changePassword() {
      this.$refs.updateSecurity.show()
      this.isShowPop = false
    },
    // 个人设置
    toPersonSet() {
      this.$router.push({ path: '/setting/personal-set' }).catch(() => {})
      this.isShowPop = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/element-variables.scss';
.user-img {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  > img {
    display: inline-block;
    width: 100%;
  }
}
.header-wrap {
  width: 100%;
}
.black {
  .el-dropdown {
    color: #333333;
  }
}
.white {
  .el-dropdown {
    color: #ffffff;
  }
}
.header {
  height: 64px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  // min-width: 968px;
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .header-left {
    cursor: pointer;
    .more-icon {
      font-size: 24px;
      vertical-align: top;
      display: inline-block;
      margin-top: 8px;
      margin-right: 6px;
    }
  }
  .header-right {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    &.black {
      color: #333333 !important;
    }
    &.white {
      color: #ffffff !important;
    }
    > div {
      cursor: pointer;
      margin-right: 4px;
      padding: 0 24px;
    }
  }
  .big-info {
    display: flex;
    align-items: center;
    padding: 0;
    > div {
      cursor: pointer;
      margin-right: 4px;
      padding: 0 24px;
    }
    .pop {
      position: relative;
      padding-right: 0;
      > i {
        font-size: 20px;
      }
      > span {
        position: absolute;
        top: -12px;
        right: -12px;
        min-width: 20px;
        height: 20px;
        background: #fb4236;
        border-radius: 10px;
        text-align: center;
        line-height: 20px;
        color: #ffffff;
        font-size: 12px;
      }
    }
  }
  .btn {
    display: inline-block;
    height: 32px;
    width: 60px;
    text-align: center;
    padding-top: 5px;
    line-height: 22px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    &:last-child {
      margin-left: 4px;
    }
    &.active {
      color: $--color-primary;
      border: 1px solid $--color-primary;
    }
  }
  .right-menu {
    display: flex;
    align-items: center;
  }
}
.userName {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
  > img {
    width: 100%;
  }
}
.head {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  border-bottom: 1px solid #f2f3f5;
  cursor: pointer;
  p {
    margin: 0;
    padding-left: 8px;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }
  .text {
    color: #999999;
    font-size: 12px;
  }
}
.drop-list {
  padding: 8px 8px 4px;
  .item {
    cursor: pointer;
    margin-bottom: 4px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    .mr_8 {
      margin-right: 8px;
    }
    &:last-child {
      margin-bottom: none;
    }
    &:active,
    &:hover {
      background-color: #f7f8fa;
    }
    > span {
      display: inline-block;
      padding-left: 8px;
      flex: 1;
    }
    > i {
      color: #333333;
    }
  }
  .team-list {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    padding: 12px;

    .list-img {
      margin-right: 8px;
      .img {
        width: 100%;
      }
    }
    > span {
      display: inline-block;
      width: 136px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover,
    &:active,
    &.active {
      background-color: #e7ecee;
      border-radius: 4px 4px 0px 0px;
    }
    .img {
      width: 32px;
      height: 32px;
    }
  }
  .icon-yonghuqiehuan {
    color: #333333;
  }
}
.el-popover {
  padding: 0;
}
@media screen and (min-width: 968px) {
  .header {
    min-width: 968px;
    max-width: 1200px;
    .header-left .more-icon.hidden {
      display: none;
    }
    .draw {
      display: none;
    }
  }
}
@media screen and (max-width: 968px) {
  .header {
    width: 100%;
    padding: 0 32px;
    .big-info {
      &.hidden {
        display: none;
      }
    }
  }
}

.draw ::v-deep {
  top: 64px;
  .draw-wrap {
    padding: 32px;
    > div {
      padding: 24px;
      cursor: pointer;
      border: 1px solid #efefef;
      border-radius: 16px;
      margin-bottom: 8px;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
}
</style>
