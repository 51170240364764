import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
import Cookies from 'js-cookie'

Vue.use(VueI18n)

const eleLan = {
  en: elementEnLocale,
  zh: elementZhLocale
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = { ...locales(key)['default'], ...eleLan[locale] }
    }
  })
  return messages
}
const lang =
  Cookies.get('lang') ||
  (navigator.language || navigator.userLanguage || 'en').split('-')[0] ||
  'zh'
Cookies.set('lang', lang)
// console.log(loadLocaleMessages(), elementEnLocale)
const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  ja: {
    currency: {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol'
    }
  },
  zh: {
    currency: {
      style: 'currency',
      currency: 'CNY',
      currencyDisplay: 'symbol'
    }
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  }
}
export default new VueI18n({
  locale: Cookies.get('lang') || 'zh',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh',
  numberFormats,
  messages: loadLocaleMessages()
})
