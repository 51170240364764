export const visibleMixin = {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: this.visible
    }
  },
  watch: {
    visible(v) {
      this.isVisible = v
    },
    isVisible(v) {
      this.$emit('update:visible', v)
      this.openOrClose(v)
    }
  },
  created() {
    this.openOrClose(this.isVisible)
  },
  methods: {
    openOrClose(v) {
      if (v) {
        this.open && this.open()
      } else {
        this.close && this.close()
      }
    }
  }
}

export const authMixin = {
  created() {
    const fn = (session) => {
      if (session.userStatus === '0') {
        this.$message.warning(this.$t('permis.toActivate'))
        this.$router.replace({ path: '/register/active' })
      }
      if (session.userStatus === '1') {
        Number(session.isCert) > 0
          ? this.$message.warning(this.$t('permis.inCert'))
          : this.$message.warning(this.$t('permis.toCert'))
        this.$router.replace({ path: '/team/team-info/cert' }).catch(() => {})
      }
    }
    if (!this.$store.getters.session) {
      this.$store.dispatch('user/GetSession').then((res) => {
        // this.$store.dispatch('im/loginIM')
        fn(res.data)
      })
    } else {
      fn(this.$store.getters.session)
    }
  }
}

export const workTeamMixi = {
  created() {
    const fn = (session) => {
      if (session.userStatus === '0') {
        this.$message.warning(this.$t('permis.toActivate'))
        this.$router.replace({ path: '/register/active' })
      }
    }
    if (!this.$store.getters.session) {
      this.$store.dispatch('user/GetSession').then((res) => {
        // this.$store.dispatch('im/loginIM')
        fn(res.data)
      })
    } else {
      fn(this.$store.getters.session)
    }
  }
}

export const screenMixi = {
  data() {
    return {
      wdith: 1200
    }
  },
  created() {
    window.onresize = () => {
      let clientWidth = document.documentElement.clientWidth
      this.width = Math.round(clientWidth)
      this.getList()
    }
    this.width = Math.round(document.documentElement.clientWidth)
    this.getList()
  }
}

export const countryMixi = {
  computed: {
    countryList() {
      return this.$store.getters.countryList
    },
    monetaryUnit() {
      return this.$store.getters.monetaryUnit
    },
    lang() {
      return this.$store.getters.lang
    }
  },
  created() {
    if (!(this.countryList && this.countryList.length > 0)) {
      this.$store.dispatch('base/GetCountry', { lang: this.lang })
    }
  },
  methods: {
    // 获取当前国家名称
    getCurCountry(v) {
      const { countryList } = this
      let curInfo =
        countryList &&
        countryList.length > 0 &&
        countryList.find((item) => v == item.key)
      return curInfo ? curInfo : { name: '--', icon: 'china' }
    }
  }
}

export const unitMixi = {
  computed: {
    monetaryUnit() {
      return this.$store.getters.monetaryUnit
    },
    lang() {
      return this.$store.getters.lang
    }
  },
  mounted() {
    this.getAllBase()
  },
  methods: {
    // 获取基础数据
    getAllBase() {
      const { monetaryUnit } = this
      if (!monetaryUnit || monetaryUnit.length == 0) {
        this.$store
          .dispatch('base/getMonetaryUnit', {
            dictType: 'currency',
            lang: this.$store.getters.lang
          })
          .then(() => {})
      }
    },
    budgetHandle(obj, type) {
      if (!obj) {
        return ''
      }
      let isSpecial = false //最小值0或者最大值-以上 做特殊处理

      const { monetaryUnit, lang } = this
      let v = monetaryUnit.find((item) => item.value == obj.budgetUnit)
      let str = ''
      if (obj.budgetMin == 0 && obj.budgetMax == 0) {
        str = 0
      } else if (obj.budgetMin == 0 && obj.budgetMax > 0) {
        let max = Number(obj.budgetMax).toLocaleString()
        str =
          lang == 'zh'
            ? v.ext + ' ' + max + '以内'
            : 'Less than ' + v.ext + ' ' + max
        isSpecial = true
      } else if (obj.budgetMax == 0 && obj.budgetMin > 0) {
        let min = Number(obj.budgetMin).toLocaleString()
        str =
          lang == 'zh'
            ? v.ext + ' ' + min + '以上'
            : 'Above ' + v.ext + ' ' + min
        isSpecial = true
      } else {
        let max = Number(obj.budgetMax).toLocaleString()
        let min = Number(obj.budgetMin).toLocaleString()
        str = min + '~' + max
      }

      let name = v ? v.ext + '( ' + obj.budgetUnit + ' )' : '¥( RMB )'
      let singalUnit = v ? v.ext : '¥'
      if (name == undefined) {
        name = v.value ? v.value : '¥'
      }
      if (singalUnit == undefined) {
        singalUnit = v.value ? v.value : '¥'
      }
      if (isSpecial) {
        return str
      }
      return (type && type == 'singal' ? singalUnit : name) + ' ' + str
    },
    filterUnit(unit) {
      if (!unit) {
        return '¥'
      }
      let v = this.monetaryUnit.find((item) => item.value == unit)
      let u = v && v.ext ? v.ext : '¥'
      return u
    }
  }
}
