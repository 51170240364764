<template>
  <div class="im-contact">
    <span class="im-avatar">
      <el-avatar shape="square" :size="40" :src="avatar" />
    </span>
    <div class="im-contact-inner">
      <span class="im-username">
        {{ member.nickname }}
      </span>
    </div>
  </div>
</template>

<script>
const defaultAvatar = require('@/assets/images/default-avatar.png')
export default {
  name: 'AirContact',
  props: {
    /** 会话信息 **/
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      defaultAvatar,
      member: {}
    }
  },
  computed: {
    avatar() {
      const faceURL = this.member.faceURL
      if (!faceURL) {
        return defaultAvatar
      } else {
        if (faceURL.startsWith('http') || faceURL.startsWith('/')) {
          return faceURL
        } else {
          return '/api/crd/disk/file/loadFile/' + faceURL
        }
      }
    }
  },
  watch: {
    item: {
      handler(n) {
        this.member = n || {}
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.im-contact {
  // border-bottom: 1px solid #f2f3f5;
  padding: 8px;
  display: flex;
  cursor: pointer;
  // border: 1px solid transparent;
  border-radius: 8px;
  &.im-contact-active,
  &:hover {
    background: #4a606d;
    // border: 1px solid #f2f3f5;
  }
  .im-avatar {
    font-size: 0;
  }
  .im-contact-inner {
    flex: 1;
    width: 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .im-username {
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>
