<template>
  <div class="app-wrapper">
    <HeaderCommon mode="black" />
    <div class="content-box">
      <div class="router-content">
        <router-view :key="key" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderCommon from '@/components/HeaderCommon'
import Footer from '@/views/home/com/footerView.vue'

export default {
  components: {
    HeaderCommon,
    Footer
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    key() {
      return this.$route.path
    },
    unread() {
      return parseInt(this.$store.state.im.unread)
    }
  },
  methods: {
    handleShowIm() {
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  background: #f7f8fa;
  width: 100%;
  height: 100%;
  .header-wrap {
    background: #303e47;
  }
  .content-box {
    display: flex;
    flex: 1;
    overflow: hidden;
    width: 100%;
    .router-content {
      width: 100%;
      height: 100%;
      overflow: auto;
      //   padding: 24px 0px;
      background: #f7f8fa;
    }
  }
}
</style>
