<template>
  <div class="im-contact" :class="{ 'im-contact-active': current }">
    <span class="im-avatar">
      <el-badge
        :value="chat.unreadCount"
        :hidden="chat.unreadCount === 0"
        :max="99"
      >
        <el-avatar shape="square" :size="40" :src="avatar" />
      </el-badge>
    </span>
    <div class="im-contact-inner">
      <span class="im-username">
        {{ chat.showName }}
      </span>
    </div>
    <i
      class="iconfont icon-guanbi close"
      @click.stop="onDeleteConversation(chat)"
    ></i>
  </div>
</template>

<script>
const defaultAvatar = require('@/assets/images/default-avatar.png')
import { drawAvatar } from './utils/index'
export default {
  name: 'AirContact',
  props: {
    /** 会话信息 **/
    item: {
      type: Object,
      default: () => {}
    },
    active: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      defaultAvatar,
      chat: {}
    }
  },
  computed: {
    current() {
      return this.active === this.chat.conversationID
    },
    avatar() {
      if (this.chat.groupID) {
        const text = this.$store.getters.lang === 'en' ? 'G' : '群'
        return drawAvatar(text)
      } else {
        const faceURL = this.chat.faceURL
        if (!faceURL) {
          return defaultAvatar
        } else {
          if (faceURL.startsWith('http') || faceURL.startsWith('/')) {
            return faceURL
          } else {
            return '/api/crd/disk/file/loadFile/' + faceURL
          }
        }
      }
    }
  },
  watch: {
    item: {
      handler(n) {
        this.chat = n || {}
      },
      immediate: true
    }
  },
  methods: {
    onDeleteConversation(item) {
      this.$store.dispatch('im/deleteConversation', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.im-contact {
  // border-bottom: 1px solid #f2f3f5;
  position: relative;
  padding: 8px;
  display: flex;
  cursor: pointer;
  // border: 1px solid transparent;
  border-radius: 8px;
  &.im-contact-active,
  &:hover {
    background: #4a606d;
    // border: 1px solid #f2f3f5;
  }
  .im-avatar {
    font-size: 0;
  }
  .im-contact-inner {
    flex: 1;
    width: 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  .im-username {
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
  }
  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
