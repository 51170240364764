<template>
  <div class="menu-box">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        ref="menus"
        :default-active="$route.path"
        text-color="#666"
        active-text-color="#FB4236"
        @select="handleSelect"
        @open="handleOpen"
        @close="handleClose"
      >
        <sidebar-item
          v-for="route in linkRoutes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
export default {
  components: {
    SidebarItem
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['linkRoutes', 'sidebar', 'lang']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      this.$refs.menus.open(keyPath)
    },
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath)
    },
    handleSelect(v) {
      if (v == this.$route.path) {
        return
      }
      this.$router.push({ path: v }).catch(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-box {
  max-height: $content-height;
  overflow: hidden;

  // background-color: #ffffff;
  margin-right: 16px;
  ::v-deep {
    .el-scrollbar {
      height: 100%;
    }
    .el-scrollbar__wrap {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  .el-menu {
    border: 0;
    padding: 8px 8px 16px;
  }
  .el-menu--inline {
    padding: 0;
    margin-bottom: 0;
  }
  .el-menu-item {
    height: 38px;
    min-width: 212px;
    line-height: 38px;
    margin-bottom: 4px;
    padding-left: 16px !important;
    span {
      margin-left: 8px;
    }
    svg {
      font-size: 16px;
      vertical-align: -0.25em;
    }
  }
  .el-submenu .el-menu-item {
    min-width: 100px;
  }
  .el-submenu__icon-arrow,
  .el-icon-arrow-down {
    display: none;
  }
  .el-submenu__title {
    font-size: 12px;
    color: #999 !important;
    padding: 0 0 0 16px;
    height: 48px;
    line-height: 60px;
  }
  .el-submenu__title:hover {
    background: transparent;
  }
  .el-menu-item.is-active {
    background: #fff5f5 !important;
    border-radius: 4px;
  }
  .el-menu-item:hover {
    background: #f7f8fa;
    border-radius: 4px;
  }
}
</style>
