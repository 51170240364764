<template>
  <div class="im-wrapper">
    <!-- 侧边联系人 -->
    <div class="im-sidebar-wrap">
      <el-scrollbar style="height: 100%">
        <div v-show="mode === 'single'" class="im-sidebar">
          <air-contact :item="conversation" />
        </div>
        <div v-show="mode === 'group'" class="im-sidebar">
          <AirGroupMember
            v-for="item in groupMembers"
            :key="item.userID"
            :item="item"
          />
        </div>
        <div v-show="mode === 'all'" class="im-sidebar">
          <!-- <div class="">
        {{ cover.username }}
      </div> -->
          <air-contact
            v-for="item in conversationList"
            :key="item.conversationID"
            :active="conversation && conversation.conversationID"
            :item="item"
            @click.native="handleConClick(item)"
          />
          <el-empty
            v-if="conversationList.length === 0"
            image="https://res.airrnd.com/disk/load/948bbacd5f7b63e8f9d5e2c796012a49.png"
            :image-size="64"
            description="暂无会话"
          />
        </div>
      </el-scrollbar>
    </div>

    <div class="im-container">
      <div v-if="conversation && conversation.conversationID">
        <div class="im-container-title im-drag">
          <div class="title">{{ conversationName }}</div>
          <span class="im-close" @click="onClickClose">
            <i class="el-icon-close"></i>
          </span>
        </div>
        <!-- 聊天内容 -->
        <air-messages
          id="chat-body"
          ref="messagesRef"
          :has-more="hasMoreMsg"
          :messages="currentMsg"
          @retry="handleRetry"
          @load-more="handleLoadMore"
        />
        <!-- 信息编辑 -->
        <air-editor @send="onSendMessage" @upload="onUpload" />
      </div>
      <div
        v-show="!(conversation && conversation.conversationID)"
        class="im-empty im-drag"
      >
        <i class="el-icon-chat-round"></i>
      </div>
    </div>
  </div>
</template>

<script>
import AirMessages from '@/components/AirIm/AirMessages.vue'
import AirContact from '@/components/AirIm/AirContact.vue'
import AirEditor from '@/components/AirIm/AirEditor.vue'
import AirGroupMember from '@/components/AirIm/AirGroupMember.vue'
import emojiData from './data/emoji.js'
// import { socketUrl } from '@/config'
import {
  getImageInfo,
  initEmoji,
  uploadFile,
  createId,
  getFileType,
  // dd,
  throttle
} from '@/components/AirIm/utils/index.js'
import {
  messageTypes
  // CbEvents
  // pageCount
} from '@/components/AirIm/utils/openIM.js'
import mixin from '@/components/AirIm/mixin.js'
export default {
  name: 'AirIm',
  mixins: [mixin],
  components: {
    AirMessages,
    AirContact,
    AirEditor,
    AirGroupMember
  },
  provide() {
    return {
      setting: {
        showNickname: true
      }
    }
  },
  data() {
    return {
      // hasMore: false,
      /** 会话列表 **/
      // conversationList: [],
      /** 当前会话id **/
      // conversationID: '',
      /** 当前会话 **/
      // conversation: {},
      /** 一次拉取的最后一条消息ID **/
      // startClientMsgID: '',
      /** 会话消息 **/
      // currentMsg: []
      /** 当前消息 **/
    }
  },
  computed: {
    conversationName() {
      if (this.conversation) {
        if (this.conversation.groupID) {
          const text =
            this.$store.getters.lang === 'en' ? ' Group Conversation' : '的群聊'
          return this.conversation.showName + text
        } else {
          return this.conversation.showName
        }
      } else {
        return ''
      }
    }
  },
  watch: {
    // conversation(v) {
    //   this.handleConClick(v)
    // }
  },
  async created() {
    initEmoji(emojiData)
    // this.$store.dispatch('im/loginIM')
    // this.loginIM()
  },
  mounted() {
    this.chatDom = this.$refs?.messagesRef?.$el
    const scrollFn = throttle(500, true, (e) => {
      // 滚轮向上滚动
      if (e.wheelDeltaY === 120) {
        // 查找id = chat-body 的dom节点
        const containerDom = e.path.find((el) => {
          return el.id === 'chat-body'
        })
        if (containerDom?.scrollTop <= 1) {
          console.log('滚动加载更多')
          this.handleLoadMore()
        }
      }
    })
    if (this.chatDom) {
      this.chatDom.addEventListener('mousewheel', scrollFn)
    }
    //监听消息
    // const onRecMessage = (recData) => {
    //   this.refreshConversation(recData)
    //   this.appendMessage(recData, true)
    // }
    // openIM.on(CbEvents.OnRecvNewMessage, onRecMessage)
    this.$once('hook:beforeDestroy', function () {
      // openIM.off(CbEvents.OnRecvNewMessage, onRecMessage)
      this.chatDom && this.chatDom.removeEventListener('mousewheel', scrollFn)
    })
  },
  methods: {
    // async loginIM() {
    //   const loginSuccess = await this.getStatus()
    //   if (this.userInfo && !loginSuccess) {
    //     const config = {
    //       userID: this.userInfo.userId,
    //       token: this.userInfo.imToken,
    //       url: socketUrl,
    //       platformID: 5
    //     }
    //     await openIM.login(config)
    //   }
    //   // this.getConversation()
    //   this.$store.dispatch('im/getAllConversation')
    // },
    // async getStatus() {
    //   const result = await openIM.getLoginStatus()
    //   return result.errCode === 0
    // },
    // 拉去会话列表
    // getConversation() {
    //   openIM
    //     .getAllConversationList()
    //     .then((res) => {
    //       //注意 会话对象中latestMsg（会话最后一条消息）仍为Json字符串格式 若需要使用请自行转换
    //       if (res.errCode === 0) {
    //         console.log('JSON.parse(res.data)', JSON.parse(res.data))
    //         this.conversationList = JSON.parse(res.data)
    //       }
    //       console.log('getAllConversationList', res)
    //     })
    //     .catch((err) => {
    //       console.log('getAllConversationList error', err)
    //     })
    // },
    // refreshConversation(recData) {
    //   const data = JSON.parse(recData.data)
    //   if (data.sendID !== this.conversation.userID) {
    //     this.$store.dispatch('im/getAllConversation')
    //     // this.getConversation()
    //   }
    // },
    // sendMessageToIM(options) {
    //   console.log('sendMessageToIM', options)
    //   openIM
    //     .sendMessageNotOss(options)
    //     .then(({ data }) => {
    //       this.data.sendStatus = 1
    //       console.log('data', JSON.parse(data))
    //     })
    //     .catch((err) => {
    //       this.data.sendStatus = 0
    //       dd(err)
    //     })
    // },
    // 点击会话列表
    handleConClick(item) {
      this.$store.dispatch('im/setConversation', item).then(() => {
        this.scrollToBottom()
      })
      this.scrollToBottom()
      // if (item.conversationID !== this.conversation.conversationID) {
      //   this.setConversation(item)
      //   this.startClientMsgID = ''
      //   this.currentMsg = []
      //   this.getHistoryMsg(true)
      // }
      // console.log('item', item)
      // if (item.conversationID !== this.conversationID) {
      //   this.startClientMsgID = ''
      //   this.conversationID = item.conversationID
      //   this.conversation = item
      //   this.currentMsg = []
      //   this.getHistoryMsg(true)
      // }
    },
    handleLoadMore() {
      if (this.startClientMsgID && this.hasMoreMsg) {
        this.getHistoryMsg()
      }
    },

    /**
     * 拉取会话消息
     * @param bottom 设置滚动至底部
     */
    getHistoryMsg(bottom) {
      this.firstIds = this.startClientMsgID
      this.$store.dispatch('im/getHistoryMessageList').then(() => {
        if (bottom) {
          this.scrollToBottom()
        }
        if (!this.startClientMsgID) {
          this.firstIds = ''
        }
        this.setScrollPos()
        // this.setMessageAsRead()
      })
      // const options = {
      //   groupID: '', // 群聊ID，拉取群聊时传入，否则为“”
      //   startClientMsgID: this.startClientMsgID, // 上一次拉取的最后一条消息ID或空字符串,为空字符则从最新一条开始
      //   count: pageCount, // 每次拉取条数
      //   userID: this.conversation.userID // 用户ID，拉取单聊时传入，否则为“”
      // }
      // this.firstIds = this.startClientMsgID // 记录上次请求的第一条信息id
      // openIM
      //   .getHistoryMessageList(options)
      //   .then((res) => {
      //     dd(res)
      //     if (res.errCode === 0) {
      //       const msgList = JSON.parse(res.data)
      //       this.hasMore = msgList.length === pageCount
      //       this.currentMsg = [...msgList, ...this.currentMsg]
      //       if (bottom) {
      //         this.scrollToBottom()
      //       }
      //       this.startClientMsgID = msgList?.[0]?.clientMsgID || ''
      //       if (!this.startClientMsgID) {
      //         this.firstIds = ''
      //       }
      //       this.setScrollPos()
      //       this.setMessageAsRead()
      //     }
      //   })
      //   .catch((err) => {
      //     dd(err)
      //   })
    },
    // 设置会话已读
    // setMessageAsRead() {
    //   const options = {
    //     userID: this.conversation.userID,
    //     msgIDList: []
    //   }
    //   openIM
    //     .markC2CMessageAsRead(options)
    //     .then((res) => {
    //       if (res.errCode === 0) {
    //         const idx = this.conversationList.findIndex(
    //           (item) => item.conversationID === this.conversation.conversationID
    //         )
    //         if (idx != -1) {
    //           this.conversationList[idx].unreadCount = 0
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       dd(err)
    //     })
    // },
    handleRetry(message) {
      console.log('handleRetry', message)
    },
    // 发送文本消息
    async onSendMessage(text) {
      // const message = this.generateMessage({ content: text })
      // const message = await this.createMessage(
      //   { content: text },
      //   messageTypes.TEXTMESSAGE
      // )
      // console.log('message', message)
      // this.appendMessage(message)
      this.$store
        .dispatch('im/sendMessage', {
          type: messageTypes.TEXTMESSAGE,
          text: text
        })
        .then(() => {
          this.scrollToBottom()
        })
    },
    /**
     *  @param obj
     *  @param type 101:文本 102:图片
     * @returns {message}
     */
    // async createMessage(obj, type) {
    //   let data
    //   switch (type) {
    //     case messageTypes.TEXTMESSAGE:
    //       data = await openIM.createTextMessage(obj.content)
    //       break
    //     case messageTypes.PICTUREMESSAGE:
    //       data = await openIM.createImageMessage(obj)
    //       break
    //   }
    //   return data
    // },
    // 发送图片
    async onUpload(file) {
      console.log('file', file)
      const imageTypes = ['image/gif', 'image/jpeg', 'image/png']
      if (imageTypes.includes(file.type)) {
        const imgUrl = URL.createObjectURL(file)
        const img = await getImageInfo(imgUrl)
        const resUrl = await uploadFile(file)

        const baseInfo = {
          uuid: createId(), //图片唯一id
          type: getFileType(file), //图片类型
          size: file.size, //图片大小
          width: img.width, //图片宽度
          height: img.height, //图片高度
          url: resUrl //图片上传后url
        }
        const info = {
          sourcePicture: baseInfo, //原图信息
          bigPicture: baseInfo, //大图信息
          snapshotPicture: baseInfo //缩略图信息
        }
        this.$store
          .dispatch('im/sendMessage', {
            type: messageTypes.PICTUREMESSAGE,
            info
          })
          .then(() => {
            this.scrollToBottom()
          })
        // // const message = this.generateMessage(obj)
        // const message = await this.createMessage(
        //   obj,
        //   messageTypes.PICTUREMESSAGE
        // )
        // this.appendMessage(message)
      }
    },
    /**
     *
     * @param message
     * @param rec 是否是接收消息
     */
    // appendMessage(message, rec = false) {
    //   console.log('append', message)
    //   this.data = JSON.parse(message.data)
    //   if (!rec) {
    //     this.data.sendStatus = 2 // 消息正在发送
    //   }
    //   this.currentMsg.push(this.data)
    //   this.scrollToBottom()
    //   if (!rec) {
    //     this.handleSendMessage(message)
    //   }
    // },
    // handleSendMessage(message) {
    //   const offlinePushInfo = {
    //     title: 'you have a new message', // 推送标题
    //     desc: '', // 推送描述
    //     ex: '', // 扩展字段
    //     iOSPushSound: '', // ios推送声音
    //     iOSBadgeCount: false
    //   }
    //   const openIMMessage = message.data
    //   const options = {
    //     recvID: this.conversation.userID,
    //     groupID: '',
    //     offlinePushInfo: offlinePushInfo,
    //     message: openIMMessage // message为通过创建消息Api返回的任一类型的消息结构体Json字符串
    //   }
    //   console.log('options', options)

    //   this.sendMessageToIM(options)
    // },
    setScrollPos() {
      if (this.firstIds && this.startClientMsgID) {
        this.$nextTick(() => {
          const prevDom = document.querySelector(
            `[clientmsgid='${this.firstIds}']`
          )
          if (prevDom) {
            this.chatDom.scrollTop = prevDom.offsetTop - 100
          }
        })
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.messagesRef && this.$refs.messagesRef.scrollToBottom()
      })
    },
    onClickClose() {
      this.$emit('close')
      this.$store.dispatch('im/setConversation', null)
    }
  }
}
</script>
<style>
.im-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #c8c8c8;
}
.im-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.im-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: transparent;
  cursor: pointer;
}

.im-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bebebe;
}

.im-scrollbar::-webkit-scrollbar-thumb:active {
  background: #b0b0b0;
}
</style>
<style lang="scss" scoped>
.im-wrapper {
  //position: absolute;
  z-index: 5001;
  right: 24px;
  bottom: 24px;
  // width: 668px;
  margin: 0 auto;
  display: flex;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  // border: 1px solid #f2f3f5;
}
::selection {
  background: $color-primary;
  color: #fff;
}
.im-sidebar-wrap {
  width: 178px;
  height: 585px;
  overflow: hidden;
  background: #303e47;
  padding-top: 8px 0;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.im-sidebar {
  width: 178px;
  display: flex;
  flex-direction: column;
  background: #303e47;
  color: #ffffff;
  padding: 8px;
}

.im-container {
  width: 489px;
}
.im-container-title {
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 16px;
  position: relative;
  border-bottom: 1px solid #f2f3f5;
  line-height: 1;
  background: #fff;
  .title {
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
    max-width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span {
    font-size: 16px;
    font-weight: 500;
    text-shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
  }
  .im-close {
    position: absolute;
    right: 12px;
    top: 20px;
    font-size: 24px;
    color: #666;
    cursor: pointer;
  }
}
.im-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  color: #666;
  background: #fbfbfb;
  font-size: 60px;
}
</style>
