import Layout from '@/views/layout/indexView'
// import Layoutourist from '@/views/layout-tourist/indexView'
const findProject = [
  {
    path: '/find-project',
    component: Layout,
    meta: {
      title: 'menuBar.project',
      type: 'dp'
    },
    hidden: true,
    children: [
      {
        path: 'project-list',
        component: () => import('@/views/find-item/item-list/index.vue'),
        name: 'ProjectList',
        meta: {
          title: 'menuBar.myProject',
          icon: 'menu-my-project',
          isFull: true
        }
      },
      {
        path: 'project-details/:id',
        hidden: true,
        component: () =>
          import('@/views/find-item/item-list/project-details.vue'),
        name: 'ProjectDetails',
        meta: { isFull: true }
        // meta: { title: 'menuBar.myProject', icon: 'menu-my-project' }
      },
      {
        path: 'per-home-page',
        hidden: true,
        component: () =>
          import('@/views/find-item/personal-homepage/index.vue'),
        name: 'PerHomePage',
        meta: { isFull: true }
        // meta: { title: 'menuBar.myProject', icon: 'menu-my-project' }
      }
    ]
  }
]

export default findProject
