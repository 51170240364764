import request from '@/utils/request'

// 邮箱校验
export function vaildEmail(data) {
  return request({
    url: '/crd/user/checkMailbox',
    method: 'post',
    data
  })
}

// 注册
export function getRegisterOne(data) {
  return request({
    url: '/crd/user/regist/stepOne',
    method: 'post',
    data
  })
}
export function getRegister(data) {
  return request({
    url: '/crd/user/regist/stepTwo',
    method: 'post',
    data
  })
}

// 用户session
export function getSession(data) {
  return request({
    url: '/sys/user/auth/session',
    method: 'post',
    data
  })
}

// 手动发送邮箱验证
export function sendAccountEmail(data) {
  return request({
    url: '/crd/user/activateAccount/sendEmail',
    method: 'post',
    data
  })
}

// 登录
export function login(data) {
  return request({
    url: '/sys/user/auth/login',
    method: 'post',
    data
  })
}

// 退出方法
export function logout(data = {}) {
  return request({
    url: '/sys/user/auth/logout',
    method: 'post',
    data
  })
}

// 激活账号
export function getActivateAccount(data = {}) {
  return request({
    url: '/crd/user/activateAccount',
    method: 'post',
    data
  })
}

// 切换团队
export function getTeamChange(data = {}) {
  return request({
    url: '/sys/user/auth/change',
    method: 'post',
    data
  })
}

// 切换角色
export function getSessionPlus(data = {}) {
  return request({
    url: '/sys/user/auth/sessionPlus',
    method: 'post',
    data
  })
}

// 新增团队
export function createTeam(data = {}) {
  return request({
    url: '/crd/team/createTeam',
    method: 'post',
    data
  })
}

// 团队升级
export function upgradeTeam(data = {}) {
  return request({
    url: '/crd/team/upgradeTeam',
    method: 'post',
    data
  })
}

// 查询用户的团队
export function getListByUserId(data = {}) {
  return request({
    url: '/crd/team/queryListByUserId',
    method: 'post',
    data
  })
}

// 忘记密码发送邮件
export function sendMail(data = {}) {
  return request({
    url: '/crd/restPassword/sendMail',
    method: 'post',
    data
  })
}

// 修改密码
export function update(data = {}) {
  return request({
    url: '/crd/restPassword/update',
    method: 'post',
    data
  })
}

// 修改密码2
export function updatePassword(data = {}) {
  return request({
    url: '/crd/password/update',
    method: 'post',
    data
  })
}

// 修改团队信息
export function updateTeamInfo(data = {}) {
  return request({
    url: '/crd/team/update',
    method: 'post',
    data
  })
}

// 站内信条数
export function getCount(data = {}) {
  return request({
    url: '/crd/user/insideMsg/count',
    method: 'post',
    data
  })
}
/*================================以下在个人主页中使用==============================*/
/* 个人主页编辑 */
export function updateDes(data = {}) {
  return request({
    url: '/crd/user/personal/updateDes',
    method: 'post',
    data
  })
}

/* 删除团队项目附件 */
export function delProjFile(data = {}) {
  return request({
    url: '/crd/team/delProjFile',
    method: 'post',
    data
  })
}

// 个人主页编辑/新增项目信息
export function teamProjAdd(data = {}) {
  return request({
    url: '/crd/user/personal/teamProjAdd',
    method: 'post',
    data
  })
}
// 个人主页编辑/删除项目信息
export function teamProjDel(data = {}) {
  return request({
    url: '/crd/user/personal/teamProjDel',
    method: 'post',
    data
  })
}
/*================================个人主页中使用完毕==============================*/

// 查看团队技能
export function getSkillSelect(data = {}) {
  return request({
    url: '/crd/base/skill/select',
    method: 'post',
    data
  })
}

// 项目类型
export function getCatSelect(data = {}) {
  return request({
    url: '/crd/base/cat/select',
    method: 'post',
    data
  })
}

// 项目类型
export function sendInviteUrl(data = {}) {
  return request({
    url: '/crd/team/sendInviteUrl',
    method: 'post',
    data
  })
}

// 邀请好友落地页的 加入团队
export function joinTeam(data = {}) {
  return request({
    url: '/crd/team/join',
    method: 'post',
    data
  })
}

// 邀请好友落地页 加入团队 ( 已有账号 )
export function teamProjJoinForLogin(data = {}) {
  return request({
    url: '/crd/team/teamProjJoinForLogin',
    method: 'post',
    data
  })
}

// 邀请好友落地页 加入团队 (未有账号)
export function noAuthTeamProjJoin(data = {}) {
  return request({
    url: '/crd/team/noAuthTeamProjJoin',
    method: 'post',
    data
  })
}

// ========================基础接口==============

// 国家
export function getCountry(data = {}) {
  return request({
    url: '/crd/base/country/select',
    method: 'post',
    data
  })
}

// 工作语言
export function getWorkLang(data = {}) {
  return request({
    url: '/crd/base/workLang/select',
    method: 'post',
    data
  })
}

// IM登录获取imToken接口
export function getImToken(data = {}) {
  return request({
    url: '/sys/user/openIM/token',
    method: 'post',
    data
  })
}

// 所属行业
export function queryDictItemByType(data = {}) {
  return request({
    url: '/crd/base/queryDictItemByType',
    method: 'post',
    data
  })
}

// 查询用户头像
export function queryAvatar(data) {
  return request({
    url: '/crd/user/queryAvatar',
    method: 'post',
    data
  })
}

// 发送验证码
export function sendRegisterSms(data) {
  return request({
    url: '/crd/user/sendRegisterSms',
    method: 'post',
    data
  })
}

// 注册审核重新提交
export function registerCheckResubmit(data) {
  return request({
    url: '/crd/user/registerCheckResubmit',
    method: 'post',
    data
  })
}

// 注销申请查询
export function accountCancelQuery(data) {
  return request({
    url: '/crd/user/accountCancelQuery',
    method: 'post',
    data
  })
}
