<template>
  <section class="app-main router-box" :class="cls">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  props: {
    cls: {
      type: String,
      default: ''
    }
  },
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped>
.app-main {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.app-main_tips {
  height: calc(100% - 62px);
}
</style>
