export default {
  computed: {
    userInfo() {
      return this.$store.getters.session
    },
    conversation() {
      return this.$store.state.im.conversation
    },
    conversationList() {
      return this.$store.state.im.conversationList
    },
    startClientMsgID() {
      return this.$store.state.im.startClientMsgID
    },
    currentMsg() {
      return this.$store.state.im.currentMsg
    },
    hasMoreMsg() {
      return this.$store.state.im.hasMoreMsg
    },
    groupMembers() {
      return this.$store.state.im.groupMembers
    },
    mode() {
      return this.$store.state.im.mode
    },
    imDialogShow() {
      return this.$store.state.im.show
    }
  }
}
