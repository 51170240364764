import Cookies from 'js-cookie'
import i18n from '@/i18n'

const state = {
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  lang: Cookies.get('lang') || 'zh'
}

const mutations = {
  SET_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_LANG: (state, lang) => {
    state.lang = lang
    Cookies.set('lang', lang)
  }
}

const actions = {
  setDevice({ commit }, device) {
    commit('SET_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setLang({ commit }, lang) {
    commit('SET_LANG', lang)
    document.title = `AirRnd.com | ${i18n.t('home.top.title')}`
  }
}
const global = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default global
