import request from '@/utils/request'

// 投诉
export function createComplaint(data = {}) {
  return request({
    url: '/crd/complaint/create',
    method: 'post',
    data
  })
}
