import Layout from '../../views/layout/indexView'
import findProject from '@/router/modules/find-project'
import contract from '../modules/contract'
const router = [
  ...contract,
  {
    path: '/work',
    component: Layout,
    meta: {
      title: 'menuBar.workbench'
    },
    hidden: true,
    children: [
      {
        path: 'workbench',
        component: () => import('@/views/workbench/indexView.vue'),
        name: 'Workbench',
        meta: { title: 'menuBar.workbench', icon: 'menu-workbench' },
        hidden: true
      }
    ]
  },
  {
    path: '/projects',
    component: Layout,
    meta: {
      title: 'menuBar.project'
    },
    children: [
      // 发布项目-选择类型
      {
        path: 'publish-project',
        component: () => import('@/views/project/publish-project/indexView'),
        name: 'PublishProject',
        meta: {
          title: 'menuBar.publishProject',
          icon: 'menu-publish',
          isFull: true,
          type: 'hp'
        }
      },
      // 发布总价项目
      {
        path: 'publish-project-total',
        hidden: true,
        component: () =>
          import('@/views/project/publish-project/totalPrice/indexView'),
        name: 'PublishProjectTotal',
        meta: {
          title: 'menuBar.publishProject',
          icon: 'menu-publish',
          isFull: true,
          type: 'hp'
        }
      },
      // 发布工时项目
      {
        path: 'publish-project-work',
        component: () =>
          import('@/views/project/publish-project/workHours/indexView'),
        name: 'PublishProjectWorking',
        hidden: true,
        meta: {
          title: 'menuBar.publishProjectWorking',
          icon: 'menu-publish',
          isFull: true,
          type: 'hp'
        }
      },
      {
        path: 'my-projects/:type',
        component: () => import('@/views/project/my-projects/indexView'),
        name: 'MyProjects',
        meta: {
          title: 'menuBar.myProjects',
          icon: 'menu-my-project',
          activeMenu: '/projects/my-projects/:type'
        }
      },
      {
        path: 'my-evaluate',
        component: () => import('@/views/deal/evaluate/indexView'),
        name: 'MyEvaluate',
        meta: { title: 'menuBar.myEvaluate', icon: 'menu-evaluate' }
      },
      {
        path: 'my-bill',
        component: () => import('@/views/deal/reward/recordView'),
        name: 'MyBill',
        meta: { title: 'menuBar.myBill', icon: 'menu-bill', type: 'hp' }
      },
      {
        path: 'my-reward',
        component: () => import('@/views/deal/reward/recordView'),
        name: 'MyReward',
        meta: { title: 'menuBar.myReward', icon: 'menu-bill', type: 'dp' }
      },
      {
        path: 'cloud',
        component: () => import('@/views/setting/online-disk/stageDetail'),
        name: 'Cloud',
        meta: { title: 'menuBar.cloud', icon: 'menu-cloud', type: 'hp' },
        children: [
          {
            path: 'contract',
            component: () => import('@/views/setting/online-disk/indexView'),
            name: 'Contract',
            meta: {
              title: 'menuBar.cloud',
              icon: 'menu-cloud',
              activeMenu: '/projects/cloud'
            },
            hidden: true,
            children: [
              {
                path: 'phases',
                component: () =>
                  import('@/views/setting/online-disk/indexView'),
                name: 'Phases',
                meta: {
                  title: 'menuBar.cloud',
                  icon: 'menu-cloud',
                  activeMenu: '/projects/cloud'
                },
                hidden: true
              }
            ]
          }
        ]
      },
      {
        path: 'cloud/contract/phases/detail',
        component: () => import('@/views/setting/online-disk/stageDetail'),
        name: 'StageDetail',
        meta: {
          title: 'menuBar.cloud',
          icon: 'menu-cloud',
          activeMenu: '/projects/cloud'
        },
        hidden: true
      }
    ]
  },
  ...findProject,
  {
    path: '/deal',
    component: Layout,
    meta: {
      title: 'menuBar.deal'
    },
    hidden: true,
    children: [
      {
        path: 'my-tender-hp',
        component: () => import('@/views/deal/tender/indexView'),
        name: 'myTenderHp',
        meta: {
          // title: 'menuBar.myTender',
          // icon: 'menu-tender',
          // type: 'hp'
          isFull: true
        }
      },
      // {
      //   path: 'my-tender-dp',
      //   component: () => import('@/views/deal/tender/hpTender'),
      //   name: 'myTenderDp',
      //   meta: {
      //     title: 'menuBar.projectTender',
      //     icon: 'menu-tender',
      //     type: 'dp',
      //     activeMenu: '/projects/my-projects/:type'
      //   }
      // },
      /** 订单开始 */
      // {
      //   path: 'my-order',
      //   component: () => import('@/views/deal/order/indexList'),
      //   name: 'MyOrder',
      //   meta: { title: 'menuBar.myOrder', icon: 'menu-order' }
      // },
      {
        path: 'detail',
        component: () => import('@/views/deal/order/detailView'),
        name: 'Detail',
        meta: {
          title: 'menuBar.myOrder',
          icon: 'menu-order',
          activeMenu: '/projects/my-projects/:type'
        },
        hidden: true
      },
      // 个人开户表单页面（国内）
      {
        path: 'cn/create-personal-account',
        component: () =>
          import('@/views/deal/createAccount/China/personalView'),
        name: 'createPersonalAccount',
        meta: {
          activeMenu: '/projects/my-reward'
        },
        hidden: true
      },
      // 企业开户表单页面（国内）
      {
        path: 'cn/create-enterprise-account',
        component: () =>
          import('@/views/deal/createAccount/China/enterpriseView'),
        name: 'createEnterpriseAccount',
        meta: {
          activeMenu: '/projects/my-reward'
        },
        hidden: true
      },
      // 开户表单页面（国外）
      {
        path: 'en/create-account',
        component: () => import('@/views/deal/createAccount/abroad/indexView'),
        name: 'createAccount',
        meta: {
          activeMenu: '/projects/my-reward'
        },
        hidden: true
      }
      /** 订单结束 */
    ]
  },
  {
    path: '/bill',
    component: Layout,
    meta: {
      title: 'menuBar.deal'
    },
    hidden: true,
    children: [
      // 个人开户表单页面（国内）
      {
        path: 'cn/create-personal-account',
        component: () =>
          import('@/views/deal/createAccount/China/personalView'),
        name: 'createPersonalAccount',
        meta: {
          activeMenu: '/projects/my-bill'
        },
        hidden: true
      },
      // 企业开户表单页面（国内）
      {
        path: 'cn/create-enterprise-account',
        component: () =>
          import('@/views/deal/createAccount/China/enterpriseView'),
        name: 'createEnterpriseAccount',
        meta: {
          activeMenu: '/projects/my-bill'
        },
        hidden: true
      },
      // 开户表单页面（国外）
      {
        path: 'en/create-account',
        component: () => import('@/views/deal/createAccount/abroad/indexView'),
        name: 'createAccount',
        meta: {
          activeMenu: '/projects/my-bill'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/team',
    component: Layout,
    name: 'Team',
    meta: {
      title: 'menuBar.team',
      title2: 'menuBar.team2'
    },
    children: [
      {
        path: 'team-member',
        component: () => import('@/views/team/members/indexView'),
        name: 'TeamMember',
        meta: {
          title: 'menuBar.teamMember',
          icon: 'menu-team-member',
          title2: 'menuBar.teamMember2'
        }
      },
      {
        path: 'team-info/:type',
        component: () => import('@/views/team/information/infoView'),
        name: 'TeamInfo',
        meta: {
          title: 'menuBar.teamInfo',
          icon: 'menu-team-info',
          title2: 'menuBar.teamInfo2',
          activeMenu: '/team/team-info/:type'
        }
      },
      {
        path: 'identity',
        component: () => import('@/views/team/identity/indexView'),
        name: 'Identity',
        meta: {
          title: 'menuBar.identity',
          icon: 'menu-identity',
          activeMenu: '/team/team-info/:type'
        },
        hidden: true
      },
      // 企业认证 => 国内企业 / 国外企业
      {
        path: 'identity/domestic',
        component: () =>
          import('@/views/team/identity/domestic/firm/indexView'),
        name: 'IdentityDomestic',
        meta: {
          title: 'menuBar.identity',
          icon: 'menu-identity',
          activeMenu: '/team/team-info/cert'
        },
        hidden: true
      },
      // 企业=>国外
      {
        path: 'identity/foreign',
        component: () =>
          import('@/views/team/identity/abroad/abroad-enterprise'),
        name: 'IdentityForeign',
        meta: {
          title: 'menuBar.identity',
          icon: 'menu-identity',
          activeMenu: '/team/team-info/cert'
        },
        hidden: true
      },
      // 个人 => 国外
      {
        path: 'identity/abroadper',
        component: () =>
          import('@/views/team/identity/abroad/abroad-personal.vue'),
        name: 'IdentityAbroadPer',
        meta: {
          title: 'menuBar.identity',
          icon: 'menu-identity',
          activeMenu: '/team/team-info/cert'
        },
        hidden: true
      }
      // {
      //   path: 'upgrade',
      //   name: 'Upgrade',
      //   meta: {
      //     title: 'menuBar.upgrade',
      //     icon: 'menu-upgrade',
      //     isFull: true
      //   }
      // }
    ]
  },
  {
    path: '/setting',
    component: Layout,
    meta: {
      title: 'menuBar.setting'
    },
    children: [
      {
        path: 'homepage',
        component: () => import('@/views/setting/hp-homepage/indexView'),
        name: 'HpHomepage',
        meta: {
          title: 'menuBar.myHomePage',
          icon: 'menu-personal',
          type: 'hp',
          isFull: true
        },
        hidden: true
      },
      {
        path: 'my-home-page',
        component: () => import('@/views/setting/my-home-page/indexView'),
        name: 'myHomePage',
        meta: {
          title: 'menuBar.myHomePage',
          icon: 'menu-personal',
          type: 'dp',
          isFull: true
        },
        hidden: true
      },
      {
        path: 'my-home-page-view',
        component: () => import('@/views/setting/my-home-page/viewPage'),
        name: 'myHomePageView',
        meta: {
          title: 'menuBar.myHomePage',
          icon: 'menu-personal',
          // type: 'dp',
          isFull: true
        },
        hidden: true
      },
      {
        path: 'personal-set',
        component: () => import('@/views/setting/personal-setting/indexView'),
        name: 'PersonalSet',
        meta: { title: 'menuBar.personal', icon: 'menu-personal' },
        hidden: true
      },
      {
        path: 'security',
        component: () => import('@/views/setting/security/indexView'),
        name: 'Security',
        meta: { title: 'menuBar.security', icon: 'menu-password' },
        hidden: true
      },
      {
        path: 'invite',
        component: () => import('@/views/setting/invite/indexView.vue'),
        name: 'Invite',
        meta: { title: 'menuBar.invite', icon: 'menu-invite' },
        hidden: true
      },
      {
        path: 'letter',
        component: () => import('@/views/setting/letter/indexView.vue'),
        name: 'Letter',
        meta: { title: 'menuBar.letter', icon: 'menu-letter' }
      },
      // 常见问题与解答
      {
        path: 'questions',
        name: 'Questions',
        meta: {
          title: 'menuBar.questions',
          icon: 'menu-question',
          isFull: true
        }
      }
    ]
  }
]
export default router
