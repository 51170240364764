export default {
  code: {
    0: 'Success',
    999999: '系统异常',
    990000: '网关异常',
    990105: 'Token校验失败或者已失效,请重新登录',
    990108: '更新请求信息失败',
    990109: '签名校验失败',
    888801:
      '创建DocuSign账户，请去邮箱里面激活DocuSign账户，否则会影响后续合同签署问题', // 新增加
    100001: '参数不能为空',
    100002: '验证码无效',
    100003: '发送验证码失败',
    100101: '邀请码已失效',
    100102: '注册失败',
    100103: '用户已激活',
    100104: '邮箱已注册',
    100105: '邀请码错误',
    100106: '激活码无效',
    100107: '用户未获得邀请',
    100108: '修改密码链接已失效，请重新发送修改链接',
    100109: '原密码错误',
    100201: '团队不存在',
    100202: '账户不存在',
    100203: '银行卡不存在',
    100204: '团队未认证',
    100205: '加入团队失败',
    100206: '发票信息不完整，请补齐再继续操作',
    100301: '项目不存在',
    100302: '项目交付信息不存在',
    100303: '项目评论不存在',
    100304: '项目状态异常',
    100305: '项目终止申请已存在',
    100306: '项目终止申请不存在',
    100307:
      '根据规则，投标前请先绑定您的银行卡。您可在”我的报酬“模块中绑定您的银行卡',
    100308: '项目评论已存在',
    100309: '项目邀请发送失败',
    100310: '不能选择自己发布的项目',
    100311: '您已参与投标了',
    100312: '收款账户不存在',
    100313: '项目还未验收结束',
    100314: '还有未完成的支付',
    100315: '项目验收失败,您还有未完成的合同', // 新增加
    100316: '请确认所有待支付的工时账单都已验收并支付', // 新增加
    100331: '合同模板不存在',
    100332: '合同不存在',
    100333: '合同状态异常',
    100334: '合同签署信息已存在',
    100335: '合同签署失败',
    100336: '合同签署服务异常',
    100337: '合同已签署',
    100338: '合同重新发起签署失败，请联系管理员', // 新增加
    100339: 'DocuSign发送签署邮件失败', // 新增加
    100340: '甲方用户不存在', // 新增加
    100341: '乙方用户不存在', // 新增加
    100401: '用户不存在',
    100402: '用户名或密码错误',
    100403: '获取用户IMToken失败',
    100404: '用户已激活,无需重复发送激活邮件',
    100405: '用户DocuSign账户激活失败', // 新增加
    100501: '文件上传失败',
    100502: '文件不存在',
    100601: '网盘不存在',
    100602: '父文件夹不存在',
    100603: '文件夹不存在',
    100604: '网盘空间不足',
    100605: '文件不存在',
    100701: '竞标记录不存在',
    100702: '已参与投标，不能重复竞投',
    100801: '认证失败',
    60000001: '获取认证链接错误;原因:参数错误：身份证号格式错误',
    101001: '当前用户开户审核中，不要重复开户',
    101002: '开通支付账户失败',
    101003: '第三方支付账户未开通',
    101004: '商户信息异常',
    101005: '商户预下单失败',
    101006: '已开通企业账户',
    101007: '修改支付账户失败',
    100317: '已有用户参与了竞标不能取消发布',
    100344: '合同金额不能低于200',
    100318: '项目已结束',
    100319: '项目类型异常',
    100320: '工时卡不存在',
    100321: '工时卡已存在',
    100322: '工时卡已确认，不可进行保存/提交操作',
    101011: '已完成支付，请刷新页面',
    100112: '手机号已注册',
    100406: '该手机号已注册',
    100407: '用户账户注销申请中',
    100408: '用户账户注销申请异常',
    100409: '用户注册审核记录不存在'
  }
}
