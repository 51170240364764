// function moverLayer(downEvent) {
//   let timer = null
//   const target = downEvent.target
//   target.style.cursor = 'move'
//   const elStyle = window.getComputedStyle(this)
//   const left = parseFloat(elStyle.left || '0')
//   const top = parseFloat(elStyle.top || '0')
//   const move = (moveEvent) => {
//     if (!timer) {
//       const _top = moveEvent.clientY - downEvent.clientY + top
//       const _left = moveEvent.clientX - downEvent.clientX + left
//       timer = setTimeout(() => {
//         timer = null
//       }, 10)
//       Object.assign(this.style, {
//         top: `${_top}px`,
//         left: `${_left}px`
//       })
//     }
//   }
//   const up = () => {
//     target.style.cursor = 'grab'
//     document.removeEventListener('mousemove', move)
//     document.removeEventListener('mouseup', up)
//   }
//   document.addEventListener('mousemove', move)
//   document.addEventListener('mouseup', up)
// }
function moverLayerEx(downEvent) {
  let timer = null
  const el = this.root
  const handler = this.handler
  const dom = this.dom
  const target = downEvent.target
  if (handler) {
    if (el.querySelector(handler) !== target) {
      return
    }
  }
  const targetPrevCursor = target.style.cursor
  target.style.cursor = 'move'
  const elStyle = window.getComputedStyle(dom)
  const left = parseFloat(elStyle.left || '0')
  const top = parseFloat(elStyle.top || '0')
  const move = (moveEvent) => {
    if (!timer) {
      const _top = moveEvent.clientY - downEvent.clientY + top
      const _left = moveEvent.clientX - downEvent.clientX + left
      timer = setTimeout(() => {
        timer = null
      }, 10)
      Object.assign(dom.style, {
        top: `${_top}px`,
        left: `${_left}px`
      })
    }
  }
  const up = () => {
    target.style.cursor = targetPrevCursor
    document.removeEventListener('mousemove', move)
    document.removeEventListener('mouseup', up)
  }
  document.addEventListener('mousemove', move)
  document.addEventListener('mouseup', up)
}
export default {
  bind(el, binding) {
    const bv = binding.value
    let sel
    let dom
    if (bv) {
      sel = bv
      if (Object.prototype.toString.call(bv) === '[object Object]') {
        sel = bv.drag
        dom = bv.dom
      }
    }
    // if (sel) {
    //   sel = el.querySelector(sel)
    // }
    if (dom) {
      dom = el.querySelector(dom)
    }
    // console.log('sel', sel, bv.drag)
    // sel = sel || el
    dom = dom || el
    // sel.style.cursor = 'grab'
    // sel.addEventListener('mousedown', moverLayer.bind(dom))
    el.addEventListener(
      'mousedown',
      moverLayerEx.bind({
        root: el,
        handler: sel,
        dom
      })
    )
  }
}
