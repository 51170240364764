import { loadDicts } from '@/api/sys'
import Cookies from 'js-cookie'
const dict = {
  namespaced: true,
  state: {
    info: {}
  },
  mutations: {
    SET_DICT_DATA(state, payload) {
      state.info[payload['key']] = payload['data']
    },
    DEL_DICT_KEY(state, key) {
      delete state.info[key]
    },
    CLEAR_DICT(state) {
      state.info = {}
    }
  },
  actions: {
    LoadDicts({ commit, state }, payload) {
      console.log('LoadDicts payload', payload)
      const force = payload['force'] || false
      const keys = payload['keys'] || []
      if (keys.length === 0) {
        return
      } else {
        let queryKeys = []
        if (force) {
          queryKeys = keys
        } else {
          const eKeys = Object.keys(state.info)
          keys.forEach((e) => {
            if (!eKeys.includes(e)) {
              queryKeys.push(e)
            }
          })
        }
        if (queryKeys.length > 0) {
          return new Promise((resolve, reject) => {
            const locale = Cookies.get('language') || 'zh'
            loadDicts({ types: queryKeys, lang: locale })
              .then((res) => {
                const data = res.data
                data &&
                  Object.keys(data).forEach((k) => {
                    commit('SET_DICT_DATA', {
                      key: k,
                      data: data[k]
                    })
                  })
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
          })
        }
      }
    },
    ClearDict({ commit }) {
      commit('CLEAR_DICT')
    },
    DelDictKey({ commit }, key) {
      commit('DEL_DICT_KEY', key)
    }
  }
}

export default dict
