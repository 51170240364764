import Cookies from 'js-cookie'

const TokenKey = 'User-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 记住密码
const passInfo = 'passInfo'
export function getPassInfo() {
  let r = Cookies.get(passInfo)
  return r ? JSON.parse(r) : ''
}

export function setPassInfo(info, date) {
  return Cookies.set(passInfo, info, { expires: date })
}

export function removePassInfo() {
  return Cookies.remove(passInfo)
}
