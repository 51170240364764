const disk = {
  namespaced: true,
  state: {
    allFolders: [], // 所有的目录 [{id:1, name:'目录名称'}]
    specialFolders: [] // 自定的目录
  },
  mutations: {
    // 加入目录
    PUSH_TO_ALL(state, payload) {
      state.allFolders.push(payload)
    },
    // 加入目录
    PUSH_TO_SPECIAL(state, payload) {
      state.specialFolders.push(payload)
    },
    // 跳转到指定目录
    CHANGE_TO_ALL(state, payload) {
      const idx = state.allFolders.findIndex((item) => item.id === payload.id)
      if (idx !== -1) {
        state.allFolders.splice(idx + 1)
      } else {
        state.allFolders.push(payload)
      }
    },
    // 跳转到指定目录
    CHANGE_TO_SPECIAL(state, payload) {
      const idx = state.specialFolders.findIndex(
        (item) => item.id === payload.id
      )
      if (idx !== -1) {
        state.specialFolders.splice(idx + 1)
      } else {
        state.specialFolders.push(payload)
      }
    },
    CLEAR_ALL(state) {
      state.allFolders = []
    },
    CLEAR_SPECIAL(state) {
      state.specialFolders = []
    }
  }
}
export default disk
