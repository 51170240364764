<template>
  <message-basic class="im-message-text" v-bind="$attrs" v-on="$listeners">
    <!-- 作用域插槽 对应组件message: { message: { content } } -->
    <template v-slot:default="{ message }">
      <span v-html="renderText(message.content)"></span>
    </template>
  </message-basic>
</template>

<script>
import MessageBasic from '@/components/AirIm/message/Basic.vue'
import { emojiNameToImage } from '@/components/AirIm/utils/index.js'
export default {
  name: 'MessageText',
  inheritAttrs: false,
  components: {
    MessageBasic
  },
  mounted() {},
  methods: {
    renderText(str) {
      return emojiNameToImage(str)
    }
  }
}
</script>
<style lang="scss">
.im-message-text {
  .im-message-content {
    img {
      height: 20px;
      padding: 0 2px;
      pointer-events: none;
      position: relative;
      vertical-align: middle;
      border-style: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.im-message-text {
  img {
    width: 22px;
    height: 22px;
  }
}
</style>
