import { getToken } from '@/utils/auth'
import router from './router'
import store from './store'
import { MessageBox } from 'element-ui'
import i18n from './i18n'
const whiteList = [
  '/login',
  '/home',
  '/register',
  '/register/result',
  '/login/forget',
  '/login/reset',
  '/login/forget/over',
  '/middle',
  '/printContract',
  '/privacy/zh',
  '/privacy/en',
  '/support/help',
  '/company/culture',
  '/link',
  '/answer/questions',
  '/members',
  '/why',
  '/recruit'
]
const interceptList = [
  '/projects/publish-project', // 发项目
  '/projects/my-projects/project', // 我的项目
  '/projects/my-projects/order', // 项目列表
  '/team/team-member', // 团队成员
  '/projects/my-evaluate', // 我的评价
  '/projects/my-bill', // 我的账单
  '/projects/my-reward',
  '/projects/my-evaluate',
  '/team/team-info/base'
]
router.beforeEach((to, from, next) => {
  const token = getToken()
  store.dispatch('permission/generateLinkRoutes', 'link').then(() => {})
  if (token !== undefined && token) {
    let session = store.getters.session
    if (!session) {
      store.dispatch('user/GetSession').then((res) => {
        store.dispatch('im/loginIM')
        const { teamType, userStatus, check } = res.data
        store.dispatch('permission/generateRoutes', teamType).then(() => {})
        // 国外注册审核未通过拦截
        if (
          userStatus === '1' &&
          (check.status === '1' || check.status === '3')
        ) {
          next({ path: '/register/audit' })
          return
        }
        // 账号已经注销
        if (userStatus === '4') {
          store.dispatch('user/LogOut').then(() => {
            next({ path: '/login' })
          })
          return
        }
        // 查询当前账户是否注销中
        store.dispatch('user/GetAccountStatus').then((data) => {
          if (data?.status === '1' || data?.status === '2') {
            next({ path: '/withdrawApplyPage' })
            return
          }
          // 注销申请被驳回，返回工作台
          if (data?.status === '3' && to.path === '/withdrawApplyPage') {
            next({ path: '/work/workbench' })
          }
        })
        next()
      })
    }
    if (interceptList.indexOf(to.path) !== -1) {
      if (session.userStatus === '0') {
        MessageBox.alert(i18n.t('permis.toActivate'), '', {
          confirmButtonText: i18n.t('btns.toActivate'),
          callback: () => {
            next({ path: '/register/active' })
          }
        })
      } else if (session.userStatus === '1') {
        let text =
          Number(session.isCert) > 0
            ? i18n.t('permis.inCert')
            : i18n.t('permis.toCert')
        MessageBox.alert(text, '', {
          confirmButtonText: i18n.t('btns.toCert'),
          callback: () => {
            next({
              path: '/team/team-info/cert'
            })
          }
        })
      } else {
        next()
      }
    }
    next()
  } else {
    /* has no token*/
    const idx = whiteList.findIndex((u) => to.path.indexOf(u) === 0)
    if (idx !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next('/login')
    }
  }
})
