import axios from 'axios'
import dayjs from 'dayjs'
import { uploadUrl, emojiDir } from '@/config'
import SparkMD5 from 'spark-md5'
//清除字符串内的所有HTML标签，除了IMG
export function clearHtmlExcludeImg(str) {
  return str.replace(/<(?!img).*?>/gi, '')
}

export function getPath(name) {
  return `@/components/AirIm/data/img/${name}`
}

/**
 * 将emoji图片转为[!code]
 * @param str
 * @returns {*}
 */
export function emojiImageToName(str) {
  return str.replace(/<img data-code="([^"]*?)" [^>]*>/gi, '[!$1]')
}
const emojiMap = {}
export function initEmoji(data) {
  data.forEach(({ code, url }) => (emojiMap[code] = url))
}
export function emojiNameToImage(str) {
  return str.replace(/\[!(\w+)\]/gi, (str, match) => {
    const file = match
    return emojiMap[file]
      ? `<img emoji-name="${match}" src="${emojiDir}${file}.png" />`
      : `[!${match}]`
  })
}

/**
 * 根据节点添加换行符
 * @param msg
 * @returns {string}
 */
export function formatTextMsg(msg = '') {
  const div = document.createElement('div')
  div.innerHTML = msg
  var i = []
  var n = function (e) {
    e.forEach(function (e) {
      if (e.nodeType === 1) {
        var t = e
        if (t.childNodes.length > 0) {
          i.push('\n')
          n(t.childNodes)
        }
      } else {
        e.nodeType === 3 && i.push(e.nodeValue || '')
      }
    })
  }
  n(div.childNodes)
  return i.join('')
}

export function getImageInfo(imgUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height
      })
    img.onerror = reject
    img.src = imgUrl
  })
}

/**
 *
 * @param file 文件对象
 * @returns {Promise<string|*>}
 */
export async function uploadFile(file) {
  let params = new FormData() // 创建form对象
  params.append('file', file)
  let config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  const res = await axios.post(uploadUrl, params, config)
  const { data } = res
  if (data.sucess) {
    return data.url
  } else {
    return ''
  }
}

/**
 * 生成id
 * @param length
 * @returns {string}
 */
export function createId(length = 8) {
  return Math.random()
    .toString(16)
    .slice(2, 2 + length)
}

export function getFileType(file) {
  const fileMap = {
    'image/gif': 'gif',
    'image/jpeg': 'jpg',
    'image/png': 'png'
  }
  return fileMap[file.type]
}

export function dd(result) {
  console.log(result.event, result)
}

export function consoleDom(dom) {
  const obj = {}
  for (const prop in dom) {
    obj[prop] = dom[prop]
  }
  console.log(obj)
}

/**
 * @param  {Number}   [delay]
 * @param  {boolean}   [noTrailing] -   可选，默认为false。如果noTrailing为true, callback将只在调用函数时执行每一个延迟毫秒。如果noTrailing为false或未指定，callback将在最后一次函数调用之后执行最后一次。(在没有为延迟毫秒调用throttled函数之后，内部计数器被重置)
 * @param  {Function}  callback
 * @param  {boolean}   [debounceMode] - If `debounceMode` is true (at begin), schedule `clear` to execute after `delay` ms. If `debounceMode` is false (at end),
 *                                    schedule `callback` to execute after `delay` ms.
 *
 * @returns {Function}
 */
export function throttle(delay, noTrailing, callback, debounceMode) {
  let timeoutID
  let cancelled = false

  let lastExec = 0

  function clearExistingTimeout() {
    if (timeoutID) {
      clearTimeout(timeoutID)
    }
  }

  function cancel() {
    clearExistingTimeout()
    cancelled = true
  }

  if (typeof noTrailing !== 'boolean') {
    debounceMode = callback
    callback = noTrailing
    noTrailing = undefined
  }

  function wrapper(...arguments_) {
    const self = this
    const elapsed = Date.now() - lastExec

    if (cancelled) {
      return
    }

    function exec() {
      lastExec = Date.now()
      callback.apply(self, arguments_)
    }

    function clear() {
      timeoutID = undefined
    }

    if (debounceMode && !timeoutID) {
      exec()
    }

    clearExistingTimeout()

    if (debounceMode === undefined && elapsed > delay) {
      exec()
    } else if (noTrailing !== true) {
      timeoutID = setTimeout(
        debounceMode ? clear : exec,
        debounceMode === undefined ? delay - elapsed : delay
      )
    }
  }

  wrapper.cancel = cancel

  return wrapper
}

export function relativeTime(
  time,
  format = { week: 'dddd', date: 'YY/MM/DD' },
  formatStr
) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000
  const weekStart = dayjs().startOf('week').valueOf()
  const weekEnd = dayjs().endOf('week').add(1, 'day').valueOf()
  if (dayjs().isSame(time, 'day')) {
    return dayjs(time).format('HH:mm')
  } else if (diff < 3600 * 24 * 2) {
    return `${formatStr} ${dayjs(time).format(`HH:mm`)}`
  } else if (weekStart < time && time < weekEnd) {
    return dayjs(time).format(format.week)
  }
  return dayjs(time).format(format.date)
}

/**
 * 获取文件MD5
 * @param file
 * @returns {Promise<unknown>}
 */
export function createFileMd5(file) {
  return new Promise((resolve, reject) => {
    // 创建FileReader实例
    const fileReader = new FileReader()
    // 开始读文件
    fileReader.readAsBinaryString(file)
    // 文件读完之后，触发load事件
    fileReader.onload = (e) => {
      // e.target就是fileReader实例
      // result是fileReader读到的部分
      const result = e.target.result
      // 如果读到的长度和文件长度一致，则读取成功
      const isSuccess = file.size === result.length
      // 读取成功，则生成MD5，扔出去。失败就报错
      isSuccess
        ? resolve(SparkMD5.hashBinary(result))
        : reject(new Error('读取出错了'))
    }
    //   读取过程中出错也直接报错
    fileReader.onerror = () => reject(new Error('读取出错了'))
  })
}

export function drawAvatar(text, size = 40, opts = {}) {
  opts = opts || {}
  const font = opts.font || 'normal 22px sans-serif'
  const textAlign = opts.textAlign || 'center'
  const textBaseline = opts.textBaseline || 'middle'
  const bgColor = opts.bgColor || '#fa413b' // '#c0c4cb'
  const fontColor = opts.fontColor || '#ffffff'
  // 创建画布
  let canvas = document.createElement('canvas')
  // 绘制文字环境
  let context = canvas.getContext('2d')
  // 设置字体
  context.font = font
  // 获取字体宽度
  let width = context.measureText(text).width
  // 如果宽度不够 240
  if (width < size) {
    width = size
  }
  // 画布宽度
  canvas.width = width
  // 画布高度
  canvas.height = width
  // 填充白色
  context.fillStyle = bgColor
  // 绘制文字之前填充白色
  context.fillRect(0, 0, canvas.width, canvas.height)
  // 设置字体
  context.font = font
  // 设置水平对齐方式
  context.textAlign = textAlign
  // 设置垂直对齐方式
  context.textBaseline = textBaseline
  // 设置字体颜色
  context.fillStyle = fontColor
  // 绘制文字（参数：要写的字，x坐标，y坐标）
  context.fillText(text, canvas.width / 2, canvas.height / 2)
  // 生成图片信息
  let dataUrl = canvas.toDataURL('image/png')
  return dataUrl
}
