<template>
  <div
    class="im-message"
    :class="[{ 'im-message-reverse': message.sendID === userInfo.userId }]"
  >
    <div class="im-avatar">
      <el-avatar shape="square" :size="40" :src="avatar" />
    </div>
    <div class="im-message-inner">
      <div class="im-message-title" v-if="setting.showNickname">
        <span>{{ message.senderNickname }}</span>
      </div>
      <div class="im-message-main">
        <div class="im-message-content">
          <slot v-bind:message="message"></slot>
        </div>
        <div class="im-message-status">
          <i
            v-if="message.status === 3"
            class="el-icon-warning"
            @click="onRetry(message)"
          />
          <i v-else-if="message.status === 1" class="el-icon-loading"></i>
          <i
            v-else-if="
              message.sendID !== userInfo.userId &&
              message.status !== 3 &&
              message.status !== 1
            "
            class="iconfont icon-jubao complaints-icon"
            @click="complaintsHandle(message)"
          />
        </div>
      </div>
    </div>
    <!-- 举报弹窗 -->
    <ReportDialog :visible.sync="reportVisible" :reportInfo="reportInfo" />
  </div>
</template>

<script>
import mixin from '@/components/AirIm/mixin.js'
import ReportDialog from '@/components/ReportDialog.vue'
const defaultAvatar = require('@/assets/images/default-avatar.png')
export default {
  name: 'MessageBasic',
  mixins: [mixin],
  inject: ['setting'],
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    message: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    ReportDialog
  },
  data() {
    return {
      reportVisible: false, // 举报弹窗
      reportInfo: null // 投诉的信息
    }
  },
  computed: {
    avatar() {
      this.$store.dispatch('im/queryAvatar', [this.message.sendID])
      const ac = this.$store.state.im.avatarCache || []
      const item = ac.find((item) => item.userId === this.message.sendID)
      if (item) {
        const faceURL = item.avatar
        if (!faceURL) {
          return defaultAvatar
        } else {
          if (faceURL.startsWith('http') || faceURL.startsWith('/')) {
            return faceURL
          } else {
            return '/api/crd/disk/file/loadFile/' + faceURL
          }
        }
      } else {
        return defaultAvatar
      }
    }
  },
  mounted() {},
  methods: {
    onRetry() {
      this.$emit('retry', this.message)
    },
    // 投诉
    complaintsHandle(msg) {
      this.reportInfo = msg
      this.reportVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.im-message {
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  scroll-snap-align: center;
  &.im-message-reverse {
    flex-flow: row-reverse;
    color: #fff;
    .im-message-title {
      text-align: right;
    }
    .im-message-main {
      flex-flow: row-reverse;
    }
  }
}
.im-message-inner {
  padding: 0 8px;
  .im-message-title {
    font-size: 12px;
    margin-bottom: 4px;
    color: #666;
  }
}
.im-message-main {
  display: flex;
}
.im-message-status {
  width: 40px;
  flex: none;
  display: flex;
  align-items: center;
  i {
    font-size: 16px;
    margin: 0 8px 0 auto;
  }
  .el-icon-warning {
    color: #f56c6c;

    cursor: pointer;
  }
  .el-icon-loading {
    color: #999;
  }
  .complaints-icon {
    cursor: pointer;
    color: #999;
  }
}
.im-message-content {
  position: relative;
  background: #fff;
  line-height: 20px;
  padding: 8px 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  &:before {
    content: ' ';
    position: absolute;
    top: 6px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    left: -5px;
    border-left: none;
    border-right-color: #fff;
  }
}
.im-message-reverse {
  .im-message-content {
    background: #303e47;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
    &:before {
      left: auto;
      right: -5px;
      border-right: none;
      border-left: 5px solid #303e47;
    }
  }
}
</style>
