<template>
  <el-dialog
    :title="$t('login.addTeam')"
    :visible.sync="isVisible"
    width="440px"
    :before-close="handleClose"
    class="add-team"
  >
    <div>
      <el-form :model="twoForm" ref="twoForm" class="twoForm">
        <div class="top">
          <img
            src="https://res.airrnd.com/disk/load/2fa901fd18a338a7bf18a21cebc261c0.png"
            alt=""
          />
        </div>
        <el-form-item label="" prop="teamName" :rules="rules.teamName">
          <el-input
            v-model="twoForm.teamName"
            :placeholder="$t('login.loginView.teamNameTip')"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="country" :rules="rules.country">
          <el-select
            v-model="twoForm.country"
            :placeholder="$t('login.loginView.countryTip')"
            style="width: 375px"
          >
            <el-option
              v-for="c in countryList"
              :key="'country_' + c.key"
              :label="c.name"
              :value="c.key"
            ></el-option>
            <!-- <el-option label="中国大陆" value="zh"></el-option> -->
            <!-- <el-option label="其他" value="other"></el-option> -->
          </el-select>
        </el-form-item>
        <div class="divider">
          <i /> <span>{{ $t('login.loginView.need') }}</span
          ><i />
        </div>
        <el-form-item label="" prop="teamType" :rules="rules.teamType">
          <div class="ratio">
            <div
              class="r-wrap"
              v-for="(r, idx) in ratio"
              :key="idx"
              :class="twoForm.teamType == r.teamType ? 'active' : ''"
              @click="changeType(r.teamType)"
            >
              <div class="r-icon">
                <el-image :src="r.icon" alt=""></el-image>
              </div>
              <div class="r-text">{{ r.text }}</div>
              <el-image
                v-show="twoForm.teamType === r.teamType"
                :src="r.tip"
                alt=""
                class="check-icon"
              ></el-image>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn"
            :disabled="disabled"
            type="primary"
            :loading="isLoading"
            @click="submitForm('twoForm')"
            >{{ $t('login.loginView.buttonText') }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { createTeam } from '@/api/login.js'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isVisible: false,
      isLoading: false,
      twoForm: {
        country: 'CN',
        teamType: 1,
        teamName: ''
      }
    }
  },
  computed: {
    ...mapGetters(['lang', 'countryList', 'session']),
    disabled() {
      return false
    },
    ratio() {
      let info = [
        {
          teamType: 1, // HP
          text: this.$t('login.loginView.teamTypeOne'),
          icon: 'https://res.airrnd.com/disk/load/ed520785c8acf4817159863f5cfc1d2a.png',
          tip: 'https://res.airrnd.com/disk/load/f8292bdf66e82ce754e5651a82b18458.png'
        },
        {
          teamType: 2, // DP
          text: this.$t('login.loginView.teamTypeTwo'),
          icon: 'https://res.airrnd.com/disk/load/d9881f3897adb656416247cb915336bf.png',
          tip: 'https://res.airrnd.com/disk/load/f8292bdf66e82ce754e5651a82b18458.png'
        }
      ]
      return info
    },
    rules() {
      let obj = {
        country: [
          {
            required: true,
            message: `${this.$t('login.loginView.countryTip')}`,
            trigger: 'change'
          }
        ],
        teamType: [
          {
            required: true,
            message: `${this.$t('login.loginView.teamTip')}`,
            trigger: 'change'
          }
        ],
        teamName: [
          {
            required: true,
            message: `${this.$t('login.loginView.teamNameTip')}`,
            trigger: 'change'
          }
        ]
      }
      return obj
    }
  },
  methods: {
    show() {
      this.isVisible = true
      this.$store.dispatch('base/GetCountry', { lang: this.lang })
      if (this.session && this.session.country) {
        this.twoForm.country = this.session.country
      }
    },
    handleClose() {
      if (this.$refs.twoForm) {
        this.$refs.twoForm.resetFields()
        this.$refs.twoForm.clearValidate()
      }
      this.isVisible = false
    },
    changeType(v) {
      this.twoForm.teamType = v
    },
    submitForm() {
      const { teamName, country, teamType } = this.twoForm
      let obj = {
        teamName: teamName.trim(),
        country: country,
        teamType: teamType
      }
      if (!obj.teamName) {
        this.$message.error(this.$t('login.pleaseEnterName'))
        return
      }
      this.$refs.twoForm.validate((valid) => {
        if (valid) {
          this.addTeam(obj)
        }
      })
    },
    clearForm() {
      this.$refs.twoForm.clearValidate()
    },
    resetForm() {
      this.$refs.twoForm.resetFields()
    },
    addTeam(obj) {
      this.isLoading = true
      createTeam(obj)
        .then((res) => {
          this.isLoading = false
          this.$message.success(res.errorMessage)
          this.$store
            .dispatch('user/ChangeTeam', {
              changeTeamId: res.data
              // teamType: obj.teamType
            })
            .then(() => {
              this.$store.dispatch('user/GetSession')
            })
          if (obj.teamType == '1') {
            this.$router.push({ path: '/work/workbench' })
          } else {
            this.$router.push({ path: '/project' }) // 完善团队信息
          }
          this.handleClose()
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-team {
  .top {
    text-align: center;
  }
  .divider {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    > i {
      flex: 1;
      display: inline-block;
      height: 1px;
      border-top: 1px dashed #e5e6eb;
    }
    > span {
      display: inline-block;
      margin: 0 12px;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ratio {
    display: flex;
    justify-content: space-between;
    .r-wrap {
      width: 180px;
      height: 64px;
      border-radius: 4px;
      border: 1px solid #e5e6eb;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      .r-icon {
        width: 40px;
        height: 40px;
      }
      .r-text {
        width: 95px;
      }
      .check-icon {
        display: inline-block;
        position: absolute;
        right: -2px;
        bottom: -2px;
        width: 24px;
        height: 24px;
      }
    }
    .des {
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      .el-checkbox {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .btn {
    width: 100%;
    height: 40px;
  }
  ::v-deep {
    .el-dialog__body {
      border-top: 1px solid #efefef;
      padding: 24px 32px;
    }
  }
}
</style>
