<template>
  <div class="app-wrapper">
    <!-- 不显示在菜单栏里面 -->
    <template v-if="$route.meta.isFull">
      <router-view :key="key" />
    </template>
    <template v-else>
      <HeaderCommon mode="black" />
      <div class="content-wrap">
        <div class="content">
          <div class="content-box">
            <sidebar class="sidebar-container" />
            <div class="app-container">
              <head-tip v-if="tipType" :tipType="tipType" :session="session" />
              <!-- 根据tipType是否存在来传入cls 是为了对app-main高度做处理 height: calc(100%-62px) -->
              <app-main :cls="tipType ? 'app-main_tips' : ''" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <el-badge
      :value="unreadLabel"
      :hidden="unread === 0 || !hasNewMsg"
      class="badge"
    >
      <div class="im-icon-wrapper" @click="handleShowIm">
        <i class="el-icon-chat-dot-round"></i>
      </div>
    </el-badge>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar/indexView'
import AppMain from './components/AppMain'
import HeaderCommon from '@/components/HeaderCommon'
// import AirDialog from '@/components/AirIm/AirDialog.vue'
import HeadTip from './components/HeadTip.vue'
// import Empty from '@/components/EmptyFile.vue'
import { mapGetters } from 'vuex'
// import openIM from '@/components/AirIm/utils/openIM.js'
// import { getImToken } from '@/api/login'
export default {
  components: {
    Sidebar,
    AppMain,
    HeaderCommon,
    // AirDialog,
    HeadTip
    // Empty
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapGetters(['session']),
    key() {
      return this.$route.path
    },
    unread() {
      return parseInt(this.$store.state.im.unread)
    },
    unreadLabel() {
      return this.unread > 99 ? '99+' : String(this.unread)
    },
    hasNewMsg() {
      return this.$store.state.im.hasNew
    },
    tipType() {
      const { session } = this
      let type = ''
      if (!session) {
        return ''
      }
      if (session.perfectSchedule < 100) {
        type = 'comp'
      } else if (
        session.userStatus != '2' &&
        this.key != '/team/team-info/cert' &&
        Number(session.isCert) > 0
      ) {
        type = 'cert'
      }
      return type
    }
  },
  methods: {
    async handleShowIm() {
      // const userID = '18668082338'
      // const res = await getImToken({
      //   userId: userID,
      //   platform: 5
      // })
      // const token = res.data.imToken
      // const url = 'ws://118.195.190.175:30000'
      // const platformID = 5
      // openIM.login({
      //   userID,
      //   token,
      //   url,
      //   platformID
      // })
      this.$store.dispatch('im/allIM')
      // this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  background: #f7f8fa;
  width: 100%;
  height: 100%;
  .header-wrap {
    background: #303e47;
  }
  .content-wrap {
    overflow: auto;
    height: calc(100% - 64px);
    .content {
      margin: 24px 120px 0px;
      overflow: auto;
      .content-box {
        display: flex;
        overflow: auto;
        // margin-bottom: 64px;

        .sidebar-container {
          width: 212px;
          flex-shrink: 0;
        }
        .app-container {
          width: calc(100% - 228px);
          height: $content-height;
          background: #fff;
          margin-left: 16px;
        }
      }
    }
  }
}
.badge {
  position: absolute;
  z-index: 1999;
  right: 24px;
  bottom: 24px;
}
.im-icon-wrapper {
  font-size: 24px;
  background: #303e47;
  color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  text-align: center;
  line-height: 48px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
  cursor: pointer;
  .el-badge__content {
    right: 14px;
  }
}
</style>
