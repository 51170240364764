<template>
  <div class="app-wrap">
    <HeaderCommon
      :mode="session ? 'black' : 'white'"
      :style="{
        background: session ? '#303E47' : '#ffffff'
      }"
    />
    <div class="main-wrap">
      <div class="content-wrap">
        <SideBar style="width: 250px" />
        <div class="content-box">
          <div class="router-content">
            <router-view :key="key" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderCommon from '@/components/HeaderCommon'
import SideBar from './com/SideBar'
import Footer from '@/views/home/com/footerView.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    HeaderCommon,
    SideBar,
    Footer
  },
  computed: {
    ...mapGetters(['session']),
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrap {
  background: #f7f8fa;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main-wrap {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .content-wrap {
    flex: 1;
    display: flex;
    .content-box {
      background-color: #fff;
      flex: 1;
    }
  }
}
@media screen and (min-width: 1200px) {
  .app-wrap .content-wrap {
    width: 1200px;
    margin: 0 auto;
    padding: 32px 0;
  }
}
@media screen and (max-width: 1200px) {
  .app-wrap .content-wrap {
    padding: 32px 64px;
  }
}
</style>
