import request from '@/utils/request'
import download from '@/utils/requestDownload'

// 查询字典
export function loadDicts(data = {}) {
  return request({
    url: '/crd/base/dictMap',
    method: 'post',
    data
  })
}

export function queryWikiById(data = {}) {
  return request({
    url: '/crd/sys/wiki/queryById',
    method: 'post',
    data
  })
}

export function queryWikiByCodeLang(data = {}) {
  return request({
    url: '/crd/sys/wiki/queryByCodeLang',
    method: 'post',
    data
  })
}

export function downloadWiki(data = {}) {
  return download({
    url: '/crd/sys/wiki/previewPdf',
    method: 'post',
    data
  })
}
