<template>
  <div class="im-messages im-scrollbar" ref="wrap">
    <div class="im-messages-load" v-if="showMore">
      <span class="im-messages-end" @click="onLoadMore"
        >{{ $t('im.moreMsg') }}
      </span>
      <!--      <span class="im-messages-loading">-->
      <!--        <i class="el-icon-loading"></i>-->
      <!--      </span>-->
    </div>
    <div v-for="(item, index) in messagesList" :key="item.clientMsgID">
      <div v-if="calcTime(index)" class="chat-time">
        {{
          item.sendTime
            | relativeTime(
              { week: 'dddd HH:mm', date: 'YY/MM/DD HH:mm' },
              $t('im.yesterday')
            )
        }}
      </div>
      <component
        :is="msgCmpName(item)"
        v-bind="item"
        v-on="$listeners"
        :message="item"
      />
    </div>
  </div>
</template>

<script>
import MessageText from './message/Text.vue'
import MessageImage from './message/Image.vue'
import MessageSys from './message/Sys.vue'
import { messageTypes } from '@/components/AirIm/utils/openIM.js'
import { relativeTime } from './utils/index.js'

export default {
  name: 'AirMessages',
  filters: {
    relativeTime
  },
  components: {
    MessageText,
    MessageImage,
    MessageSys
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    hasMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      messagesList: []
    }
  },
  computed: {
    showMore() {
      return this.hasMore
    },
    msgCmpName() {
      return (item) => {
        const type = {
          [messageTypes.TEXTMESSAGE]: 'text',
          [messageTypes.PICTUREMESSAGE]: 'image'
        }
        const cmpType = type[item.contentType] || 'sys'
        return `message-${cmpType}`
      }
    }
  },
  watch: {
    messages: {
      handler(n) {
        this.messagesList = n
      },
      immediate: true
    }
  },
  methods: {
    async scrollToBottom() {
      await this.$nextTick()
      const { wrap } = this.$refs
      if (wrap) {
        wrap.scrollTop = wrap.scrollHeight
      }
    },
    /**
     * 计算两条消息之间的回复间隔 -1 表示超时未回复
     * @param index 索引
     * @returns {boolean}
     */
    calcTime(index) {
      const current = this.messagesList[index - 1]?.sendTime || 0
      const next = this.messagesList[index]?.sendTime || 0
      const costTime = 1000 * 60 * 5
      return next - current >= costTime || index === 0
    },
    onLoadMore() {
      this.$emit('load-more')
    }
  }
}
</script>

<style lang="scss" scoped>
.im-messages {
  background: #fafafa;
  padding: 8px 16px 16px 16px;
  height: 320px;
  //min-height: 260px;
  //max-height: 450px;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}
.chat-time {
  text-align: center;
  color: #999;
  font-size: 12px;
  line-height: 22px;
  margin: 12px 0;
}
.im-messages-load {
  display: flex;
  justify-content: center;
  font-size: 12px;
}
.im-messages-end {
  color: $color-primary;
  cursor: pointer;
}
</style>
